<template>
  <div class="profile-menu relative w-full md:me-10 lg:me-20">
    <ul class="flex flex-col gap-4">
      <li
        v-for="(item, index) in items"
        :key="index"
        class="profile-menu-item"
        :class="{ active: useRoute().name === item.route?.name }"
      >
        <Link
          v-if="item.route && useRoute().name !== item.route"
          variant="clear"
          :to="item.route"
          class="flex text-nowrap p-3"
        >
          <Icon v-if="item.icon" :name="item.icon" class="w-5 h-5 me-2" />
          {{ item.title }}

          <span class="block md:hidden ms-auto">
            <Icon name="svg-chevron-right" class="w-5 h-5 text-secondary-600" />
          </span>
        </Link>
        <span v-else class="flex text-nowrap p-3">
          <Icon v-if="item.icon" :name="item.icon" class="w-5 h-5 me-2" />
          {{ item.title }}

          <span class="block md:hidden ms-auto">
            <Icon name="svg-chevron-right" class="w-5 h-5 text-secondary-600" />
          </span>
        </span>
      </li>
    </ul>
    <div
      class="hidden md:block absolute border-l border-secondary-100 h-[94dvh] -me-4 lg:-me-12 -top-6 right-0"
    ></div>
  </div>
</template>

<script setup lang="ts">
import Icon from '@/components/Icon/Icon.vue'
import { useRoute } from 'vue-router'
import Link from '@/components/Link/Link.vue'

const items = [
  {
    title: 'Account Details',
    icon: 'user-settings',
    route: { name: 'accountDetails' }
  },
  {
    title: 'Security',
    icon: 'svg-security',
    route: { name: 'security' }
  },
  {
    title: 'Billing',
    icon: 'svg-wallet',
    route: { name: 'billing' }
  }
  // {
  //   title: 'Team',
  //   icon: 'user-team',
  //   route: { name: 'team' }
  // },
  // {
  //   title: 'API',
  //   icon: 'svg-fusion',
  //   route: { name: 'api' }
  // }
]
</script>

<style scoped>
.profile-menu .profile-menu-item {
  @apply text-base text-secondary-600 font-semibold border border-secondary-100 rounded-lg hover:text-primary hover:bg-primary-50;
}

.profile-menu .active {
  @apply border-primary text-primary bg-primary-50;
}
</style>
