<template>
  <div class="w-full">
    <div class="relative h-60 flex-1">
      <VueApexCharts :options="chartOptions" :series="series" height="100%" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, ref } from 'vue'
import VueApexCharts from 'vue3-apexcharts'
import type { VueApexChartsComponent } from 'vue3-apexcharts'
import type { ApexOptions } from 'apexcharts'

interface IProps {
  series: VueApexChartsComponent['series']
  categories: number[] | string[],
  dataValueFormatter(val: number[] | number | string, opts?: never): string
}

const props = defineProps<IProps>()
const chartOptions = ref<ApexOptions>({
  chart: {
    type: 'area',
    toolbar: {
      show: false,
    }
  },
  stroke: {
    curve: 'smooth',
    colors: ['#3273F6'],
    width: 1,
  },
  colors: ['#3273F6'],
  fill: {
    type: 'gradient',
    gradient: {
      opacityTo: 1,
      opacityFrom: 0.6,
      shade: 'light',
      shadeIntensity: 0,
      gradientToColors: ['#EFF5FF', '#3273F6'],
      stops: [0, 100],
    },
  },
  xaxis: {
    categories: props.categories,
    labels: {
      style: {
        colors: '#8995A7',
      }
    }
  },
  yaxis: {
    min: 0,
    forceNiceScale: true,
    labels: {
      style: {
        colors: '#8995A7'
      },
      formatter: props.dataValueFormatter
    }
  },
  dataLabels: {
    enabled: true,
    formatter: props.dataValueFormatter,
    style: {
      fontSize: '10px',
      fontFamily: 'Nunito Sans',
      fontWeight: 'bold',
      colors: ['#3273F6']
    },
    background: {
      padding: 4,
      borderRadius: 2,
      enabled: true,
      borderWidth: 1,
      borderColor: '#3273F6',
      opacity: 1
    },
  }
})
</script>
