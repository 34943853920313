<template>
  <div class="flex flex-col relative">
    <Header />
    <div
      class="md:top-20 lg:top-25 lg:relative min-h-[100vh] bg-secondary-50"
      :class="{ 'px-4 py-6': !meta.notDefaultPadding }"
    >
      <router-view></router-view>
      <div class="max-sm:pt-15" :class="{ 'md:pt-0 lg:pt-10': meta.notDefaultPadding }">
        <div
          class="absolute m-auto left-0 right-0 text-xxs text-secondary-400 text-center z-5 bottom-2 flex justify-center"
        >
          <div class="w-3/4">
            <span class="text-primary">©</span> {{ new Date().getFullYear() }} HR Signal Technology
            Inc. DBA Lookalikes<span class="text-primary">™</span> |
            <a
              class="text-primary text-nowrap underline hover:no-underline"
              href="https://www.hirelookalikes.com/privacy-policies-legal/"
              target="_blank"
              >Privacy, Policies & Legal</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { RouterView } from 'vue-router'
import Header from '@/components/MainHeader/Header.vue'

import { useRoute } from 'vue-router'

const route = useRoute()
const meta = computed(() => route.meta)
</script>
