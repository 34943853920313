<template>
  <div class="bg-primary-50 py-6 px-4 w-full rounded-r-lg rounded-bl-lg">
    <div class="flex flex-col bg-grey-50 p-4 gap-8 rounded-lg">
      <div class="max-sm:hidden flex flex-row w-full justify-between">
        <div class="flex flex-col items-center">
          <span class="text-2xl font-semibold text-grey-900 leading-[33px]"
            >{{ isArchetype ? 'Archetype:' : 'Candidate:' }} {{ data.name }}</span
          >
        </div>
      </div>
      <div class="flex flex-col gap-4">
        <div class="flex flex-row">
          <span class="text-lg font-semibold text-grey-900 leading-6"
            >About {{ isArchetype ? 'Archetype' : 'Candidate' }}</span
          >
        </div>
        <div class="flex flex-row border rounded-lg border-secondary-200 py-2 px-3">
          <span class="text-sm text-grey-900" v-html="data.about ?? 'No data provided.'"></span>
        </div>
      </div>

      <div class="flex flex-col gap-4">
        <div class="flex flex-row">
          <span class="text-lg font-semibold text-grey-900 leading-6"
            >Current {{ isArchetype ? 'Employer' : 'Position(s)' }}</span
          >
        </div>
        <div class="flex flex-col divide-y divide-secondary-100">
          <div
            v-if="Object.keys(currentPositions).length"
            v-for="experience in currentPositions"
            :key="experience.company_name"
            class="py-4 lg:py-3 first:pt-0"
          >
            <ExperienceCard :work-experience="experience" :display-history="false" />
          </div>
          <div v-else>
            Not currently employed.
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-4" v-if="Object.keys(workExp).length">
        <div class="flex flex-row">
          <span class="text-lg font-semibold text-grey-900 leading-6">Work experience(s)</span>
        </div>
        <div class="flex flex-col divide-y divide-secondary-100">
          <div
            v-for="experience in workExp"
            :key="experience.company_name"
            class="py-4 lg:py-3 first:pt-0"
          >
            <ExperienceCard :work-experience="experience" />
          </div>
        </div>
      </div>
      <div v-if="data.education && data.education[0].degree" class="flex flex-col gap-4">
        <div class="flex flex-row">
          <span class="text-lg font-semibold text-grey-900 leading-6">Education</span>
        </div>
        <EducationCard :educations="data.education" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import ExperienceCard from '@/components/Candidate/Experience/ExperienceCard.vue'
import { computed, defineEmits, defineProps, ref } from 'vue'
import EducationCard from '@/components/Candidate/Education/EducationCard.vue'

interface IProps {
  data: object
  isArchetype?: boolean
}

const props = withDefaults(defineProps<IProps>(), {
  isArchetype: false
})
const emit = defineEmits(['compare'])

const compareArchetype = ref(false)

const currentPositions = computed(() => {
  if (props.data.workExperience) {
    return props.data.workExperience.reduce((acc, cur) => {
      if (!cur.ends_at) {
        if (acc[cur.company_name] !== undefined) {
          acc[cur.company_name]['positions'].push(cur)
        } else {
          acc[cur.company_name] = { positions: [cur], current: cur }
        }
      } else if (acc[cur.company_name] !== undefined) {
        acc[cur.company_name]['positions'].push(cur)
      }
      return acc
    }, {})
  }

  return {}
})

const workExp = computed(() => {
  if (props.data.workExperience) {
    return props.data.workExperience.reduce((acc, cur) => {
      let displayInExperienceSection = false

      if (!Object.keys(currentPositions.value).includes(cur.company_name)) {
        displayInExperienceSection = true
      } else {
        const hasNonNullEndsAt = currentPositions.value[cur.company_name].positions.some(
          (position) => position.ends_at !== null
        )

        displayInExperienceSection =
          hasNonNullEndsAt && currentPositions.value[cur.company_name].positions.length > 1
      }

      if (displayInExperienceSection) {
        if (acc[cur.company_name] !== undefined) {
          acc[cur.company_name]['positions'].push(cur)
        } else {
          acc[cur.company_name] = { positions: [cur], current: cur }
        }
      }
      return acc
    }, {})
  }

  return {}
})
</script>
