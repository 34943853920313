<template>
  <Button
    :variant="shortlisted || shortlistedState ? 'success' : 'primary'"
    :class="{
      'bg-primary-100 !text-primary-700 hover:!text-white': !shortlisted && !shortlistedState,
      '!bg-success-50 border border-success-500 !text-success-500': shortlisted || shortlistedState
    }"
    :loading="loading"
    @click="handleShortlist"
  >
    {{ shortlisted || shortlistedState ? 'Shortlisted' : 'Shortlist' }}
  </Button>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'

import axios from 'axios'

import Button from '@/components/Buttons/Button.vue'
import { useProjectStore } from '@/stores/project'

interface Props {
  candidateId: string
}

const emit = defineEmits(['error', 'shortlisted'])
const props = defineProps<Props>()

const projectStore = useProjectStore()

const loading = ref(false)

const shortlisted = ref(
  projectStore.getCandidates.find(
    (candidate) => candidate.project_candidate_id === props.candidateId
  )?.shortlisted
)

const shortlistedState = computed(() => {
  return projectStore.getCandidates.find(
    (candidate) => candidate.project_candidate_id === props.candidateId
  )?.shortlisted
})

const handleShortlist = async () => {
  if (shortlistedState.value) {
    await unshortlist()
  } else {
    await shortlist()
  }
}

const shortlist = async () => {
  loading.value = true
  try {
    await axios.put(
      `${import.meta.env.VITE_LOOKALIKES_API}/api/projects/shortlist/${props.candidateId}`
    )
    projectStore.shortlist(props.candidateId)

    shortlisted.value = true
    emit('shortlisted', 1)
  } catch (error) {
    if (error.response.status === 400) {
      emit('error', new Error('Candidate already shortlisted'))
    } else {
      emit('error', new Error('Failed to shortlist candidate'))
    }
  } finally {
    loading.value = false
  }
}

const unshortlist = async () => {
  loading.value = true
  try {
    await axios.put(
      `${import.meta.env.VITE_LOOKALIKES_API}/api/projects/unshortlist/${props.candidateId}`
    )

    projectStore.unshortlist(props.candidateId)
    shortlisted.value = false
    emit('shortlisted', -1)
  } catch (error) {
    if (error.response.status === 400) {
      emit('error', new Error('Candidate already unshortlisted'))
    } else {
      emit('error', new Error('Failed to unshortlist candidate'))
    }
  } finally {
    loading.value = false
  }
}
</script>
