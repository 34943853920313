<template>
  <div class="relative">
    <Button
      variant="outline-primary"
      class="border-none hover:bg-transparent hover:underline max-md:px-1 md:py-0 h-6"
      @onClick="toggleAddToProjectModal"
    >
      Add
    </Button>
    <div
      v-if="showAddToProjectModal"
      class="flex flex-col absolute bg-secondary-50 border border-primary p-4 rounded-lg z-20"
      :class="positionClasses"
      v-click-away="closeAddToProjectModal"
    >
      <div v-if="!createNewProjectModal" class="flex flex-col gap-3 md:w-67.5">
        <div class="flex leading-6 text-lg text-secondary-900 pb-3 border-b border-b-secondary-100">
          {{ title }}
          <Icon
            name="input-close"
            class="size-6 ms-auto text-secondary-400"
            @click="closeAddToProjectModal"
          />
        </div>

        <div class="flex items-center">
          <IconButton
            icon="svg-add"
            icon-class="size-4 text-primary-700"
            class="flex items-center gap-2 border-none h-5.5 p-2"
            @onClick="createNewProjectModal = true"
          >
            <span class="text-primary-700 text-md">New</span>
          </IconButton>
        </div>

        <div class="overflow-hidden">
          <div id="sliderWrapper" ref="slider" class="slider-wrapper flex">
            <div
              v-for="(chunk, chunkIndex) in chunkedProjects"
              :key="chunkIndex"
              class="slide-item flex-shrink-0 basis-full flex flex-col gap-y-2"
            >
              <div v-for="(project, index) in chunk" :key="index">
                <button
                  class="flex flex-wrap leading-5 text-secondary-600 underline"
                  @click="addToProject(project.id)"
                >
                  {{ project.name }} ({{ project.candidates_count }})
                  <Icon
                    name="svg-loading-circle"
                    class="size-5 ml-2 text-primary-700"
                    v-if="savingToProjectId === project.id"
                  />
                </button>
              </div>
            </div>
          </div>

          <div class="flex flex-row justify-end p-0 gap-3 mt-3" v-show="chunkedProjects.length">
            <Icon
              id="prevProjects"
              name="svg-chevron-left"
              class="size-5"
              :class="{ 'text-secondary-300': !currentSlide }"
              @click="prevProjects"
            />
            <Icon
              id="nextProjects"
              name="svg-chevron-right"
              class="size-5"
              :class="{ 'text-secondary-300': currentSlide + 1 == chunkedProjects?.length }"
              @click="nextProjects"
            />
          </div>
        </div>
      </div>

      <div v-else class="w-67.5">
        <div class="flex leading-6 text-lg text-secondary-900">
          Create a New Project
          <Icon
            name="input-close"
            class="size-6 ms-auto text-secondary-400"
            @click="createNewProjectModal = false"
          />
        </div>
        <div class="mt-3">
          <div class="flex leading-6 text-md">
            <BasicInput
              class="w-full"
              name="project"
              placeholder="Type name of project"
              @input-change="(value) => (projectName = value)"
            />
          </div>
          <div class="flex flex-col gap-3 mt-6">
            <Button
              variant="primary"
              :disabled="!projectName"
              :loading="loading"
              @click="createNewProject"
              >Create</Button
            >
            <Button
              variant="outline-secondary"
              class="border-none hover:bg-transparent underline hover:no-underline h-5.5 !py-0"
              @onClick="createNewProjectModal = false"
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, defineProps, ref } from 'vue'
import axios from 'axios'
import BasicInput from '@/components/Inputs/BasicInput/BasicInput.vue'
import Button from '@/components/Buttons/Button.vue'
import IconButton from '@/components/Buttons/IconButton.vue'
import Icon from '@/components/Icon/Icon.vue'

interface IProps {
  positionClasses?: string
  projects: any[]
  searchResultId: number
  title?: string
}

const emit = defineEmits([
  'addToProjectError',
  'addToProjectSuccess',
  'projectCreated',
  'projectCreationError'
])
const props = withDefaults(defineProps<IProps>(), {
  positionClasses: 'top-10 right-0',
  title: 'Add to Project(s)?'
})

const showAddToProjectModal = ref(false)
const createNewProjectModal = ref(false)
const currentSlide = ref(0)
const slider = ref(null)
const projectName = ref('')
const loading = ref(false)
const savingToProjectId = ref<number | null>(null)

const chunkedProjects = computed(() => {
  const chunkSize = 2
  const chunks = []
  for (let i = 0; i < props.projects.length; i += chunkSize) {
    chunks.push(props.projects.slice(i, i + chunkSize))
  }
  return chunks
})

function updateSlider() {
  const offset = -(currentSlide.value * 100)
  if (slider.value) {
    slider.value.style.transform = `translateX(${offset}%)`
  }
}

const prevProjects = () => {
  currentSlide.value = Math.max(currentSlide.value - 1, 0)
  updateSlider()
}
const nextProjects = () => {
  currentSlide.value = Math.min(currentSlide.value + 1, chunkedProjects.value?.length - 1)
  updateSlider()
}

const toggleAddToProjectModal = () => {
  showAddToProjectModal.value = !showAddToProjectModal.value
}
const closeAddToProjectModal = () => {
  if (showAddToProjectModal.value) {
    showAddToProjectModal.value = false
  }
}

const createNewProject = async () => {
  if (!projectName.value) {
    return
  }

  try {
    loading.value = true
    await axios.post(`${import.meta.env.VITE_LOOKALIKES_API}/api/projects`, {
      name: projectName.value
    })
    emit('projectCreated')
  } catch (error) {
    if (error.response.status === 400) {
      emit('projectCreationError', 'Project name already exists')
    } else {
      emit('projectCreationError', 'Oops! Something went wrong, please contact support.')
    }
  } finally {
    loading.value = false
  }
}

const addToProject = async (projectId: number) => {
  savingToProjectId.value = projectId
  try {
    await axios.post(`${import.meta.env.VITE_LOOKALIKES_API}/api/projects/add-candidate`, {
      search_result_id: props.searchResultId,
      project_id: projectId
    })
    emit('addToProjectSuccess')
  } catch (error) {
    emit('addToProjectError', error.response.data)
  } finally {
    savingToProjectId.value = null
  }
}
</script>
