export const setRecentViewed = (candidateId: string) => {
  cleanup()

  const views = localStorage.getItem('recent-viewed')
    ? JSON.parse(localStorage.getItem('recent-viewed'))
    : {}

  if (views[candidateId] == undefined) {
    views[candidateId] = {
      date: Date.now(),
      count: 1
    }
  } else {
    views[candidateId].count = views[candidateId].count + 1
    views[candidateId].date = Date.now()
  }

  localStorage.setItem('recent-viewed', JSON.stringify(views))

  window.postMessage('recent-candidates-views-updated');
}

export const isRecentViewed = (candidateId: string, displayAtFirstTime: boolean) => {
  if (!localStorage.hasOwnProperty('recent-viewed')) return false

  const views = JSON.parse(localStorage.getItem('recent-viewed'))

  return displayAtFirstTime
    ? views[candidateId] !== undefined
    : views[candidateId] !== undefined && views[candidateId].count > 1
}

export const getRecentViewedDate = (candidateId: string) => {
  if (!localStorage.hasOwnProperty('recent-viewed')) return null;

  const views = JSON.parse(localStorage.getItem('recent-viewed'))

  const date = new Date(views[candidateId].date);

  return new Date(views[candidateId].date).toISOString().slice(0, 16).replace('T', '-')
}

const cleanup = () => {
  if (localStorage.hasOwnProperty('recent-viewed')) {
    const views = JSON.parse(localStorage.getItem('recent-viewed'))

    // Calculate the Unix timestamp for 14 days ago in milliseconds
    const fourteenDaysAgo = Date.now() - 14 * 24 * 60 * 60 * 1000

    for (const key in views) {
      if (views[key].date < fourteenDaysAgo) {
        delete views[key]
      }
    }

    localStorage.setItem('recent-viewed', JSON.stringify(views))
  }
}
