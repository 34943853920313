<template>
  <div
    class="lg:fixed z-20 lg:w-full px-5 py-5 xl:px-10 lg:py-5 md:border-b border-b-secondary-100 bg-secondary-50 overscroll-none"
  >
    <div class="flex items-center h-10 lg:h-15 lg:py-2 z-2">
      <div class="hidden max-lg:flex items-center">
        <Icon
          :name="mobileMenuIcon"
          class="stroke-2 rounded border border-secondary-100 text-secondary-900 me-3 p-1 w-8 h-8"
          @click="displayMenu = !displayMenu"
        />
      </div>
      <div class="max-lg:order-2 flex justify-center items-center w-full lg:w-auto z-20">
        <Link to="/">
          <img class="cursor-pointer" src="@/assets/Lookalikes.png" alt="logo" width="180" />
        </Link>
      </div>
      <div
        class="lg:flex max-lg:order-1 max-lg:absolute max-lg:top-20 max-lg:w-full max-lg:border-t border-t-secondary-100 max-lg:left-0 max-lg:right-0 max-lg:h-full max-lg:bg-secondary-800 max-lg:bg-opacity-50 z-40"
        :class="{ hidden: !displayMenu }"
        @click.self="displayMenu = false"
      >
        <HeaderMenu
          @sendInviteClick="openSendInviteModal"
          @close="displayMenu = false"
          class="max-lg:py-6 max-lg:px-8 max-md:px-4"
        />
      </div>

      <div class="ms-auto flex items-center order-3 max-md:hidden">
        <div
          v-if="userStore.subscriptionType === 'trial'"
          class="text-sm mr-4 hover:bg-gray-100 rounded-lg p-2"
        >
          <RouterLink to="/profile/billing">
            <span>Free Trial</span>
            <div class="flex">
              <span class="mr-1"> {{ userStore.getDaysUntilFreeTrialEnds }} </span>
              <span> days left</span>
            </div>
          </RouterLink>
        </div>
        <Button
          variant="outline-secondary"
          class="hidden lg:flex text-nowrap border-secondary-900"
          @click="sendInvites = true"
        >
          <Icon name="user-like" class="stroke-2 text-secondary-900 me-2 w-5 h-5" />
          Invite People
        </Button>
        <div v-if="userStore.isAuthenticated" class="ms-4">
          <UserDropdown />
        </div>
      </div>
    </div>
  </div>

  <InviteUserModal v-if="sendInvites" @close="closeSendInviteModal" />
</template>

<script setup lang="ts">
import Button from '@/components/Buttons/Button.vue'
import Icon from '@/components/Icon/Icon.vue'
import HeaderMenu from '@/components/MainHeader/HeaderMenu.vue'
import Link from '@/components/Link/Link.vue'
import UserDropdown from '@/components/MainHeader/UserDropdown.vue'
import { useUserStore } from '@/stores/user'
import { computed, ref, watch } from 'vue'

import InviteUserModal from '@/components/Modals/User/InviteUserModal.vue'
import { useRoute, useRouter } from 'vue-router'

const userStore = useUserStore()
const displayMenu = ref(false)
const sendInvites = ref(false)
const router = useRouter()
const route = useRoute()
const mobileMenuIcon = computed(() => (displayMenu.value ? 'input-close' : 'svg-hamburger'))

watch(
  route,
  (to) => {
    displayMenu.value = false
  },
  {
    deep: true
  }
)
const openSendInviteModal = () => {
  const isMobile = window.innerWidth < 768

  if (isMobile) {
    // redirect if on mobile
    router.push('invite')
  } else {
    // Open modal if on desktop or tablet
    sendInvites.value = !sendInvites.value
  }

  displayMenu.value = !displayMenu.value
}

const closeSendInviteModal = () => {
  sendInvites.value = false
}
</script>
