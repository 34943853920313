<template>
  <div class="space-x-1">
    <span class="dot animate-bounce delay-1"></span>
    <span class="dot animate-bounce delay-2"></span>
    <span class="dot animate-bounce delay-3"></span>
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped>

@keyframes bounce {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

.dot {
  display: inline-block;
  width: 3px;
  height: 3px;
  background-color: white;
  border-radius: 50%;
}

.animate-bounce {
  animation: bounce 1s infinite ease-in-out both;
}

.delay-1 {
  animation-delay: 0s;
}

.delay-2 {
  animation-delay: 0.2s;
}

.delay-3 {
  animation-delay: 0.4s;
}
</style>