<template>
  <div class="bg-primary-50 py-6 p-4 rounded-r-lg rounded-bl-lg">
    <div v-if="!loading" class="flex flex-col bg-grey-50 gap-8 rounded-lg p-4">
      <AnnualSalaryCard
        :average-annual-salary="data.averageAnnualSalary"
        months-ago="6"
        :salary-history="data.salaryHistory"
        :salary-change-label="data.salaryChangeLabel"
        :annual-salary-percentage-change="data.annualSalaryPercentageChange"
      />

      <PositionDemandCard
        months-ago="6"
        :average-position-demand="data.positionDemand"
        :position-change-label="data.positionDemandSixMonthChangeLabel"
        :position-demand-history="data.jobDemandHistory"
        :position-demand-percentage-change="data.positionDemandSixMonthChange"
      />
    </div>
    <MarketDataTabLoader v-else />
  </div>
</template>
<script setup lang="ts">
import axios from 'axios'
import { defineProps, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import AnnualSalaryCard from '@/components/Candidate/Salary/AnnualSalaryCard.vue'
import PositionDemandCard from '@/components/Candidate/Position/PositionDemandCard.vue'
import MarketDataTabLoader from '@/components/Modals/Candidate/components/MarketDataTabLoader.vue'

interface IProps {
  searchResultId: number
}

const props = defineProps<IProps>()
const route = useRoute()
const data = ref([])
const searchId = route.params.searchId ?? 5
const loading = ref(true)

onMounted(async () => {
  const res = await axios.get(
    `${import.meta.env.VITE_LOOKALIKES_API}/api/search-results/${props.searchResultId}/candidate/market-data`
  )

  data.value = res.data
  loading.value = false
})
</script>
