<template>
  <svg width="60" height="66" viewBox="0 0 60 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect class="bar bar1" x="51.2727" y="0.857178" width="8.72727" height="64.2857" rx="4.36364" fill="#5E3DEE"/>
    <rect class="bar bar2" x="33.6001" y="4.78577" width="8.72727" height="56.25" rx="4.36364" fill="#5E3DEE"/>
    <rect class="bar bar3" x="16.8" y="14.0715" width="8.72727" height="37.5" rx="4.36364" fill="#5E3DEE"/>
    <rect class="bar bar4" y="23.7144" width="8.72727" height="18.75" rx="4.36364" fill="#5E3DEE"/>
  </svg>

</template>


<style scoped>
/* Initial styling */
.bar {
  transform-origin: bottom center;
  animation: bounce 1.5s ease-in-out infinite;
}

/* Different delays for each bar */
.bar1 { animation-delay: 0s; }
.bar2 { animation-delay: 0.3s; }
.bar3 { animation-delay: 0.6s; }
.bar4 { animation-delay: 0.9s; }

/* Keyframes for the bouncing effect */
@keyframes bounce {
  0%, 100% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0.5);
  }
}
</style>
<script setup lang="ts">
</script>
