<template>
  <div>
    <ul
      class="flex gap-4 w-full justify-start"
      :class="{'max-sm:grid max-sm:grid-cols-2 max-sm:justify-between': !rowDirection}"
    >
      <li
        v-for="(tab, index) in tabs"
        :key="`tab-${tab.slug}`"
        :class="[
          index === currentTab
            ? activeTabItemClass
            : tabItemClass
        ]"
        class="cursor-pointer px-2 text-nowrap py-3 md:px-3 md:py-1 text-center border text-base font-medium rounded-lg md:hover:text-secondary-900 hover:bg-secondary-50 md:hover:border-secondary-300"
        @click="tabSelected(index)"
      >
        {{ tab.title }}
      </li>
    </ul>
    <slot name="actions" />
  </div>
</template>

<script setup lang="ts">
import { defineEmits, defineProps, onMounted, ref, watch } from 'vue'

interface IProps {
  tabs: Record<bigint, { title: string; slug: string }>
  activeTab: bigint,
  rowDirection?: boolean,
  activeTabItemClass?: string,
  tabItemClass?: string
}

const emit = defineEmits(['selected'])
const props = withDefaults(defineProps<IProps>(), {
  activeTabItemClass: 'text-primary border-primary md:text-secondary-900 md:bg-secondary-50 md:border-secondary-300',
  tabItemClass: 'text-secondary-900 md:border-transparent border-secondary-100 bg-transparent md:text-secondary-600',
  rowDirection: false,
})

const currentTab = ref(props.activeTab)

const tabSelected = (index: bigint): void => {
  if (index === currentTab.value) {
    return
  }

  currentTab.value = index
  emit('selected', index)
}

watch(
  () => props.activeTab,
  (tabIndex) => {
    currentTab.value = tabIndex
  }
)
onMounted(() => {
  currentTab.value = props.activeTab || 0
})
</script>
