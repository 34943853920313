<template>
  <BaseSearchCard :data="data">
    <template #buttons>
      <Button
        variant="outline-success-secondary"
        class="search-card__actions__button flex-grow lg:flex-grow-0 flex-shrink lg:flex-shrink-0 basis-0 order-1 md:order-none cursor-default"
        >Saved</Button
      >
      <Button
        variant="outline-danger"
        class="search-card__actions__button md:basis-1/2 lg:basis-0"
        @onClick="toggleRemoveSearchModal"
        >Remove</Button
      >

      <Button variant="primary" class="search-card__actions__button lg:order-3" @click="viewSearch">
        View
      </Button>
    </template>
  </BaseSearchCard>
  <RemoveModal
    v-if="removeSearchModal"
    title="Remove Search"
    description="Are you sure you want to delete this search? This action is not reversible"
    @close="closeRemoveSearchModal"
    @remove="
      () => {
        $emit('remove', data.search_id)
        toggleRemoveSearchModal()
      }
    "
  />
</template>
<script setup lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import Button from '@/components/Buttons/Button.vue'
import BaseSearchCard from '@/views/Card/Search/BaseSearchCard.vue'
import RemoveModal from '@/components/Modals/RemoveModal.vue'

import type { SavedSearch } from '@/views/Searches/types'
import { useBreadcrumbsStore } from '@/stores/breadcrumbs'

interface IProps {
  data: SavedSearch
}
const emit = defineEmits(['remove'])
const props = defineProps<IProps>()
const router = useRouter()
const breadcrumbsStore = useBreadcrumbsStore()

const removeSearchModal = ref(false)

const toggleRemoveSearchModal = () => {
  removeSearchModal.value = !removeSearchModal.value
}

const closeRemoveSearchModal = () => {
  removeSearchModal.value = false
}

const viewSearch = () => {
  breadcrumbsStore.setBreadcrumbs([{ title: 'Saved Searches', route: { name: 'savedSearches' } }])
  router.push({
    name: 'searchResults',
    params: { searchId: props.data.search_id },
    query: props.data.total_shortlisted ? { shortlisted: 'true' } : {}
  })
}
</script>

<style scoped>
.search-card__actions__button {
  @apply w-full lg:w-auto;
}
</style>
