<template>
  <VueDatePicker
    v-model="date"
    v-bind="props"
    :loading="loading"
    :enable-time-picker="enableTimePicker"
    :auto-apply="autoApply"
    :hide-navigation="['month', 'year']"
    :disabled-dates="disabledDates"
  >
  </VueDatePicker>
</template>

<script setup lang="ts">
import VueDatePicker from '@vuepic/vue-datepicker'

import '@vuepic/vue-datepicker/dist/main.css'

import type { UIOptions } from '@vuepic/vue-datepicker'

interface IProps {
  loading?: boolean
  enableTimePicker?: boolean
  autoApply?: boolean
  position?: 'left' | 'center' | 'right'
  disabledDates?: Date[] | string[]
  ui?: Partial<UIOptions>
  hideInputIcon?: boolean
  clearable?: boolean
}

const date = defineModel<Date | null>()

const props = withDefaults(defineProps<IProps>(), {
  loading: false,
  enableTimePicker: false,
  autoApply: true,
  position: 'center',
  hideInputIcon: false,
  clearable: true
})
</script>

<style>
.dp__calendar {
  .dp__calendar_row {
    gap: 0.25rem;
  }
  .dp__today {
    @apply !border-secondary-200;
  }
  .dp__calendar_header_separator {
    @apply !border-transparent !hidden;
  }
}
.dp__overlay {
  @apply !border-secondary-200 !rounded;
}

.dp__flat-input {
  border: none;
  border-bottom: 1px solid #000;
  border-radius: 0;
  box-shadow: none;
  text-align: center;
  font-size: 0.875rem;
  font-family: 'Nunito Sans', sans-serif;
  padding-right: 4px;
  padding-left: 4px;
}
</style>
