<template>
  <ProfileSection title="Billing">
    <div>
      <div class="bg-gray-100 rounded-lg p-4 max-w-[30%] flex flex-col gap-2">
        <div class="flex flex-col gap-1">
          <span class="text-secondary-600"> Current Plan:</span>
          <span class="text-secondary-900 font-semibold">{{ subscriptionType }}</span>
          <p
            v-if="
              userStore.subscriptionType === 'trial' && userStore.subscriptionStatus === 'expired'
            "
            class="text-sm text-gray-500"
          >
            Your 14-day free trial expired on {{ subscriptionEndsAt }}. To continue enjoying
            Lookalikes, please upgrade to a paid plan.
          </p>
          <p v-if="userStore.subscriptionStatus === 'canceled'" class="text-sm text-gray-500">
            This subscription has been canceled and your account is currently inactive. To
            reactivate your account, select one of the plans below. We will save all of your saved
            candidates, searches and projects for up to 60 days after the date of cancellation.
          </p>
          <p
            v-if="
              userStore.subscriptionType !== 'trial' &&
              userStore.subscriptionStatus === 'active' &&
              userStore.subscriptionEndsAt
            "
            class="text-sm text-gray-500"
          >
            This subscription is scheduled to be canceled on {{ subscriptionEndsAt }}. To undo this,
            go to Manage Subscription.
          </p>
        </div>
        <div>
          <div v-if="loading" class="flex items-center gap-2">
            Loading Manage Subscription
            <Icon name="svg-loading-circle" class="w-6 h-6" />
          </div>

          <a
            v-if="customerPortalUrl && subscriptionStatus !== 'canceled'"
            :href="customerPortalUrl"
            target="_blank"
            class="text-primary hover:underline"
          >
            <Button variant="primary" class="w-full md:w-auto h-11 mt-4"
              >Manage Subscription</Button
            >
          </a>
        </div>
      </div>
      <SubscriptionPlans
        v-if="userStore.subscriptionType === 'trial' || userStore.subscriptionStatus === 'canceled'"
        @subscriptionCreated="subscriptionCreated"
      />
    </div>
  </ProfileSection>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import axios from 'axios'

import ProfileSection from '@/views/User/Components/ProfileSection.vue'
import SubscriptionPlans from '@/views/User/Billing/SubscriptionPlans.vue'
import Button from '@/components/Buttons/Button.vue'
import Icon from '@/components/Icon/Icon.vue'

import { useUserStore } from '@/stores/user'

const userStore = useUserStore()
const customerPortalUrl = ref(null)
const loading = ref(false)

const subscriptionEndsAt = computed(() => {
  if (userStore?.subscriptionEndsAt) {
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric'
    }).format(new Date(userStore?.subscriptionEndsAt))
    return formattedDate
  }
  return null
})

const subscriptionType = computed(() => {
  if (userStore?.subscriptionType === 'trial') {
    return `Free Trial (${
      userStore?.subscriptionStatus === 'expired' ? 'expired' : 'expires'
    } on: ${subscriptionEndsAt.value})`
  }
  if (userStore?.subscriptionType === 'essential' && userStore?.subscriptionStatus !== 'canceled') {
    return 'Essential'
  }
  if (
    userStore?.subscriptionType === 'professional' &&
    userStore?.subscriptionStatus !== 'canceled'
  ) {
    return 'Professional'
  }
  if (userStore?.subscriptionType === 'free') {
    return 'Free'
  }
  return 'No Subscription'
})

const subscriptionStatus = computed(() => userStore?.subscriptionStatus)

onMounted(async () => {
  if (
    userStore?.subscriptionType !== 'trial' &&
    userStore?.subscriptionStatus !== 'canceled' &&
    userStore?.subscriptionType !== 'free'
  ) {
    getUserPortalUrl()
  }
})

const getUserPortalUrl = async () => {
  loading.value = true
  const response = await axios.get(
    `${import.meta.env.VITE_LOOKALIKES_API}/api/user/customer-portal-session`
  )
  customerPortalUrl.value = response.data.url
  loading.value = false
}

const subscriptionCreated = () => {
  loading.value = true
  const intervalId = setInterval(async () => {
    try {
      await getUserPortalUrl()
      clearInterval(intervalId) // Stop the interval if the function succeeds
    } catch (error) {
      // console.error('Error fetching user portal URL:', error)
      // The interval will continue if there's an error
    }
  }, 5000)
}
</script>
