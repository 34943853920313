<template>
  <button
    class="rounded-lg px-6 py-3 h-11 text-base leading-5 font-semibold ease-out duration-300"
    :class="[
      colors[variant] ?? colors.primary,
      hoverColors[variant] ?? hoverColors.primary,
      activeColors[variant] ?? activeColors.primary,
      disabledColors[variant] ?? disabledColors.primary
    ]"
    :disabled="disabled"
    :type="type"
    :form="formId"
    @click.stop="$emit('onClick')"
  >
    <template v-if="!loading">
      <slot />
    </template>

    <template v-else-if="animation === 'dots' && loading">
      <slot name="loading" />
      <DotsLoader class="flex mt-auto ms-1 mb-0.5" />
    </template>

    <Loader v-else class="w-10 text-center m-auto" />
  </button>
</template>
<script setup lang="ts">
import { defineEmits, defineProps } from 'vue'
import Loader from '@/components/Loader.vue'
import type { ButtonColors, OutlineButtonColors } from '@/components/Buttons/buttons'
import DotsLoader from '@/components/Loaders/DotsLoader.vue'

interface IProps {
  variant?: ButtonColors | OutlineButtonColors
  disabled?: boolean
  type?: string
  loading?: boolean
  formId?: string
  animation?: 'dots'
}

defineEmits(['onClick'])
defineProps<IProps>()

const colors = {
  primary: 'bg-primary text-secondary-50',
  secondary: 'border border-secondary-200 bg-secondary-50 text-secondary-200',
  success: 'bg-success-50 text-success-500',
  danger: 'bg-danger-50 text-danger-500',
  'outline-primary': 'border border-primary bg-secondary-50 text-primary',
  'outline-secondary': 'border border-secondary-200 bg-secondary-50 text-secondary-600',
  'outline-success': 'border border-success-600 bg-secondary-50 text-success-600',
  'outline-danger': 'border border-danger-600 bg-secondary-50 text-danger-600',
  'outline-success-secondary': 'bg-success-50 border border-success-500 text-success-500'
}

const hoverColors = {
  primary: 'hover:bg-purple-500',
  secondary: 'hover:bg-secondary-400 hover:text-secondary-50',
  success: 'hover:bg-success-400 hover:text-secondary-50',
  danger: 'hover:bg-danger-400 hover:text-secondary-50',
  'outline-primary': 'hover:bg-purple-50',
  'outline-secondary': 'hover:border-secondary-400 hover:bg-gray-100 hover:text-secondary-500',
  'outline-success': 'hover:border-success hover:bg-secondary-50 hover:text-success',
  'outline-danger': 'hover:border-danger hover:bg-secondary-50 hover:text-danger',
  'outline-success-secondary': ''
}

const activeColors = {
  primary: 'active:bg-purple-800 active:shadow-sm active:shadow-purple-50',
  secondary: 'active:bg-secondary active:text-secondary-50',
  success: 'active:bg-success active:text-secondary-50',
  danger: 'active:bg-danger active:text-secondary-50',
  'outline-primary':
    ' active:border-primary-800 active:bg-secondary-50 active:text-primary-800 active:shadow-sm active:shadow-purple-50',
  'outline-secondary': 'active:border-secondary active:bg-secondary-50 active:text-secondary',
  'outline-success': 'active:border-success active:bg-secondary-50 active:text-success',
  'outline-danger': 'active:border-danger active:bg-secondary-50 active:text-danger',
  'outline-success-secondary': ''
}

const disabledColors = {
  primary: 'disabled:bg-purple-200 disabled:text-purple-400',
  secondary: 'disabled:bg-secondary-200 disabled:text-secondary-50',
  success: 'disabled:bg-success-200 disabled:text-secondary-50',
  danger: 'disabled:bg-danger-200 disabled:text-secondary-50',
  'outline-primary':
    'disabled:border-primary-200 disabled:text-primary-200 disabled:text-purple-400',
  'outline-secondary':
    'disabled:border-secondary-200 disabled:bg-secondary-50 disabled:text-secondary-200',
  'outline-success':
    'disabled:border-success-200 disabled:bg-secondary-50 disabled:text-success-200',
  'outline-danger': 'disabled:border-danger-200 disabled:bg-secondary-50 disabled:text-danger-200',
  'outline-success-secondary': ''
}
</script>
