<template>
  <BaseInput
    ref="baseInputRef"
    :type="type"
    :label="label"
    :labelClass="labelClass"
    :inputClass="inputClass"
    :touched="touched"
    :required="required"
    :disabled="disabled"
    :readonly="readonly"
    :value="value"
    :placeholder="placeholder"
    :name="name"
    :form="form"
    :isValid="isValid"
    :errors="errors"
    :minLength="minLength"
    :maxLength="maxLength"
    :min="min"
    :max="max"
    :pattern="pattern"
    :clearable="clearable"
    :componentType="componentType"
    @inputChange="(value) => $emit('inputChange', value)"
  >
    <template #default>
      <Icon
        v-if="clearable"
        name="input-close"
        class="absolute cursor-pointer h-5 w-5 right-[10px] top-[11px] text-secondary-400 hover:text-danger"
        @click="clearInput"
      />
    </template>
  </BaseInput>
</template>

<script setup lang="ts">
import { ref, defineEmits, defineProps, withDefaults } from 'vue'
import Icon from '@/components/Icon/Icon.vue'
import BaseInput from '@/components/Inputs/BaseInput.vue'

interface IProps {
  type?: string
  label?: string
  labelClass?: string
  inputClass?: string
  touched?: boolean
  required?: boolean
  disabled?: boolean
  readonly?: boolean
  value?: string | number
  placeholder?: string
  name?: string | number
  form?: string
  isValid?: boolean
  errors?: string[]
  minLength?: string
  maxLength?: string
  min?: number
  max?: number
  pattern?: string
  clearable?: boolean
  componentType?: 'input' | 'floatInput'
}

const emit = defineEmits(['inputChange', 'cleared', 'test'])

withDefaults(defineProps<IProps>(), {
  type: 'text',
  clearable: false,
  componentType: 'input'
})

const baseInputRef = ref(null)

const clearInput = () => {
  console.log('clearInput')
  if (baseInputRef.value) {
    ;(baseInputRef.value as any).clearValue()
    emit('cleared')
    emit('inputChange', '')
  }
}
</script>
