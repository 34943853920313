<template>
  <button
    class="rounded-lg ease-out duration-300"
    :class="[
      colors[variant] ?? colors.primary,
      hoverColors[variant] ?? hoverColors.primary,
      activeColors[variant] ?? activeColors.primary,
      disabledColors[variant] ?? disabledColors.primary
    ]"
    :disabled="disabled"
    :type="type"
    :form="formId"
    @click.stop="$emit('onClick')"
  >
    <template v-if="!loading">
      <Icon :name="icon" :class="iconClass" />
      <slot />
    </template>
    &nbsp;
    <Loader v-else class="w-10 text-center m-auto" />
  </button>
</template>
<script setup lang="ts">
import { defineEmits, defineProps } from 'vue'
import Loader from '@/components/Loader.vue'
import Icon from '@/components/Icon/Icon.vue'
import type { ButtonColors } from '@/components/Buttons/buttons'

interface IProps {
  variant?: ButtonColors
  disabled?: boolean
  type?: string
  icon: string
  iconClass?: string
  loading?: boolean
  formId?: string
}

defineEmits(['onClick'])
withDefaults(defineProps<IProps>(), {
  iconClass: 'size-5'
})

const colors = {
  primary: 'border border-primary-400 bg-secondary-50',
  secondary: 'border border-secondary-400 bg-secondary-50',
  success: 'border border-success-400 bg-success-50',
  danger: 'border border-danger-400 bg-danger-50'
}

const hoverColors = {
  primary: 'hover:bg-primary-50',
  secondary: 'hover:bg-secondary-50',
  success: 'hover:bg-success-50',
  danger: 'hover:bg-danger-50'
}

const activeColors = {
  primary: 'active:bg-primary-100',
  secondary: 'active:bg-secondary-100',
  success: 'active:bg-success-100',
  danger: 'active:bg-danger-100'
}

const disabledColors = {
  primary: 'disabled:bg-primary-200 disabled:bg-secondary-50',
  secondary: 'disabled:bg-secondary-200 disabled:bg-secondary-50',
  success: 'disabled:bg-success-200 disabled:bg-success-50',
  danger: 'disabled:bg-danger-200 disabled:bg-danger-50'
}
</script>
