<template>
  <div class="relative flex flex-col gap-2" v-click-away="closeMenu">
    <Button variant="outline-secondary" class="flex text-nowrap" @onClick="toggle">
      <Icon name="svg-sort" class="size-5 me-2" />
      Sort by
    </Button>

    <slot v-if="displayMenu" name="content" />
  </div>
</template>
<script setup lang="ts">
import Button from '@/components/Buttons/Button.vue'
import Icon from '@/components/Icon/Icon.vue'
import { ref } from 'vue'

const displayMenu = ref(false)

const toggle = () => {
  displayMenu.value = !displayMenu.value
}

const closeMenu = () => {
  displayMenu.value = false
}
</script>
