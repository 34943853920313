<template>
  <div>
    <div v-if="!loading" class="bg-primary-50 py-6 px-4 rounded-r-lg rounded-bl-lg">
      <div class="flex flex-col bg-grey-50 p-4 gap-8 rounded-lg">
        <div
          v-for="chart in charts"
          :key="chart.slug"
          class="flex flex-col gap-6 rounded-lg border border-grey-100"
        >
          <ChartCard
            :title="chart.title"
            :description="chart.description"
            :comparison-label="getComparisonLabel(chart.slug)"
            :avg-years-and-months="data[chart.slug].avgYearsAndMonths"
            :no-data="
              !data[chart.slug] ||
              !Object.values(data[chart.slug].series).length === 0 ||
              Object.values(data[chart.slug].categories).length === 0
            "
          >
            <template #chart>
              <BarChart
                :series="getSeriesForGraph(data[chart.slug])"
                y-axis-title="% Proportion"
                x-axis-title="Years"
                :y-axis-formatter="yFormatter"
                :x-tooltip-formatter="xTooltipFormatter"
                :data-value-formatter="dataFormatter"
                :x-axis-avg-title="chart.xAxisAvgTitle"
                :x-annotation-values="[data[chart.slug].avg]"
                :x-axis-categories="
                  Object.values(data[chart.slug].categories).length > 0
                    ? data[chart.slug].categories
                    : mockCategories
                "
                :no-data="
                  Object.values(data[chart.slug].series).length === 0 ||
                  Object.values(data[chart.slug].categories).length === 0
                "
              />
            </template>
          </ChartCard>
        </div>
      </div>
    </div>
    <BenchamarksTabLoader v-else class="bg-primary-50 py-6 px-4" />
  </div>
</template>
<script setup lang="ts">
import ChartCard from '@/components/Card/ChartCard.vue'
import axios from 'axios'
import { computed, defineProps, ref, onBeforeMount } from 'vue'
import { useRoute } from 'vue-router'
import BarChart from '@/components/Chart/BarChart.vue'
import BenchamarksTabLoader from '@/components/Modals/Candidate/components/BenchamarksTabLoader.vue'

interface IProps {
  candidateId: number
  searchResultId: number
  candidatePosition?: string
  company?: string
}

const props = defineProps<IProps>()
const route = useRoute()
const data = ref([])
const searchId = route.params.searchId
const loading = ref(false)

const charts = computed(() => [
  {
    title: props.candidatePosition + ': Average Tenure at Each Employer',
    slug: 'tenureAtOtherCompanies',
    description: 'This chart compares how long a person typically stays in a ' + props.candidatePosition + ' role at a single employer. ' +
      'The vertical dotted line shows the average, while the purple bar highlights this candidate\'s tenure as a ' + props.candidatePosition + ' at ' + props.company + '.',
    xAxisTitle: "Duration of the candidate's tenure",
    xAxisAvgTitle: 'Average length of time in this position'
  },
  {
    title: props.candidatePosition + ': Total Career Time in Role',
    slug: 'companyTenureForPositionInOtherCompanies',
    description: 'This chart shows the total time someone typically spends as a ' + props.candidatePosition + ' across their entire career, ' +
      'including time in this role at different employers. ' +
      'For example, if someone worked as a ' + props.candidatePosition + ' at three different companies, we add up all that time.',
    xAxisTitle: 'Candidate’s aggregate position',
    xAxisAvgTitle: 'The average aggregate tenure of position'
  },
  {
    title: props.candidatePosition + ': Total Time at Companies',
    slug: 'averageAggregate',
    description: 'This chart shows how long people typically stay at companies where they held a ' + props.candidatePosition + ' role, counting their full time ' +
      'at the company regardless of starting position. ' +
      'For instance, if someone started as an intern and later became a ' + props.candidatePosition + ', we count their ' +
      'entire time at that company, from intern through ' + props.candidatePosition + '.',
    xAxisTitle: 'Candidate’s aggregate position',
    xAxisAvgTitle: 'The average aggregate tenure of position'
  }
])

const mockCategories = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

onBeforeMount(async () => {
  loading.value = true
  const res = await axios.get(
    `${import.meta.env.VITE_LOOKALIKES_API}/api/search-results/${props.searchResultId}/candidate/benchmarks`
  )
  data.value = res.data
  loading.value = false
})

const getSeriesForGraph = (graphInfo) => {
  const graphData = []

  if (graphInfo) {
    if (Object.values(graphInfo.series).length > 0) {
      graphInfo.series.forEach((element, index) => {
        if (graphInfo.selectedCategory === graphInfo.categories[index]) {
          graphData.push({
            x: graphInfo.categories[index],
            y: element,
            fillColor: '#807AFF'
          })
        } else {
          graphData.push({ x: graphInfo.categories[index], y: element })
        }
      })
      return [{ name: 'Proportion', data: graphData }]
    }
  }

  return [
    {
      name: 'Proportion',
      data: [
        { x: 1, y: 1500 },
        { x: 2, y: 1400 },
        { x: 3, y: 1560 },
        { x: 4, y: 1300 },
        { x: 5, y: 1400 },
        { x: 6, y: 1600, fillColor: '#807AFF' },
        { x: 7, y: 1600 },
        { x: 8, y: 1600 },
        { x: 9, y: 1600 },
        { x: 10, y: 1600 }
      ]
    }
  ]
}

const xTooltipFormatter = (val: number): string => `${val} ${val > 1 ? 'Years' : 'Year'}`;
const yFormatter = (val: number): string => `${val}%`;
const dataFormatter = (val: number): string => `${val}%`;

const getComparisonLabel = (chartSlug) => {
  const graphInfo = data.value[chartSlug]

  if (graphInfo) {
    const upDownSame = graphInfo.percentageChangeLabel
    let label = `${Math.abs(graphInfo.percentageChange)}%`

    switch (upDownSame) {
      case 'up':
        return `<span class="text-success-500">${label} Longer</span> <span class="text-secondary-500">than the average of ${graphInfo.avgTenureYearsAndMonths.years} years ${graphInfo.avgTenureYearsAndMonths.months} months</span>`
      case 'down':
        return `<span class="text-danger-500">${label} Shorter</span> <span class="text-secondary-500">than the average of ${graphInfo.avgTenureYearsAndMonths.years} years ${graphInfo.avgTenureYearsAndMonths.months} months</span>`
      case 'same':
        return `<span class="text-secondary-500">Same as the average of ${graphInfo.avgTenureYearsAndMonths.years} years ${graphInfo.avgTenureYearsAndMonths.months} months</span>`
      default:
        return `Data did not load.`
    }
  }

  return ``
}
</script>
