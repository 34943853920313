<template>
  <Dropdown
    :items="items"
    @select="followLink"
    align="end"
    position="absolute max-md:relative md:shadow-lg md:ring-1"
  >
    <template #button="{ isOpen, toggle }">
      <button
        class="inline-flex cursor-pointer items-center p-1 md:p-2 bg-secondary-50 rounded-lg active:text-primary active:bg-primary-50 focus:outline-none"
        :class="[isOpen ? 'bg-secondary-50 md:user-shadow' : 'hover:bg-gray-100']"
        @click="toggle"
      >
        <div
          class="max-md:hidden w-11 h-11 rounded-full me-2 bg-primary flex items-center justify-center"
        >
          <span class="text-white text-2xl font-semibold">{{ user?.first_name.charAt(0) }}</span>
        </div>

        <div class="text-start">
          <div class="text-secondary-900 text-base font-bold">{{ user?.first_name }}</div>
          <div class="text-secondary-500 text-sm font-medium text-nowrap">
            {{ user?.job_title }}
          </div>
        </div>
        <Icon
          :name="isOpen ? 'input-arrow-up' : 'input-arrow-down'"
          class="md:hidden ml-3 w-4 h-4"
        />
      </button>
    </template>

    <template #items="{ selectItem }">
      <div class="p-2 flex flex-col gap-1 max-lg:py-0 max-lg:px-3 md:flex sm:relative">
        <div
          v-for="item in items"
          :key="item.label"
          @click="selectItem(item)"
          class="flex p-2 cursor-pointer items-center hover:bg-secondary-100 hover:rounded-lg"
          :class="item.class"
        >
          <div class="w-6 h-6 content-center me-2 max-md:hidden">
            <Icon :name="item.icon" class="w-6 h-6 m-auto" />
          </div>
          <div class="text-nowrap">
            <Link v-if="item.route" :to="item.route" variant="clear" class="font-semibold">
              {{ item.label }}
            </Link>
            <span v-else class="text-base font-semibold">{{ item.label }}</span>
          </div>
        </div>
      </div>
    </template>
  </Dropdown>
</template>

<script setup lang="ts">
import Icon from '@/components/Icon/Icon.vue'
import Dropdown from '@/components/Dropdown/Dropdown.vue'
import type { DropdownItem } from '@/components/Dropdown/dropdown'
import { useRouter } from 'vue-router'
import Link from '@/components/Link/Link.vue'
import { useUserStore } from '@/stores/user'
import { computed } from 'vue'

const router = useRouter()
const userStore = useUserStore()

const user = computed(() => userStore.user)

const items: DropdownItem[] = [
  {
    label: 'Account & Security',
    icon: 'svg-settings',
    class: 'text-secondary-600',
    callback: () => {
      if(window.innerWidth < 768) {
        router.push({name: 'profile'})
      }
      else {
        router.push({name: 'accountDetails'})
      }
    },
  },
  {
    label: 'Billing',
    icon: 'svg-wallet',
    class: 'text-secondary-600',
    route: { name: 'billing' }
  },
  {
    label: 'Log Out',
    icon: 'auth-logout',
    class: 'text-danger-600 max-md:hidden',
    callback: async () => {
      await userStore.logout()

      router.push({ name: 'login' })
    }
  }
]

const followLink = (item: DropdownItem) => {
  if (item.route) {
    router.push(item.route)
  }

  if (item.callback) {
    item.callback()
  }
}
</script>

<style scoped>
.user-shadow {
  box-shadow:
    0 4px 6px -2px rgba(16, 24, 40, 0.03),
    0 12px 16px -4px rgba(16, 24, 40, 0.08);
}
</style>
