<template>
  <BaseModal
    class="!overflow-hidden"
    modalContentClasses="lg:max-w-[900px] bg-white md:rounded-lg md:shadow-lg w-full max-sm:h-full md:max-w-lg p-3 md:z-30"
    @close="closeModal"
  >
    <template #main>
      <div class="overflow-hidden">
        <div class="agreement-text max-h-[520px] overflow-y-auto">
          <SubscriptionAgreement />
        </div>
        <div class="flex items-center p-4 border-t border-gray-200">
          <Checkbox name="agreement" :required="true" @change="toggleAgree" id="agreement" />
          <label class="ml-2 text-base text-gray-700" for="agreement"
            >I hereby agree to the SaaS agreement and order
          </label>
        </div>
        <div class="flex p-4">
          <Button class="w-full" variant="primary" :disabled="!agreed" @on-click="confirmAgreement">
            Agree and proceed
          </Button>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import BaseModal from '@/components/Modals/BaseModal.vue'

import Button from '@/components/Buttons/Button.vue'
import Checkbox from '@/components/Inputs/Checkbox.vue'
import SubscriptionAgreement from './SubscriptionAgreement.vue'
// Define the events this component can emit
const emit = defineEmits(['close', 'agree'])

// State to track if the user has agreed
const agreed = ref(false)

// Toggle the agreed state when the checkbox changes
const toggleAgree = () => {
  agreed.value = !agreed.value
}

// Emit the agree event when the user confirms
const confirmAgreement = () => {
  if (agreed.value) {
    emit('agree')
    closeModal()
  }
}

// Emit the close event to close the modal
const closeModal = () => {
  emit('close')
}
</script>
