<template>
  <div class="flex items-center justify-between md:py-5 md:px-7 w-full">
    <div class="flex">
      <Button
        variant="outline-secondary"
        class="hidden lg:flex border-0 md:border p-2 md:px-6 md:py-3 max-md:underline max-md:hover:no-underline mr-4"
        @click="props.setPrevChunk"
        :disabled="props.page === 1"
      >
        <<
      </Button>

      <Button variant="outline-secondary" class="paginate-btn" @click="props.setPrevPage" :disabled="props.page === 1">
        <Icon name="input-arrow-left" class="size-5 me-2" />
        Previous
      </Button>
    </div>
    <div class="flex text-gray-500 gap-3">
      <template v-for="page in props.pages" :key="page">
        <button
          :class="`hover:cursor-pointer w-10 h-10 text-center block  rounded text-sm hover:bg-gray-100 ${props.page === page ? 'bg-gray-200' : ''}`"
          v-on="props.pageEvents(page)"
        >
          {{ page }}
        </button>
      </template>
    </div>

    <div class="flex">
      <Button variant="outline-secondary" class="paginate-btn" @click="props.setNextPage" :disabled="props.page === props.totalPages">
        Next
        <Icon name="input-arrow-right" class="size-5 ms-2" />
      </Button>

      <Button
        variant="outline-secondary"
        class="hidden lg:flex border-0 md:border p-2 md:px-6 md:py-3 max-md:underline max-md:hover:no-underline ml-4"
        :disabled="props.page === props.totalPages"
        @click="props.setNextChunk"
      >
        >>
      </Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import Button from '@/components/Buttons/Button.vue'
import Icon from '@/components/Icon/Icon.vue'
import { onMounted, ref, watch } from 'vue'
import { update } from '@intercom/messenger-js-sdk'

const defaultProps = defineProps<{
  props: any
}>()

const isMobile = ref(false)

onMounted(() => {
  checkMobileDevice();
  window.addEventListener('scroll', checkScrollPosition);
});

watch(() => defaultProps.props.page, () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
});

const checkMobileDevice = () => {
  isMobile.value = window.innerWidth < 768;
};

const checkScrollPosition = () => {
  if (isMobile.value) {
    const scrollPosition = window.scrollY + window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    update({ hide_default_launcher: scrollPosition >= documentHeight - 10 })
  }
};

</script>

<style scoped>
.paginate-btn {
  @apply flex border-0 md:border p-2 md:px-6 md:py-3 max-md:underline max-md:hover:no-underline;
}
</style>
