import { defineStore } from 'pinia'
import type { BreadcrumbItem } from '@/components/Breadcrumb/breadcrumbs'

export const useBreadcrumbsStore = defineStore('breadcrumbs', {
  state: () => ({
    breadcrumbs: []
  }),
  getters: {
    getBreadcrumbs: (state) => state.breadcrumbs
  },
  actions: {
    setBreadcrumbs(breadcrumbs: BreadcrumbItem[]) {
      this.breadcrumbs = breadcrumbs
    }
  }
})
