<template>
  <div class="archetype-summary">
    <div class="-ms-1.5 md:hidden">
      <IconButton icon="svg-chevron-left" icon-class="size-5 text-secondary-500" class="border-none" @onClick="scroll('left')"/>
    </div>
    <div class="archetype-summary__items">
      <ArchetypeSummaryCard title="archetype" v-if="data.profileId">
        <a
          :href="`https://www.linkedin.com/in/${data.profileId}`"
          target="_blank"
          rel="noopener noreferrer"
          class="archetype-summary--link"
        >
          <Icon name="svg-linkedin" class="size-5 md:size-6 me-2 lg:me-1" />
          <span>
            {{ data.name }}
          </span>
        </a>
      </ArchetypeSummaryCard>

      <ArchetypeSummaryCard title="position">
        {{ data.position }}
      </ArchetypeSummaryCard>

      <ArchetypeSummaryCard title="employer">
        {{ data.employer }}
      </ArchetypeSummaryCard>

      <ArchetypeSummaryCard title="industry">
        {{ data.industry }}
      </ArchetypeSummaryCard>

      <ArchetypeSummaryCard title="location">
        {{ data.location }}
      </ArchetypeSummaryCard>

      <ArchetypeSummaryCard title="total role exp">
        {{ convertMonthsToYearsMonths(data.roleExp) }}
      </ArchetypeSummaryCard>

      <ArchetypeSummaryCard title="career exp">
        {{ convertMonthsToYearsMonths(data.careerExp) }}
      </ArchetypeSummaryCard>
    </div>
    <div class="-me-1.5 md:hidden">
      <IconButton icon="svg-chevron-right" icon-class="size-5 text-secondary-500" class="border-none" @onClick="scroll('right')"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import ArchetypeSummaryCard from '@/components/ArchetypeSummary/ArchetypeSummaryCard.vue'
import { convertMonthsToYearsMonths } from '@/utils'
import Icon from '@/components/Icon/Icon.vue'
import IconButton from '@/components/Buttons/IconButton.vue'

interface IProps {
  data: Object
}

defineProps<IProps>()

const scroll = (direction) => {
  const content = document.querySelector(".archetype-summary__items");

  if (direction === 'left') {
    content.scrollLeft -= 200;
  } else if (direction === 'right') {
    content.scrollLeft += 200;
  }
}
</script>

<style>
.archetype-summary {
  @apply flex items-center gap-4 md:gap-0;

  .archetype-summary__items {
    @apply w-full flex md:grid md:grid-cols-3 lg:grid-cols-4 gap-4 overflow-auto scroll-smooth;

    .archetype-summary__item {
      @apply p-4 lg:p-2 rounded-lg bg-grey-75 flex-shrink-0 min-w-25;

      .archetype-summary__item__title {
        @apply text-xs leading-5 text-secondary-600 font-extrabold uppercase mb-2 lg:mb-1;
      }

      .archetype-summary__item__body {
        @apply text-sm text-secondary-900 font-medium;
      }
    }

    .archetype-summary--link {
      @apply flex items-center;

      span {
        @apply underline md:no-underline;
      }
    }
  }
}
</style>
