<template>
  <div
    class="bottom-0 top-0 lg:top-[6.25rem] fixed inset-0 bg-gray-800 bg-opacity-50 z-30 lg:z-10 flex justify-end"
    @click.self="$emit('close')"
  >
    <Overview
      :archetype-data="archetypeData"
      :candidate-data="candidateData"
      :candidate-id="candidateId"
      :search-result-id="searchResultId"
      :projects="projects"
      :is-project-page="isProjectPage"
      :project-candidate-id="projectCandidateId"
      @close="$emit('close')"
      @shortlistError="$emit('shortlistError', $event)"
      @addToProjectSuccess="$emit('addToProjectSuccess')"
      @projectCreated="$emit('projectCreated')"
      @shortlisted="$emit('shortlisted', $event)"
    />
  </div>
</template>
<script setup lang="ts">
import { defineProps, onMounted, ref } from 'vue'
import Overview from '@/components/Candidate/Overview/Overview.vue'
import axios from 'axios'
import { useRoute } from 'vue-router'

interface IProps {
  candidateId: number
  searchResultId: number
  projects: any[]
  isProjectPage: boolean
  projectCandidateId: string
}

const emit = defineEmits([
  'shortlisted',
  'close',
  'shortlistError',
  'addToProjectSuccess',
  'projectCreated',
  'candidateDataError'
])
const props = defineProps<IProps>()
const candidateData = ref(null)
const archetypeData = ref(null)

onMounted(async () => {
  try {
    const res = await axios.get(
      `${import.meta.env.VITE_LOOKALIKES_API}/api/search-results/${props.searchResultId}/candidate/overview`
    )

    candidateData.value = res.data.candidateProfileData
    archetypeData.value = res.data.archetypeProfileData
  } catch (error) {
    emit('candidateDataError', error)
  }
})
</script>

<style scoped>
.button-card-shadow {
  box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.04);
}
</style>
