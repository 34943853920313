<template>
  <div class="filter__section">
    <div class="filter__section__name">
      <label v-if="label" class="filter__section__label" :for="name || `form-input-${$.uid}`">
        {{ label }}
      </label>
      <div class="filter__wrap" :class="{ 'mt-3': !active }">
        <div class="filter__field">
          <div class="filter__inputs">
            <div>
              <BasicInput
                class="w-10 ml-4"
                label-class="filter__label"
                input-class="text-secondary-900 text-sm font-medium border-0 border-b border-b-secondary-900 rounded-none hover:rounded-none shadow-none text-center  pb-1 pt-0"
                :value="localValue"
                @change="change"
              />
            </div>
          </div>
        </div>
      </div>
      <button class="filter__section__clear" v-show="clearable" @click.prevent="clear">
        Clear
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import BasicInput from '@/components/Inputs/BasicInput/BasicInput.vue'

interface IProps {
  label?: string
  name?: string
  value?: number
  clearable?: boolean
}
const props = withDefaults(defineProps<IProps>(), {
  clearable: true
})

const localValue = ref(props.value)

watch(
  () => props.value,
  (newVal) => {
    localValue.value = newVal
  }
)

const emit = defineEmits(['clear', 'change'])

const change = (ev: Event) => {
  const value = (ev.target as HTMLInputElement).value
  emit('change', value)
}

const clear = () => {
  localValue.value = undefined
  emit('change', undefined)
  emit('clear')
}
</script>

<style scoped>
.filter__section {
  @apply border-b last:border-b-0 pb-3 last:pb-0;

  .filter__section__name {
    @apply flex justify-between text-sm font-medium;
  }

  .filter__section__label {
    @apply text-secondary-900 self-end;
  }

  .filter__section__clear {
    @apply text-secondary-400 underline hover:no-underline ms-auto;
  }
}

.filter__label {
  @apply text-secondary-500 text-sm font-medium mb-0;
}

.filter__input {
  @apply text-secondary-900 text-sm font-medium border-0 border-b border-b-secondary-900 rounded-none hover:rounded-none shadow-none text-center !px-0 pb-1 pt-0;
}
</style>
