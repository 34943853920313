<template>
  <AuthLayout>
    <template #leftSide>
      <AuthLeftSection class="overflow-hidden xl:max-w-3/4 mx-auto" />
    </template>
    <template #rightSide>
      <div>
        <ApplicationLogo class="mb-8 lg:hidden" variant="white" />
        <div class="mb-8 text-center lg:text-start">
          <h1 class="text-2xl md:text-3xl leading-8 lg:leading-10 font-bold text-secondary-50 mb-4">
            Sign In to your Account
          </h1>
          <p class="text-secondary-300">
            Welcome back to Lookalikes! Please enter your login credentials below to start using the
            app
          </p>
        </div>

        <div class="m-auto w-full">
          <form @submit.prevent="login" class="mb-4">
            <div class="grid gap-4">
              <Message v-if="errorMessage" type="error" :message="errorMessage" />

              <BasicInput
                label="Email"
                name="email"
                type="email"
                :value="formData.email"
                component-type="floatInput"
                required
                @input-change="(value) => (formData.email = value)"
              />

              <PasswordInput
                label="Password"
                name="password"
                :value="formData.password"
                component-type="floatInput"
                required
                @input-change="(value) => (formData.password = value)"
              />
            </div>

            <div class="text-end mt-4 leading-5">
              <Link
                variant="clear"
                link-type="link"
                to="/forgot-password"
                class="font-medium font-third text-primary-300"
                size="sm"
              >
                Forgot Password?
              </Link>
            </div>

            <div class="mt-6">
              <Button type="submit" class="w-full text-center h-11" :loading="loading">
                Login
              </Button>
            </div>
          </form>

          <div class="grid gap-4">
            <Separator text="or" text-class="text-secondary-300 text-sm" class="h-6" />

            <Button
              type="button"
              variant="outline-secondary"
              class="w-full"
              @click="loginWithGoogle"
            >
              <div class="flex justify-center text-center">
                <Icon name="auth-google" class="w-5 h-5 me-2" />
                Sign In with Google
              </div>
            </Button>

            <!-- <Button type="button" variant="outline-secondary" class="w-full">
              <div class="flex justify-center text-center">
                <Icon name="auth-apple" class="w-5 h-5 me-2" />
                Sign In with Apple
              </div>
            </Button> -->
          </div>

          <p class="mt-10 text-center text-sm text-secondary-300">
            Don't have an account?
            <Link
              variant="clear"
              link-type="link"
              to="/register"
              class="font-medium font-third text-primary-300"
              size="sm"
            >
              Sign Up
            </Link>
          </p>
        </div>
      </div>
    </template>
  </AuthLayout>
</template>

<script setup lang="ts">
import { ref, onMounted, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { update } from '@intercom/messenger-js-sdk'
import axios from 'axios'
import { captureException } from '@sentry/vue'
import { useUserStore } from '@/stores/user'
import AuthLayout from '@/components/AuthLayout.vue'
import Separator from '@/components/Separator/Separator.vue'
import BasicInput from '@/components/Inputs/BasicInput/BasicInput.vue'
import Link from '@/components/Link/Link.vue'
import Message from '@/components/Message/Message.vue'
import PasswordInput from '@/components/Inputs/PasswordInput/PasswordInput.vue'
import Button from '@/components/Buttons/Button.vue'
import Icon from '@/components/Icon/Icon.vue'
import ApplicationLogo from '@/components/ApplicationLogo.vue'
import AuthLeftSection from '@/components/AuthLeftSection.vue'

const router = useRouter()
const route = useRoute()
const userStore = useUserStore()

const formData = reactive({
  email: (route.query.email as string) ?? '',
  password: ''
})

const errorMessage = ref('')
const loading = ref(false)

onMounted(() => {
  update({ hide_default_launcher: true })
  if (route.query.error) {
    errorMessage.value = route.query.error as string
  }
})

const login = async () => {
  loading.value = true

  try {
    await axios.get(`${import.meta.env.VITE_LOOKALIKES_API}/sanctum/csrf-cookie`)

    const res = await axios.post(`${import.meta.env.VITE_LOOKALIKES_API}/login`, formData)

    const user = res.data
    userStore.login(user)

    const redirect = router.currentRoute.value.query.redirect || '/'

    router.push(redirect)
  } catch (error) {
    if (error.response && error.response.status === 401) {
      errorMessage.value = error.response.data
    } else {
      captureException(error)
      errorMessage.value = 'Oops,something went wrong. please contact support'
    }
  } finally {
    loading.value = false
  }
}

const loginWithGoogle = async () => {
  await axios.get(`${import.meta.env.VITE_LOOKALIKES_API}/sanctum/csrf-cookie`)

  window.location.href = `${import.meta.env.VITE_LOOKALIKES_API}/auth/google`
}
</script>
