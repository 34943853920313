<template>
  <AuthLayout>
    <template #leftSide>
      <LeftSection />
    </template>
    <template #rightSide>
      <div v-if="resetSuccessfully">
        <ApplicationLogo variant="white" class="lg:hidden"/>
        <div class="mt-8 md:mt-10 lg:mt-8 text-center lg:text-start">
          <h1 class="text-2xl md:text-3xl leading-8 lg:leading-10 font-bold text-secondary-50 mb-2 md:mt-2">
            Password was changed successfully
          </h1>
          <p class="text-secondary-300">
            After a few seconds, you will be redirected to the main page where you will need to sign
            in with a new password
          </p>
        </div>

        <div class="mt-8 md:mt-10 text-center">
          <Link variant="clear" link-type="link" to="/login" class="font-normal text-primary-300">
            Back to Sign In
          </Link>
        </div>
      </div>
      <div v-else>
        <ApplicationLogo variant="white" class="lg:hidden"/>
        <div class="my-8 lg:my-6 text-center lg:text-start">
          <h1 class="text-2xl md:text-3xl leading-8 lg:leading-10 font-bold text-secondary-50 mb-2 md:mt-2">
            Enter New Password
          </h1>
          <p class="text-secondary-300">
            Please enter a password, at least 8 characters.
          </p>
          <Message
            v-if="errorMessage"
            class="mt-4 md:mt-8 lg:mt-4"
            type="error"
            :message="errorMessage"
          />
        </div>

        <div class="m-auto w-full">
          <form @submit.prevent="resetPassword">
            <div class="grid gap-4">
              <BasicInput
                :errors="formErrors?.email"
                :value="formData.email"
                :disabled="route.query.email"
                label="Email"
                name="email"
                type="email"
                component-type="floatInput"
                required
                @input-change="(value) => (formData.email = value)"
              />

              <PasswordInput
                :errors="formErrors?.password"
                :value="formData.password"
                label="New Password"
                name="password"
                component-type="floatInput"
                required
                @input-change="(value) => (formData.password = value)"
              />

              <PasswordInput
                :errors="formErrors?.password_confirmation"
                :value="formData.password_confirmation"
                label="Confirm Password"
                name="password_confirmation"
                component-type="floatInput"
                required
                @input-change="(value) => (formData.password_confirmation = value)"
              />
            </div>

            <div class="mt-6">
              <Button type="submit" class="w-full text-center h-11" :loading="loading">
                Reset Password
              </Button>
            </div>
          </form>

          <div class="mt-8 md:mt-10 text-center">
            <Link variant="clear" link-type="link" to="/login" class="font-normal text-primary-300">
              Back to Sign In
            </Link>
          </div>
        </div>
      </div>
    </template>
  </AuthLayout>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { update } from '@intercom/messenger-js-sdk'
import axios from 'axios'
import { captureException } from '@sentry/vue'
import AuthLayout from '@/components/AuthLayout.vue'
import BasicInput from '@/components/Inputs/BasicInput/BasicInput.vue'
import Link from '@/components/Link/Link.vue'
import Button from '@/components/Buttons/Button.vue'
import ApplicationLogo from '@/components/ApplicationLogo.vue'
import PasswordInput from '@/components/Inputs/PasswordInput/PasswordInput.vue'
import Message from '@/components/Message/Message.vue'
import LeftSection from '@/views/PasswordPage/Components/LeftSection.vue'

onMounted(() => {
  update({ hide_default_launcher: true })
})

const route = useRoute()
const router = useRouter()

const formData = ref({
  email: (route.query.email as string) ?? '',
  password: '',
  password_confirmation: '',
  token: route.query.token
})

const formErrors = ref<null | Record<string, string[]>>(null)
const loading = ref(false)
const resetSuccessfully = ref(false)
const errorMessage = ref(null)

const resetPassword = async () => {
  if (loading.value) return

  if (formData.value.password !== formData.value.password_confirmation) {
    formErrors.value = { password_confirmation: ['Passwords do not match. Please try again'] }
    return
  }

  try {
    loading.value = true
    formErrors.value = null

    await axios.get(`${import.meta.env.VITE_LOOKALIKES_API}/sanctum/csrf-cookie`)

    await axios.post(`${import.meta.env.VITE_LOOKALIKES_API}/reset-password`, formData.value)

    resetSuccessfully.value = true

    setTimeout(() => {
      router.push('/login')
    }, 3000)
  } catch (error) {
    captureException(error)
    formErrors.value = error.response.status === 422 ? error.response.data.errors : null
    errorMessage.value = error.response.status !== 422 ? error.response?.data?.message : null
  } finally {
    loading.value = false
  }
}
</script>
