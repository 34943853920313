<template>
  <div>
    <SectionsLoader v-if="loadingProjects" />
    <div v-else class="flex lg:flex-row flex-col">
      <div class="lg:min-w-90 max-w-90 border-r border-gray-100">
        <Filters title="Filters" :open="isFilterOpen" :table="table" @close="closeFilter" />
      </div>
      <div class="px-4 py-6 md:px-8 md:pt-10 flex flex-col gap-2 flex-1 ">
        <div class="flex flex-row gap-4">
          <div class="flex flex-row justify-between lg:items-center p-0 w-full gap-1.5">
            <div class="flex flex-col items-start p-0 gap-2 lg:mx-auto max-lg:max-w-[783px]">
              <div class="font-bold text-2xl leading-[44px] text-grey-900">Projects</div>
              <div class="max-lg:hidden font-normal text-base leading-5 text-grey-700">
                This page contains all the sourcing projects you have created. Sourcing projects can
                aggregate multiple individual searches and shortlists. Projects can be shared between
                users among the same organization.
              </div>
            </div>
            <div class="flex flex-col items-start">
              <CreateProjectButton
                @create="create"
                class="max-sm:hidden"
                @projectCreated="handleProjectCreated"
                @projectCreationError="handleProjectCreationError"
              />
            </div>
          </div>
        </div>
        <div class="flex flex-row">
          <div class="lg:hidden">
          <span class="font-normal text-base leading-5 text-grey-700">
            This page contains all the sourcing projects you have created. Sourcing projects
            aggregate candidates across multiple searches and shortlists. Projects can be shared
            between users within your organization
          </span>
          </div>
        </div>

        <div class="flex flex-row gap-2 w-full">
          <Button variant="outline-primary" class="flex gap-2 px-3 lg:hidden" @click="openFilter">
            <Icon name="svg-filter" class="size-5" />
            Filters
          </Button>
          <CreateProjectButton class="md:hidden" @create="create" />
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 p-0 gap-7 max-lg:mt-3">
          <template v-for="row in table.getRowModel().rows" :key="row.id">
            <ProjectCard :project="row.original" @remove="remove" />
          </template>
        </div>
        <div class="flex flex-row w-full">
          <Pagination
            v-model="currentPage"
            :records="projects.length"
            :per-page="10"
            @paginate="handlePagination"
            :options="paginationOptions"
          />
        </div>
        <div
          class="flex flex-row md:items-end md:justify-end max-sm:absolute max-sm:left-0 max-sm:mx-1"
        >
          <Message
            v-if="message"
            :type="messageType"
            :message="message"
            :timeout="5000"
            @timeout="message = null"
            class="fixed bottom-0 w-full h-15"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import axios from 'axios'
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  useVueTable
} from '@tanstack/vue-table'

import Filters from '@/views/Projects/ProjectPage/Components/Filters.vue'
import Button from '@/components/Buttons/Button.vue'
import Icon from '@/components/Icon/Icon.vue'
import ProjectCard from '@/views/Projects/ProjectPage/Components/ProjectCard.vue'
import CreateProjectButton from '@/components/Project/CreateProjectButton.vue'
import NewPagination from '@/components/NewPagination.vue'
import Message from '@/components/Message/Message.vue'
import SectionsLoader from '@/components/Loaders/SectionsLoader.vue'
import Pagination from 'v-pagination-3'

interface Project {
  id: number
  name: string
  candidates_count: number
  created_at: string
}

const isFilterOpen = ref(false)
const messageType = ref('success')
const message = ref(null)
const loadingProjects = ref(false)
const projects = ref([])
const currentPage = ref(1)
const paginationOptions = ref({
  template: NewPagination,
  chunk: 6
})
const columnHelper = createColumnHelper<Project>()

const fromToFilterFunction = (row, columnId, filterValue) => {
  return filterValue.from <= row.original[columnId] && filterValue.to >= row.original[columnId]
}

const dateFilterFunction = (row, columnId, filterValue) => {
  const columnDate = new Date(row.original[columnId])
  columnDate.setHours(0, 0, 0, 0)

  return (
    filterValue.from.getTime() <= columnDate.getTime() &&
    filterValue.to.getTime() >= columnDate.getTime()
  )
}

const includeExcludeFilter = (
  row,
  columnId: string,
  filterValue: { label: string; type: string }[]
) => {
  if (filterValue.length === 0) {
    return true
  }

  if (filterValue.some((filter) => filter.type === 'include')) {
    if (typeof row.original[columnId] === 'string') {
      return filterValue.some((filter) => {
        return (
          filter.type === 'include' &&
          row.original[columnId].toLowerCase() === filter.label.toLowerCase()
        )
      })
    } else if (row.original[columnId] === null) {
      return false
    }
  } else {
    if (typeof row.original[columnId] === 'string') {
      let result = true
      filterValue.forEach((filter) => {
        if (
          filter.type === 'exclude' &&
          row.original[columnId].toLowerCase() === filter.label.toLowerCase()
        ) {
          result = false
        }
      })
      return result
    } else if (row.original[columnId] === null) {
      return true
    }
  }
}

const columns = [
  columnHelper.accessor('name', {
    enableColumnFilter: true,
    filterFn: includeExcludeFilter
  }),
  columnHelper.accessor('candidates_count', {
    enableColumnFilter: true,
    filterFn: fromToFilterFunction
  }),
  columnHelper.accessor('created_at', {
    enableColumnFilter: true,
    filterFn: dateFilterFunction
  })
]

const table = useVueTable({
  data: projects,
  initialState: {
    pagination: {
      pageIndex: 0, //custom initial page index
      pageSize: 6 //custom default page size
    }
  },
  columns: columns,
  getCoreRowModel: getCoreRowModel(),
  getFilteredRowModel: getFilteredRowModel(), //client-side filtering
  getFacetedUniqueValues: getFacetedUniqueValues(), // generate unique values for select filter/autocomplete
  getSortedRowModel: getSortedRowModel(),
  getPaginationRowModel: getPaginationRowModel(),
  autoResetPageIndex: false
})

onMounted(() => {
  fetchProjects()
})

const handlePagination = (page: number) => {
  table.setPageIndex(page - 1)
  currentPage.value = page
}

const fetchProjects = async (showLoader = true) => {
  if (showLoader) {
    loadingProjects.value = true
  }
  try {
    const response = await axios.get(`${import.meta.env.VITE_LOOKALIKES_API}/api/projects`)
    projects.value = response.data
  } catch (error) {
  } finally {
    loadingProjects.value = false
  }
}

const openFilter = () => {
  isFilterOpen.value = true
}

const closeFilter = () => {
  isFilterOpen.value = false
}

const handleProjectCreated = async () => {
  message.value = 'Project created successfully'
  messageType.value = 'success'
  await fetchProjects(false)
}

const handleProjectCreationError = (errorMessage: string) => {
  message.value = errorMessage
  messageType.value = 'error'
}

const remove = async (project) => {
  try {
    await axios.delete(`${import.meta.env.VITE_LOOKALIKES_API}/api/projects/${project.id}`)
    message.value = `The “${project.name}” project has been removed successfully`
    messageType.value = 'success'
    await fetchProjects(false)
  } catch (error) {
    message.value = 'Failed to remove project, please contact support'
    messageType.value = 'error'
  }
}
</script>

<style scoped>
.experience-container {
  @apply flex flex-row flex-wrap gap-1 font-normal mt-3;

  .exp-item {
    @apply flex items-center px-2 py-1 text-secondary-700;

    .exp-item__icon {
      @apply size-4 me-1;
    }

    .exp-item__title {
      @apply text-sm;
    }
  }
}

.results-table__header {
  @apply flex flex-col-reverse md:flex-row max-md:flex-wrap gap-6 md:gap-4 mt-6;

  .results-table__header__results-sort {
    @apply flex items-center md:flex-auto justify-between;

    .results-table__header__results {
      @apply ms-0 me-auto text-lg md:text-xl lg:text-2xl font-medium md:font-normal text-secondary-900;
    }

    .results-table__header__sort {
      @apply ms-auto;
    }
  }

  .results-table__header__actions {
    @apply flex gap-4 items-center flex-wrap sm:flex-nowrap;

    .filters-count {
      @apply size-6 text-sm self-center text-current bg-primary-50 rounded;
    }
  }

  .results-table__header__actions > button {
    @apply max-sm:px-3;
  }
}
</style>
