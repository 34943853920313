<template>
  <SearchResultsLoader v-if="pageLoading" />
  <div v-else class="flex lg:flex-row flex-col">
    <div class="lg:min-w-90 max-w-90 border-r border-gray-100">
      <Filters :open="isFilterOpen" :table="table" @close="closeFilter" />
    </div>
    <!-- Beginning of Right side -->

    <div class="p-4 md:p-6 flex-1">
      <div>
        <Breadcrumbs />
        <div class="flex mt-4 md:mt-6">
          <!-- Title -->
          <div class="mt-6 md:mt-4 lg:mt-0">
            <h1 class="text-3xl leading-8 text-secondary-900 font-bold capitalize">
              Project: {{ projectName }}
            </h1>
            <span class="text-sm text-secondary-700 mt-4 block"
              >Created: {{ projectCreatedAt }}</span
            >
          </div>
        </div>

        <!--shortlisted and table actions tablet and desktop -->
        <div class="hidden mt-9 pl-2 md:flex justify-between">
          <div class="ms-0 me-auto">
            <span
              class="text-lg md:text-xl lg:text-2xl leading-8 font-medium md:font-normal text-secondary-900"
            >
              {{ Object.entries(table.getPaginationRowModel().rowsById).length.toLocaleString() }}
              Candidates
            </span>
          </div>

          <div class="flex gap-4">
            <div class="flex">
              <span class="font-medium text-base leading-5 text-primary-700 self-center">
                Shortlisted
              </span>
              <ToggleButton
                class="self-center"
                label=""
                @toggle="filterShortlisted"
                :toggle-state="route.query.shortlisted === 'true'"
              />
            </div>
            <SearchResultSortMenu :table="table" />
            <Button variant="outline-secondary" class="hidden lg:flex" @click="exportTable">
              <Icon name="svg-download" class="size-5 me-2" />
              Export
            </Button>
            <Button variant="outline-primary" class="flex gap-2 lg:hidden px-3" @click="openFilter">
              <Icon name="svg-filter" class="size-5" />
              Filters
              <span class="filters-count">5</span>
            </Button>
            <Button
              variant="primary"
              :loading="riskScoreLoading"
              animation="dots"
              class="flex justify-center items-center px-3"
              @click="sortByRiskScore"
            >
              <template #loading>
                <Icon name="svg-stars" class="size-5 me-2" />Predicting
              </template>
              <Icon name="svg-stars" class="size-5 me-2" />
              Predicted Interest
            </Button>
          </div>
        </div>

        <!-- Number of results and table actions mobile -->
        <div class="md:hidden mt-9 flex flex-col gap-6 justify-between">
          <div class="flex gap-4 justify-between">
            <Button variant="outline-secondary" class="hidden lg:flex" @click="exportTable">
              <Icon name="svg-download" class="size-5 me-2" />
              Export
            </Button>
            <Button variant="outline-primary" class="flex gap-2 lg:hidden px-3" @click="openFilter">
              <Icon name="svg-filter" class="size-5" />
              Filters
              <span class="filters-count">5</span>
            </Button>
            <Button
              variant="primary"
              :loading="riskScoreLoading"
              animation="dots"
              class="flex justify-center items-center px-3"
              @click="sortByRiskScore"
            >
              <template #loading>
                <Icon name="svg-stars" class="size-5 me-2" />Predicting
              </template>
              <Icon name="svg-stars" class="size-5 me-2" />
              Predicted Interest
            </Button>
          </div>

          <div class="flex justify-between">
            <span
              class="self-center text-lg md:text-xl lg:text-2xl font-medium md:font-normal text-secondary-900"
            >
              {{ searchStore.searchResults?.candidateData?.length.toLocaleString() }} search results
            </span>
            <SearchResultSortMenu :table="table" />
          </div>
        </div>

        <!-- Search Results Table -->
        <div class="mt-4 md:border md:border-gray-100 md:rounded-lg md:pt-4 md:px-6">
          <div class="flex flex-col gap-4 md:gap-0 md:divide-y mb-6 md:mb-0">
            <template v-for="row in table.getRowModel().rows" :key="row.id">
              <ProjectCandidateCard
                :candidate-data="row"
                :projects="projects"
                :removing-candidate-id="removingCandidateId"
                @shortlist-error="handleShortlistError"
                @project-created="handleProjectCreated"
                @project-creation-error="handleProjectCreationError"
                @addTo-project-error="handleAddToProjectError"
                @addTo-project-success="handleAddToProjectSuccess"
                @shortlisted="handleShortlisted"
                @remove="handleRemoveCandidate"
              />
            </template>
          </div>
          <Pagination
            v-model="currentPage"
            :records="Object.entries(table.getPaginationRowModel().rowsById).length"
            :per-page="table.getState().pagination.pageSize"
            @paginate="handlePagination"
            :options="paginationOptions"
          />
        </div>
      </div>
    </div>
    <Message
      v-if="shortlistError"
      class="fixed bottom-0 w-full h-15"
      :timeout="3000"
      @timeout="shortlistError = false"
      type="error"
      :message="shortlistErrorMessage"
    />
    <Message
      v-if="error"
      class="fixed bottom-0 w-full h-15"
      :timeout="5000"
      @timeout="error = false"
      type="error"
      :message="errorMessage"
    />
    <Message
      v-if="success"
      class="fixed bottom-0 w-full h-15"
      :timeout="3000"
      @timeout="success = false"
      type="success"
      :message="successMessage"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, onBeforeMount, onUnmounted, onMounted } from 'vue'
import { useRoute } from 'vue-router'

import axios from 'axios'
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  useVueTable
} from '@tanstack/vue-table'
import Pagination from 'v-pagination-3'
import { mkConfig, generateCsv, download } from 'export-to-csv'

import Filters from '@/views/Projects/ProjectsCandidatesPage/Components/Filters.vue'
import Button from '@/components/Buttons/Button.vue'
import Icon from '@/components/Icon/Icon.vue'
import ProjectCandidateCard from '@/views/Projects/ProjectsCandidatesPage/Components/ProjectCandidateCard.vue'
import NewPagination from '@/components/NewPagination.vue'
import Breadcrumbs from '@/components/Breadcrumb/Breadcrumbs.vue'
import SearchResultsLoader from '@/components/Results/SearchResultsLoader.vue'
import SearchResultSortMenu from '@/components/Results/SearchResultSortMenu.vue'
import Message from '@/components/Message/Message.vue'
import ToggleButton from '@/components/ToggleButton.vue'

import { convertMonthsToYearsMonths } from '@/utils/date'
import { useSearchStore } from '@/stores/search'
import { useProjectStore } from '@/stores/project'
import { useBreadcrumbsStore } from '@/stores/breadcrumbs'
import type { SearchResults } from '@/stores/types'

const searchStore = useSearchStore()
const route = useRoute()
const breadcrumbsStore = useBreadcrumbsStore()
const projectStore = useProjectStore()
const columnHelper = createColumnHelper<SearchResults['results']>()
const currentPage = ref(1)
const paginationOptions = ref({
  template: NewPagination,
  chunk: 10
})
const isFilterOpen = ref(false)
const pageLoading = ref(false)
const loadingNewResults = ref(false)
const error = ref(false)
const errorMessage = ref('')
const showArchetypeSummary = ref(false)
const riskScoreLoading = ref(false)
const tableData = ref([])
const shortlistError = ref(false)
const shortlistErrorMessage = ref('')
const projects = ref([])
const success = ref(false)
const successMessage = ref('')
const projectName = ref('')
const projectCreatedAt = ref('')
const shortlistedCount = ref(0)
const removingCandidateId = ref('')

breadcrumbsStore.breadcrumbs = [{ title: 'Projects', route: '/projects' }]

const includeExcludeFilter = (
  row,
  columnId: string,
  filterValue: { label: string; value?: number; type: string }[]
) => {
  if (filterValue.length === 0) {
    return true
  }

  if (filterValue.some((filter) => filter.type === 'include')) {
    if (typeof row.original[columnId] === 'string') {
      return filterValue.some((filter) => {
        return (
          filter.type === 'include' &&
          row.original[columnId].toLowerCase() === filter.label.toLowerCase()
        )
      })
    } else if (typeof row.original[columnId] === 'number') {
      return filterValue.some((filter) => {
        return filter.type === 'include' && row.original[columnId] === filter.value
      })
    } else if (row.original[columnId] === null) {
      return false
    }
  } else {
    if (typeof row.original[columnId] === 'string') {
      let result = true
      filterValue.forEach((filter) => {
        if (
          filter.type === 'exclude' &&
          row.original[columnId].toLowerCase() === filter.label.toLowerCase()
        ) {
          result = false
        }
      })
      return result
    } else if (typeof row.original[columnId] === 'number') {
      let result = true
      filterValue.forEach((filter) => {
        if (filter.type === 'exclude' && row.original[columnId] === filter.value) {
          result = false
        }
      })
      return result
    } else if (row.original[columnId] === null) {
      return true
    }
  }
}

const minExpFilterFunction = (row, columnId, filterValue) => {
  return filterValue <= row.original[columnId]
}

const columns = [
  columnHelper.accessor('ranking_order', {
    enableSorting: true
  }),
  columnHelper.accessor('name', {}),
  columnHelper.accessor('title', {
    enableColumnFilter: true,
    filterFn: includeExcludeFilter
  }),
  columnHelper.accessor('industry', {
    enableColumnFilter: true,
    filterFn: includeExcludeFilter
  }),
  columnHelper.accessor('country', {
    enableColumnFilter: true,
    filterFn: includeExcludeFilter
  }),
  columnHelper.accessor('company_name', {
    enableColumnFilter: true,
    enableSorting: true,
    sortingFn: 'alphanumeric',
    filterFn: includeExcludeFilter
  }),
  columnHelper.accessor('company_size', {
    enableColumnFilter: true,
    filterFn: includeExcludeFilter
  }),
  columnHelper.accessor('state', {
    enableColumnFilter: true,
    filterFn: includeExcludeFilter
  }),
  columnHelper.accessor('city', {
    enableColumnFilter: true,
    filterFn: includeExcludeFilter
  }),
  columnHelper.accessor('final_risk_score', {
    enableSorting: true
  }),
  columnHelper.accessor('total_experience_in_position', {
    enableSorting: true,
    enableColumnFilter: true,
    sortingFn: 'alphanumeric',
    filterFn: minExpFilterFunction
  }),
  columnHelper.accessor('total_career_experience', {
    enableSorting: true,
    enableColumnFilter: true,
    filterFn: minExpFilterFunction
  }),
  columnHelper.accessor('shortlisted', {
    enableColumnFilter: true
  })
]

const table = useVueTable({
  defaultColumn: {
    enableSorting: false
  },
  data: tableData,

  initialState: {
    sorting: [
      {
        id: 'ranking_order',
        desc: false // sort by name in descending order by default
      }
    ],

    pagination: {
      pageIndex: 0, //custom initial page index
      pageSize: 10 //custom default page size
    }
  },

  columns: columns,
  getCoreRowModel: getCoreRowModel(),
  getFilteredRowModel: getFilteredRowModel(), //client-side filtering
  getFacetedUniqueValues: getFacetedUniqueValues(), // generate unique values for select filter/autocomplete
  getSortedRowModel: getSortedRowModel(),
  getPaginationRowModel: getPaginationRowModel(),
  autoResetPageIndex: true
})

const riskScoreColumn = table.getColumn('final_risk_score')
const positionColumn = table.getColumn('title')
const shortlistedColumn = table.getColumn('shortlisted')

onBeforeMount(async () => {
  try {
    pageLoading.value = true
    await projectStore.fetchCandidates(route.params.projectId as string)
    tableData.value = projectStore.getCandidates as never[]
    projectName.value = projectStore.projectName
    projectCreatedAt.value = projectStore.projectCreatedAt
    shortlistedCount.value = projectStore.shortlistedCount
  } catch (error) {
    console.error(error)
  } finally {
    pageLoading.value = false
  }

  const response = await axios.get(`${import.meta.env.VITE_LOOKALIKES_API}/api/projects`)
  projects.value = response.data
})

onMounted(() => {
  updateDynamicValue() // Initial check
  window.addEventListener('resize', updateDynamicValue)
  if (route.query.shortlisted === 'true') {
    shortlistedColumn?.setFilterValue(true)
  }
})

onUnmounted(() => {
  window.removeEventListener('resize', updateDynamicValue)
})

const updateDynamicValue = () => {
  const screenWidth = window.innerWidth
  if (screenWidth < 640) {
    paginationOptions.value.chunk = 3
  } else if (screenWidth < 1440) {
    paginationOptions.value.chunk = 5
  } else {
    paginationOptions.value.chunk = 10
  }
}

const handleShortlistError = (error: Error) => {
  shortlistError.value = true

  shortlistErrorMessage.value = error.message
}

const handlePagination = (page: number) => {
  table.setPageIndex(page - 1)
  currentPage.value = page
}

const openFilter = () => {
  isFilterOpen.value = true
}

const closeFilter = () => {
  isFilterOpen.value = false
}

const sortByRiskScore = (event) => {
  if (riskScoreColumn.getSortIndex() === 0) {
    table.resetSorting()
  } else {
    riskScoreLoading.value = true

    setTimeout(() => {
      riskScoreColumn.toggleSorting(true)
      riskScoreLoading.value = false
    }, 2000)
  }
}

const applyFilters = async (filters) => {
  if (searchStore.searchType === 'title' && !filters.onlyTitleFilterChanged) {
    await jobTitleSearch(filters)
  } else if (searchStore.searchType === 'url' && !filters.onlyTitleFilterChanged) {
    await urlSearch(filters)
  }
  positionColumn?.setFilterValue(filters.title)

  if (filters.onlyTitleFilterChanged) {
    axios.put(`${import.meta.env.VITE_LOOKALIKES_API}/api/search/update-title-filters`, {
      searchId: searchStore.searchId,
      title_include: filters.title_include,
      title_exclude: filters.title_exclude
    })
  }
}

const exportTable = () => {
  const data = table.getPrePaginationRowModel().flatRows.map((row) => {
    return {
      Name: row.original.name,
      Position: row.original.title,
      Employer: row.original.company_name,
      Industry: row.original.industry,
      Location: [row.original.country, row.original.state, row.original.city]
        .filter((x) => x)
        .join(', '),
      'Total Role Exp':
        row.original.total_experience_in_position !== null
          ? convertMonthsToYearsMonths(row.original.total_experience_in_position)
          : 'N\\A',
      'Career Exp':
        row.original.total_career_experience !== null
          ? convertMonthsToYearsMonths(row.original.total_career_experience)
          : 'N\\A',
      'Linkedin Profile URL': `https://www.linkedin.com/in/${row.original.profile_id}`
    }
  })

  const fileName = `${searchStore.searchPayload?.title}_${convertMonthsToYearsMonths(
    searchStore.searchPayload?.total_role_exp
  )}_Role_Exp `

  const csvConfig = mkConfig({
    useKeysAsHeaders: true,
    filename: `lookalikes_search_results_${fileName}_${new Date().toLocaleDateString()}`
  })
  const csv = generateCsv(csvConfig)(data)
  download(csvConfig)(csv)
}

const filterShortlisted = (toggle: boolean) => {
  // this is so that the new shortlisted candidates are displayed
  tableData.value = [...projectStore.getCandidates]

  shortlistedColumn?.setFilterValue(toggle ? true : undefined)

  currentPage.value = 1
}

const handleProjectCreated = async () => {
  success.value = true
  successMessage.value = 'Project created successfully'

  const response = await axios.get(`${import.meta.env.VITE_LOOKALIKES_API}/api/projects`)
  projects.value = response.data
}

const handleProjectCreationError = (errMessage: string) => {
  error.value = true
  errorMessage.value = errMessage
}

const handleAddToProjectSuccess = async (showMessage: boolean) => {
  if (showMessage) {
    success.value = true
    successMessage.value = 'Candidate added to project successfully'
  }

  const response = await axios.get(`${import.meta.env.VITE_LOOKALIKES_API}/api/projects`)
  projects.value = response.data
}

const handleAddToProjectError = (errMessage: string) => {
  error.value = true
  errorMessage.value = errMessage
}

const handleShortlisted = (shortlisted: number) => {
  shortlistedCount.value += shortlisted
}

const handleRemoveCandidate = async (candidateId: string) => {
  removingCandidateId.value = candidateId
  try {
    await projectStore.removeCandidate(candidateId)
    tableData.value = [...projectStore.getCandidates]
  } catch (error) {
    error.value = true
    errorMessage.value = error.response.data.message
  } finally {
    removingCandidateId.value = null
  }
}
</script>

<style>
.experience-container {
  @apply flex flex-row flex-wrap gap-1 font-normal mt-2;

  .exp-item {
    @apply flex items-center px-2 py-1 text-secondary-700;

    .exp-item__icon {
      @apply size-4 me-1;
    }

    .exp-item__title {
      @apply text-sm;
    }
  }
}

.filters-count {
  @apply size-6 text-sm leading-6 self-center text-current bg-primary-50 rounded;
}
</style>
