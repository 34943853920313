<template>
  <div>
    <BaseInput
      :type="showPassword ? 'text' : 'password'"
      :label="label"
      :touched="touched"
      :required="required"
      :disabled="disabled"
      :value="value"
      :placeholder="placeholder"
      :name="name"
      :form="form"
      :isValid="isValid"
      :errors="errors"
      :minLength="minLength"
      :maxLength="maxLength"
      :min="min"
      :max="max"
      :componentType="componentType"
      v-bind="$attrs"
      @inputChange="$emit('inputChange', $event)"
    >
      <template #default>
        <div
          class="absolute cursor-pointer right-[10px] top-[10px]"
          @click="showPassword = !showPassword"
        >
          <Icon
            v-show="!showPassword"
            name="input-eye"
            class="text-secondary-400 stroke-2 hover:stroke-primary h-5 w-5"
          />
          <Icon
            v-show="showPassword"
            name="input-eye-close"
            class="text-secondary-400 stroke-2 hover:stroke-primary h-5 w-5"
          />
        </div>
      </template>
    </BaseInput>
  </div>
</template>

<script setup lang="ts">
import { ref, defineEmits, defineProps, withDefaults } from 'vue'
import Icon from '@/components/Icon/Icon.vue'
import BaseInput from '@/components/Inputs/BaseInput.vue'

interface IProps {
  label?: string
  touched?: boolean
  required?: boolean
  disabled?: boolean
  value?: string | number
  placeholder?: string
  name?: string | number
  form?: string
  isValid?: boolean
  errors?: string[]
  minLength?: string
  maxLength?: string
  min?: number
  max?: number
  componentType?: 'input' | 'floatInput'
}

defineEmits(['inputChange', 'cleared'])

withDefaults(defineProps<IProps>(), {
  componentType: 'input'
})

const showPassword = ref(false)
</script>
