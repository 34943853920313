<template>
  <SubscriptionPlan
    title="Professional"
    subtitle="Per User"
    :price="99"
    pricePeriod="month"
    bgColor="bg-professional-plan"
    actionButtonInActiveState
    bottomTextColorClass="text-white"
    @upgradeNow="upgradeNow"
  >
    <ul class="flex flex-col gap-2 text-white">
      <li class="flex gap-2">
        <Icon name="svg-check" class="size-4" />
        1B+ Person Global Talent Pool
      </li>
      <li class="flex gap-2">
        <Icon name="svg-check" class="size-4" />
        View Full Candidate Resume
      </li>
      <li class="flex gap-2">
        <Icon name="svg-check" class="size-4" />
        Unlimited Searches
      </li>
      <li class="flex gap-2">
        <Icon name="svg-check" class="size-4" />
        Build Custom Archetypes
      </li>
      <li class="flex gap-2 leading-5">
        <Icon name="svg-check" class="size-4" />
        AI Ranks by Predicted Interest™ In A New Job
      </li>
      <li class="flex gap-2 leading-5">
        <Icon name="svg-check" class="size-4" />
        Unlimited Candidate Export
      </li>
      <li class="flex gap-2 leading-5">
        <Icon name="svg-check" class="size-4" />
        FREE Regional Compensation Data
      </li>
      <li class="flex gap-2 leading-5">
        <Icon name="svg-check" class="size-4" />
        FREE Regional Hiring Demand Data
      </li>
      <li class="flex gap-2 leading-5">
        <Icon name="svg-check" class="size-4" />
        FREE Candidate Benchmarks
      </li>
    </ul>
  </SubscriptionPlan>
</template>

<script setup lang="ts">
import SubscriptionPlan from '@/views/User/Billing/Components/SubscriptionPlan.vue'
import Icon from '@/components/Icon/Icon.vue'

import { useUserStore } from '@/stores/user'
const userStore = useUserStore()
const emit = defineEmits(['upgradeNow'])

const daysLeftForTrial = userStore?.getDaysUntilFreeTrialEnds

const subscriptionPlanMap = {
  14: `${import.meta.env.VITE_PADDLE_PROFESSIONAL_14_DAYS_TRIAL_SUBSCRIPTION_ID}`,
  13: `${import.meta.env.VITE_PADDLE_PROFESSIONAL_13_DAYS_TRIAL_SUBSCRIPTION_ID}`,
  12: `${import.meta.env.VITE_PADDLE_PROFESSIONAL_12_DAYS_TRIAL_SUBSCRIPTION_ID}`,
  11: `${import.meta.env.VITE_PADDLE_PROFESSIONAL_11_DAYS_TRIAL_SUBSCRIPTION_ID}`,
  10: `${import.meta.env.VITE_PADDLE_PROFESSIONAL_10_DAYS_TRIAL_SUBSCRIPTION_ID}`,
  9: `${import.meta.env.VITE_PADDLE_PROFESSIONAL_9_DAYS_TRIAL_SUBSCRIPTION_ID}`,
  8: `${import.meta.env.VITE_PADDLE_PROFESSIONAL_8_DAYS_TRIAL_SUBSCRIPTION_ID}`,
  7: `${import.meta.env.VITE_PADDLE_PROFESSIONAL_7_DAYS_TRIAL_SUBSCRIPTION_ID}`,
  6: `${import.meta.env.VITE_PADDLE_PROFESSIONAL_6_DAYS_TRIAL_SUBSCRIPTION_ID}`,
  5: `${import.meta.env.VITE_PADDLE_PROFESSIONAL_5_DAYS_TRIAL_SUBSCRIPTION_ID}`,
  4: `${import.meta.env.VITE_PADDLE_PROFESSIONAL_4_DAYS_TRIAL_SUBSCRIPTION_ID}`,
  3: `${import.meta.env.VITE_PADDLE_PROFESSIONAL_3_DAYS_TRIAL_SUBSCRIPTION_ID}`,
  2: `${import.meta.env.VITE_PADDLE_PROFESSIONAL_2_DAYS_TRIAL_SUBSCRIPTION_ID}`,
  1: `${import.meta.env.VITE_PADDLE_PROFESSIONAL_1_DAYS_TRIAL_SUBSCRIPTION_ID}`,
  0: `${import.meta.env.VITE_PADDLE_PROFESSIONAL_0_DAYS_TRIAL_SUBSCRIPTION_ID}`
}

const upgradeNow = () => {
  emit('upgradeNow', subscriptionPlanMap[daysLeftForTrial], 'professional')
}
</script>
