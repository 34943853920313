<template>
  <div>
    <div class="relative" v-click-away="closeModal">
      <Button
        class="flex w-max bg-primary-100 !text-primary-700 hover:!bg-primary-50 hover:!border hover:!border-primary-700 p-3 px-6 gap-2"
        variant="primary"
        @onClick="toggleModal"
      >
        <Icon name="svg-add" class="size-5 text-primary-700" />
        Create a New Project
      </Button>

      <div
        v-if="visible"
        class="flex flex-col absolute bg-white border top-14 w-[303px] h-[211px] border-primary p-4 rounded-lg z-20 right-1 gap-3"
      >
        <div class="flex flex-row">
          <span class="text-lg leading-[25px] font-semibold"> Create a New Project </span>
        </div>
        <div class="flex flex-col leading-6 gap-3">
          <div class="flex leading-6 text-md">
            <BasicInput
              name="project"
              placeholder="Type name of project"
              required
              class="w-full"
              :value="projectName"
              @input-change="(value) => (projectName = value)"
            />
          </div>
          <div class="flex flex-col">
            <Button
              variant="primary"
              @click="createNewProject"
              :disabled="!projectName"
              :loading="loading"
            >
              Create
            </Button>
            <Button
              variant="outline-secondary"
              class="border-none hover:bg-transparent hover:underline !p-0"
              @onClick="closeModal"
              >Cancel</Button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import Icon from '@/components/Icon/Icon.vue'
import Button from '@/components/Buttons/Button.vue'
import BasicInput from '@/components/Inputs/BasicInput/BasicInput.vue'
import { defineEmits, ref } from 'vue'
import axios from 'axios'

const emit = defineEmits(['create', 'projectCreated', 'projectCreationError'])

const visible = ref(false)
const projectName = ref('')
const loading = ref(false)

const closeModal = () => {
  visible.value = false
}

const toggleModal = () => {
  visible.value = !visible.value
}

const createNewProject = async () => {
  if (!projectName.value) {
    return
  }

  try {
    loading.value = true
    await axios.post(`${import.meta.env.VITE_LOOKALIKES_API}/api/projects`, {
      name: projectName.value
    })
    emit('projectCreated')
  } catch (error) {
    if (error.response.status === 400) {
      emit('projectCreationError', 'Project name already exists')
    } else {
      emit('projectCreationError', 'Oops! Something went wrong, please contact support.')
    }
  } finally {
    loading.value = false
    visible.value = false
  }
}
</script>
