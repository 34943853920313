<template>
  <div>
    <form @submit.prevent="updatePassword" class="grid gap-4 md:gap-6 lg:w-1/2">
      <Message :type="updateStatus" :message="updateMessage"/>
      <ProfileCard>
        <PasswordInput
          :errors="formErrors?.current_password"
          :value="form.current_password"
          label="Current Password"
          name="password"
          required
          min-length="8"
          @input-change="(value) => (form.current_password = value)"
        />
      </ProfileCard>

      <ProfileCard>
        <div class="grid gap-4">
          <PasswordInput
            :errors="formErrors?.password"
            :value="form.password"
            label="New Password"
            name="password"
            required
            min-length="8"
            @input-change="(value) => (form.password = value)"
          />
          <PasswordInput
            :errors="formErrors?.confirm_password"
            :value="form.confirm_password"
            label="Confirm New Password"
            name="password"
            required
            min-length="8"
            @input-change="(value) => (form.confirm_password = value)"
          />
        </div>
        <div class="md:text-end mt-6">
          <Button
            type="submit"
            variant="primary"
            class="w-full md:w-auto h-11"
            :loading="loading"
          >
            Change password
          </Button>
        </div>
      </ProfileCard>
    </form>
  </div>
</template>

<script setup lang="ts">
import PasswordInput from '@/components/Inputs/PasswordInput/PasswordInput.vue'
import axios from 'axios'
import { captureException } from '@sentry/vue'
import { reactive, ref } from 'vue'
import Message from '@/components/Message/Message.vue'
import Button from '@/components/Buttons/Button.vue'
import ProfileCard from '@/views/User/Components/ProfileCard.vue'
import type { MessageTypes } from '@/components/Inputs/inputs'

const initialState = {
  password: '',
  confirm_password: '',
  current_password: '',
};

const form = reactive({...initialState})
const formErrors = ref(null);
const loading = ref(false);
const updateStatus = ref<MessageTypes>(null);
const updateMessage = ref(null);

const updatePassword = async () => {
  if(loading.value)
    return
  
  formErrors.value = null
  loading.value = true

  try {
    await axios.put(`${import.meta.env.VITE_LOOKALIKES_API}/api/profile/update-password`, form)
    updateStatus.value = 'success'
    updateMessage.value = 'Password success updated!'

    Object.assign(form, initialState);
  } catch (error) {
    if (error.response?.status === 422) {
      formErrors.value = error.response.data.errors
    } else {
      captureException(error)
      updateStatus.value = 'error'
      updateMessage.value = error.response?.message ?? 'Oops something went wrong. please contact our support team'
    }
  } finally {
    loading.value = false
  }
}
</script>
