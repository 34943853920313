<template>
  <SectionBlock title="Work experience(s)">
    <div class="grid gap-4 md:gap-6">
      <div class="flex gap-2 md:gap-6 flex-col xl:flex-row">
        <div class="mb-1 md:mb-0 xl:w-37.5 text-secondary-900">
          <span class="text-sm font-medium leading-6">Experience level:</span>
        </div>
        <div class="flex gap-2 md:gap-6 flex-col md:flex-row">
          <div class="lg:max-w-75 md:basis-1/2">
            <BasicInput
              name="roleMinYears"
              label="Min Years in Current Role"
              label-class="!font-medium"
              clearable
              :value="props.roleMinYears ? simplifiedYearConversion(props.roleMinYears) : ''"
              @inputChange="(value: number) => $emit('roleMinYearsChange', value * MONTHS_PER_YEAR)"
            />
          </div>
          <div class="lg:max-w-75 md:basis-1/2">
            <BasicInput
              name="careerMinYears"
              label="Min Years Career Experience"
              label-class="!font-medium"
              clearable
              :value="props.careerMinYears ? simplifiedYearConversion(props.careerMinYears) : ''"
              @inputChange="
                (value: number) => $emit('careerMinYearsChange', value * MONTHS_PER_YEAR)
              "
            />
          </div>
        </div>
      </div>
      <div class="p-4 grid gap-6 bg-secondary-50 rounded-lg" v-if="localPastRoles.length">
        <PastExperienceRole
          v-for="(role, index) in localPastRoles"
          :key="role.id"
          :past-role="role"
          :index="index"
          :show-remove-button="localPastRoles.length > 1"
          @pastRoleTitleChange="pastRoleTitleChange"
          @pastRoleMinYearsChange="pastRoleMinYearsChange"
          @remove-past-role="(index: number) => removePastRole(index)"
        />
      </div>
      <div class="text-end">
        <Button variant="outline-primary" type="button" class="max-md:w-full" @click="addPastRole">
          Add past experience role
        </Button>
      </div>
    </div>
  </SectionBlock>
</template>

<script setup lang="ts">
import { defineEmits, reactive, onMounted } from 'vue'
import SectionBlock from '@/components/Tabs/SectionBlock.vue'
import BasicInput from '@/components/Inputs/BasicInput/BasicInput.vue'
import PastExperienceRole from '@/views/Search/Components/PastExperienceRole.vue'
import Button from '@/components/Buttons/Button.vue'

import { simplifiedYearConversion } from '@/utils/date'

import type { PastRole } from '@/stores/types'

interface IProps {
  roleMinYears?: number | null
  careerMinYears?: number | null
  pastRoles?: PastRole[] | []
}

const props = withDefaults(defineProps<IProps>(), {
  roleMinYears: null,
  careerMinYears: null,
  pastRoles: () => []
})

const emit = defineEmits(['roleMinYearsChange', 'careerMinYearsChange', 'pastRolesChange'])

const MONTHS_PER_YEAR = 12
const localPastRoles = reactive<PastRole[]>(
  props.pastRoles.length > 0
    ? props.pastRoles
    : [{ id: Date.now(), title: '', canonical_title: '', minYears: null }]
)

onMounted(() => {
  if (props.pastRoles.length) {
    emit('pastRolesChange', props.pastRoles)
  }
  if (props.roleMinYears) {
    emit('roleMinYearsChange', props.roleMinYears)
  }
  if (props.careerMinYears) {
    emit('careerMinYearsChange', props.careerMinYears)
  }
})

const addPastRole = () => {
  localPastRoles.push({ id: Date.now(), title: '', canonical_title: '', minYears: null })
}

const removePastRole = (index: number) => {
  localPastRoles.splice(index, 1)
  emit('pastRolesChange', localPastRoles)
}

const pastRoleTitleChange = (index: number, title: string, canonical_title: string) => {
  localPastRoles[index].title = title
  localPastRoles[index].canonical_title = canonical_title
  emit('pastRolesChange', localPastRoles)
}

const pastRoleMinYearsChange = (index: number, minYears: number | string) => {
  if (minYears === '') {
    localPastRoles[index].minYears = null
  } else {
    localPastRoles[index].minYears = minYears * 12
  }
  emit('pastRolesChange', localPastRoles)
}
</script>
