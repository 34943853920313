<template>
  <Tabs
    :tabs="tabs"
    tabs-class="flex max-sm:w-full w-auto md:p-4 gap-4 md:bg-primary-50 rounded-t-lg md:max-h-16"
    class="px-4 md:px-8 md:py-6"
    v-model="currentTab"
    :row-direction="true"
    active-tab-item-class="!px-4 text-primary md:border-primary md:text-secondary-900 md:bg-secondary-50 md:border-secondary-300 max-sm:w-full max-sm:bg-primary-50 max-sm:!rounded-b-none"
    tab-item-class="!px-4 text-secondary-900 md:border-transparent border-secondary-100 bg-transparent md:text-secondary-600 max-sm:w-full max-sm:border-none max-sm:!rounded-b-none"
  >
    <template #actions>
      <slot name="actions" />
    </template>
    <template #tab-overview-body>
      <div class="flex flex-row relative">
        <div
          v-if="archetypeData"
          class="max-sm:hidden absolute right-9 top-9 !px-0 flex flex-row border-none hover:bg-transparent hover:underline"
        >
          <span
            v-if="displayCompareTitle"
            class="font-medium text-base leading-5 text-primary-700 hover:cursor-pointer"
            @click="toggleCompare"
            >Compare with archetype</span
          >
          <Switch name="compare" class="ms-3" :checked="compare" @change="toggleCompare" />
        </div>
        <OverviewTab :data="candidateData" @compare="toggleCompare" />
        <OverviewTab v-if="compare" :data="archetypeData" :is-archetype="true" />
      </div>
    </template>
    <template #tab-benchmarks-body>
      <BenchmarksTab
        :company="candidateData.workExperience[0].company_name ?? null"
        :candidate-position="candidateData.workExperience[0].title ?? null"
        :candidate-id="candidateId"
        :search-result-id="searchResultId"
      />
    </template>
    <template #tab-market-data-body>
      <MarketDataTab :search-result-id="searchResultId" />
    </template>
  </Tabs>
</template>
<script setup lang="ts">
import OverviewTab from '@/components/Modals/Candidate/components/OverviewTab.vue'
import MarketDataTab from '@/components/Modals/Candidate/components/MarketDataTab.vue'
import BenchmarksTab from '@/components/Modals/Candidate/components/BenchmarksTab.vue'
import Tabs from '@/components/Tabs/Tabs.vue'
import { defineEmits, defineProps, onMounted, ref, watch, withDefaults } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Button from '@/components/Buttons/Button.vue'
import Switch from '@/components/Inputs/Switch.vue'

interface IProps {
  candidateData: object
  archetypeData?: object
  candidateId: number
  displayCompareTitle?: boolean
  searchResultId: number
}

defineProps<IProps>()

const route = useRoute()
const router = useRouter()
const emit = defineEmits(['compare'])

const tabs = [
  {
    title: 'Overview',
    slug: 'overview'
  },
  {
    title: 'Benchmarks',
    slug: 'benchmarks'
  },
  {
    title: 'Market Data',
    slug: 'market-data'
  }
]

const currentTab = ref(0)
const compare = ref(false)
onMounted(() => {
  if (route.query.tab) {
    currentTab.value = tabs.findIndex((t) => t.slug === route.query.tab)
  }
})

const toggleCompare = () => {
  compare.value = !compare.value

  emit('compare', compare.value)
}
</script>
