<template>
  <div class="filter-overlay">
    <div
      class="backdrop"
      :class="isOpen ? 'block lg:hidden' : 'opacity-0 hidden'"
      @click="close"
    ></div>
    <div class="filter" :class="[{ 'is-open filter-shadow': isOpen }, $attrs.class]">
      <div class="filter__header">
        <div class="filter__title">
          <Icon name="svg-filter" class="hidden lg:block w-5 h-5 me-1" />
          {{ title }}
        </div>
        <div class="hidden lg:flex ms-auto">
          <Button variant="primary" @click="applyFilters"> Apply Filters</Button>
        </div>
        <div
          class="max-md:absolute max-md:right-0 self-center ms-auto p-2.5 cursor-pointer lg:hidden"
          @click="close"
        >
          <Icon name="input-close" class="bg-grey-75 text-secondary-300 rounded-full size-6 p-1" />
        </div>
      </div>

      <div class="filter__body">
        <FilterDropdown
          label="Job Title"
          :values="selectedJobTitles"
          :options="jobTitles"
          @select="(val) => (selectedJobTitles = val)"
          @clear="(val) => (selectedJobTitles = val)"
        />
        <FilterDropdown
          label="Country"
          :values="selectedCountries"
          :options="countries"
          @select="(val) => (selectedCountries = val)"
          @clear="(val) => (selectedCountries = val)"
        />
        <FilterDropdown
          label="State"
          :values="selectedStates"
          :options="states"
          @select="(val) => (selectedStates = val)"
          @clear="(val) => (selectedStates = val)"
        />
        <FilterDropdown
          label="City"
          :values="selectedCities"
          :options="cities"
          @select="(val) => (selectedCities = val)"
          @clear="(val) => (selectedCities = val)"
        />
        <FilterDropdown
          label="Industry"
          :values="selectedIndustries"
          :options="industries"
          @select="(val) => (selectedIndustries = val)"
          @clear="(val) => (selectedIndustries = val)"
        />
        <FilterNumber
          label="Min Years in Current Role"
          :value="selectedRoleExperience"
          @change="(val) => (selectedRoleExperience = val)"
          @clear="(val) => (selectedRoleExperience = '')"
        />
        <FilterNumber
          label="Min Years in Career"
          :value="selectedCareerExperience"
          @change="(val) => (selectedCareerExperience = val)"
          @clear="(val) => (selectedCareerExperience = '')"
        />
      </div>
      <div class="filter__footer">
        <Button type="button" variant="primary" @click="applyFilters"> Apply Filters</Button>

        <Button
          type="reset"
          variant="outline-secondary"
          class="text-secondary-600"
          @click="resetFilters"
        >
          <div class="flex justify-center">
            <Icon name="svg-trash" class="hidden lg:block w-5 h-5 me-2" />
            Clear Filters
          </div>
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'

import Icon from '@/components/Icon/Icon.vue'
import Button from '@/components/Buttons/Button.vue'
import FilterDropdown from '@/components/Filters/FilterDropdown.vue'
import FilterNumber from '@/components/Filters/FilterNumber.vue'

interface IProps {
  open?: boolean
  title?: string
  table: any
}

defineOptions({
  inheritAttrs: false
})

const emit = defineEmits(['close'])
const props = withDefaults(defineProps<IProps>(), {
  open: false,
  title: 'Filters'
})

const isOpen = ref<boolean>(false)

const selectedJobTitles = ref<string[]>([])
const selectedCountries = ref<string[]>([])
const selectedStates = ref<string[]>([])
const selectedCities = ref<string[]>([])
const selectedIndustries = ref<string[]>([])
const selectedRoleExperience = ref<number | string>('')
const selectedCareerExperience = ref<number | string>('')
const titleFilter = props.table.getColumn('title')
const countriesFilter = props.table.getColumn('countries')
const statesFilter = props.table.getColumn('states')
const citiesFilter = props.table.getColumn('cities')
const industriesFilter = props.table.getColumn('industries')
const careerExperienceFilter = props.table.getColumn('career_experience')
const roleExperienceFilter = props.table.getColumn('role_experience')

const jobTitles = Array.from(titleFilter.getFacetedUniqueValues().keys())
  .filter((val) => val !== '' && val !== null)
  .sort()

const countries = Array.from(countriesFilter.getFacetedUniqueValues().keys())
  .filter((val) => val !== '' && val !== null)
  .sort()

const states = Array.from(statesFilter.getFacetedUniqueValues().keys())
  .filter((val) => val !== '' && val !== null)
  .sort()

const cities = Array.from(citiesFilter.getFacetedUniqueValues().keys())
  .filter((val) => val !== '' && val !== null)
  .sort()

const industries = [
  ...new Set(Array.from(industriesFilter.getFacetedUniqueValues().keys()).flat())
].sort()

const applyFilters = () => {
  titleFilter.setFilterValue(selectedJobTitles.value)
  countriesFilter.setFilterValue(selectedCountries.value)
  statesFilter.setFilterValue(selectedStates.value)
  citiesFilter.setFilterValue(selectedCities.value)
  industriesFilter.setFilterValue(selectedIndustries.value)
  roleExperienceFilter.setFilterValue(selectedRoleExperience.value * 12)
  careerExperienceFilter.setFilterValue(selectedCareerExperience.value * 12)
}

const resetFilters = () => {
  selectedJobTitles.value = []
  selectedCountries.value = []
  selectedStates.value = []
  selectedCities.value = []
  selectedIndustries.value = []
  selectedRoleExperience.value = ''
  selectedCareerExperience.value = ''

  props.table.resetColumnFilters()
}

const close = () => {
  isOpen.value = false
  emit('close')
}

watch(
  () => props.open,
  (openStatus) => {
    if(openStatus) {
      document.body.classList.add('max-lg:overflow-hidden');
    }
    else{
      document.body.classList.remove('max-lg:overflow-hidden');
    }
    isOpen.value = openStatus
  }
)
</script>

<style>
@keyframes slideFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideFromBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.filter {
  @apply md:max-w-90 hidden lg:block w-full py-5 md:pt-20 lg:pt-10 px-4 md:px-8 lg:px-4 bg-secondary-50 overscroll-contain lg:animate-none;
  animation: slideFromBottom 0.5s ease-out forwards;

  .filter__header {
    @apply flex py-2.5 lg:pt-0 lg:pb-4 justify-center md:justify-between items-center md:border-b border-secondary-100 relative;

    .filter__title {
      @apply flex text-lg leading-6 lg:text-xl text-center md:text-start font-bold lg:font-semibold text-grey-900;
    }
  }

  .filter__body {
    @apply mt-4 lg:mt-6 lg:px-2 grid gap-3;

    .filter__section {
      @apply border-b last:border-b-0 pb-3 last:pb-0;

      .filter__section__name {
        @apply flex justify-between text-sm font-medium;
      }

      .filter__section__label {
        @apply text-secondary-900;
      }

      .filter__section__clear {
        @apply text-secondary-400 underline hover:no-underline ms-auto;
      }
    }
  }

  .filter__footer {
    @apply grid gap-4 mt-10;
  }
}

.filter.is-open {
  @apply z-[2147483600] block md:max-h-[100vh] md:h-[calc(100%-80px)] lg:max-h-none max-md:rounded-t-20 max-lg:fixed inset-0 md:left-auto md:overflow-y-auto;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .filter {
    @apply border-t border-t-secondary-100;
    animation: slideFromRight 0.5s ease-out forwards;
  }
  .filter-shadow.is-open {
    box-shadow:
      0px 47px 47px 0px rgba(117, 117, 117, 0.09),
      0px 12px 26px 0px rgba(117, 117, 117, 0.1);
  }
}

.backdrop {
  @apply z-[2147483600] transition-opacity ease-linear duration-150 w-screen h-screen fixed inset-0 bg-secondary-950/50 md:bg-transparent;
}
</style>
