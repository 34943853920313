const usStateAbbreviations = {
  Alabama: 'AL',
  Alaska: 'AK',
  'American Samoa': 'AS',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  'District of Columbia': 'DC',
  'Federated States of Micronesia': 'FM',
  Florida: 'FL',
  Georgia: 'GA',
  'Guam GU': 'GU',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  'Marshall Islands': 'MH',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  'Northern Mariana Islands': 'MP',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Palau: 'PW',
  Pennsylvania: 'PA',
  'Puerto Rico': 'PR',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  'Virgin Islands': 'VI',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY'
}

const australianStateAbbreviations = {
  'Australian Capital Territory': 'ACT',
  'New South Wales': 'NSW',
  'Northern Territory': 'NT',
  Queensland: 'QLD',
  'South Australia': 'SA',
  Tasmania: 'TAS',
  Victoria: 'VIC',
  'Western Australia': 'WA'
}

const canadianStateAbbreviations = {
  Alberta: 'AB',
  'British Columbia': 'BC',
  Manitoba: 'MB',
  'New Brunswick': 'NB',
  'Newfoundland and Labrador': 'NL',
  'Northwest Territories': 'NT',
  'Nova Scotia': 'NS',
  Nunavut: 'NU',
  Ontario: 'ON',
  'Prince Edward Island': 'PE',
  Quebec: 'QC',
  Saskatchewan: 'SK',
  Yukon: 'YT'
}

export const locationShortenFormat = (country, state, city, cityFirst): string => {
  let shortenCountry = country

  switch (country) {
    case 'United States':
      shortenCountry = 'USA'
      break
    case 'Australia':
      shortenCountry = 'AUS'
      break
    case 'Canada':
      shortenCountry = 'CAN'
      break
    case 'Israel':
      shortenCountry = 'ISR'
      break
  }

  let shortenState = state

  if (shortenCountry == 'USA' && usStateAbbreviations[state] !== undefined) {
    shortenState = usStateAbbreviations[state]
  }

  if (shortenCountry == 'AUS' && australianStateAbbreviations[state] !== undefined) {
    shortenState = australianStateAbbreviations[state]
  }

  if (shortenCountry == 'CAN' && canadianStateAbbreviations[state] !== undefined) {
    shortenState = canadianStateAbbreviations[state]
  }

  if (cityFirst) {
    return (
      (city ? city + ', ' : '') + (shortenState ? shortenState + ', ' : '') + (shortenCountry ?? '')
    )
  }

  return (
    (shortenCountry ?? '') + (shortenState ? ', ' + shortenState : '') + (city ? ', ' + $city : '')
  )
}
