<template>
  <div class="flex flex-col gap-8 rounded-lg border border-grey-100">
    <ChartCard
      title="Average Annual Salary History"
      description="HR Signal’s salary history data varies by position."
      :avg-years-and-months="`$${Math.floor(averageAnnualSalary).toLocaleString('en-US')} USD/yr`"
      :comparison-label="percentageValueTitle"
      :no-data="Object.values(props.salaryHistory).length === 0"
    >
      <template #chart>
        <AreaChart
          :series="series"
          :categories="categories"
          :data-value-formatter="formatter"
        />
      </template>
    </ChartCard>
  </div>
</template>
<script setup lang="ts">
import AreaChart from '@/components/Chart/AreaChart.vue'
import ChartCard from '@/components/Card/ChartCard.vue'
import { computed, defineProps } from 'vue'

interface IProps {
  currency?: string,
  salaryHistory: object[],
  averageAnnualSalary?: string,
  salaryChangeLabel?: string,
  monthsAgo?: string,
  annualSalaryPercentageChange?: string
}

const props = withDefaults(defineProps<IProps>(), {
  currency: '$'
})

const mockSeries = [1500, 1400, 1450, 1350, 1300, 1550]
const mockCategories = [1, 2, 3, 4, 5, 6, 7]



const series = computed(
  () => [
    {
      name: 'Salary',
      data: Object.values(props.salaryHistory).length > 0 ? props.salaryHistory.map((data) => data.salary) : mockSeries
    }
  ]
)

const categories = computed(
  () => {
    return Object.values(props.salaryHistory).length > 0 ? props.salaryHistory.map((data) => {
      const date = new Date(data.timestamp)

      return `${date.toLocaleString('default', { month: 'short' })}`
    }) : mockCategories
  }
)

const percentageValueTitle = computed(() => {
  let label = `${Math.abs(props.annualSalaryPercentageChange)}%`

  switch (props.salaryChangeLabel) {
    case 'up':
      return `<span class="text-success-500">${label} higher</span> <span class="text-secondary-500">compared to ${props.monthsAgo} months ago</span>`
    case 'down':
      return `<span class="text-danger-500">${label} lower</span> <span class="text-secondary-500">compared to ${props.monthsAgo} months ago</span>`
    case 'same':
      return `<span class="text-secondary-500">same as compared to ${props.monthsAgo} months ago</span>`
    default:
      return '<span class="text-secondary-500">Data for this position/location did not load.</span>'
  }
})

const formatter = (val: number): string => `${props.currency}${Math.floor(val).toLocaleString('en-US')}`
</script>
