<template>
  <SectionBlock title="Candidate Location(s)">
    <div class="flex flex-col md:flex-row gap-3 md:gap-6">
      <SelectInput
        class="flex flex-col w-full md:max-w-[454px] gap-2"
        label="Country:"
        label-class="w-full md:max-w-37.5 text-secondary-900 !font-medium"
        placeholder="Type and select"
        :options="countryOptions"
        :clear-on-blur="true"
        :selected-value="selectedCountries"
        @change="onCountryChange"
      />
      <SelectInput
        class="flex flex-col w-full lg:max-w-[820px] gap-2"
        label="State or region:"
        label-class="w-full md:max-w-37.5 text-secondary-900 !font-medium"
        ref="statesSelect"
        placeholder="Type and select"
        :options="stateOptions"
        :clear-on-blur="true"
        :selected-value="selectedStates"
        :hide-option-if-search-empty="true"
        :disabled="!selectedCountries || selectedCountries.length === 0"
        @change="onStateChange"
      />
      <SelectInput
        class="flex flex-col w-full lg:max-w-[820px] gap-2"
        ref="citiesSelect"
        label="City:"
        label-class="w-full md:max-w-37.5 text-secondary-900 !font-medium"
        placeholder="Type and select"
        :options="cityOptions"
        :hide-option-if-search-empty="true"
        :clear-on-blur="true"
        :disabled="!selectedStates || selectedStates.length === 0"
        :selected-value="selectedCities"
        @change="onCityChange"
      />
    </div>
  </SectionBlock>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'

import SectionBlock from '@/components/Tabs/SectionBlock.vue'
import SelectInput from '@/components/Inputs/Select/SelectInput.vue'

import { useSearchStore } from '@/stores/search'

import axios from 'axios'

import { countries } from '@/data/locations'
import { getLocationRecentSearches } from '@/utils/recent-searches'

interface IProps {
  selectedCountries: string[] | null
  selectedStates: string[] | null
  selectedCities: string[] | null
}

const props = defineProps<IProps>()
const emit = defineEmits([
  'countryChange',
  'stateChange',
  'cityChange',
  'stateOptions',
  'cityOptions'
])

const searchStore = useSearchStore()
const dynamicStatesOptions = ref(searchStore.statesOptions)
const dynamicCitiesOptions = ref(searchStore.citiesOptions)
const selectedCountries = ref(props.selectedCountries ?? ['United States'])
const selectedStates = ref(props.selectedStates ?? [])
const selectedCities = ref(props.selectedCities ?? [])
const recentlySearchedCountries = ref(getLocationRecentSearches('countries', 3))
const recentlySearchedStates = ref(
  getLocationRecentSearches('states', 3, searchStore.searchLocation.states)
)
const recentlySearchedCities = ref(
  getLocationRecentSearches('cities', 3, searchStore.searchLocation.cities)
)
const statesSelect = ref(null)
const citiesSelect = ref(null)

onMounted(async () => {
  if (selectedCountries.value && selectedCountries.value.length > 0) {
    emit('countryChange', selectedCountries.value)

    await loadStates()
  }
  if (selectedStates.value && selectedStates.value.length > 0) {
    emit('stateChange', selectedStates.value)
    await loadCities()
  }

  window.addEventListener('changed-recently-searches', () => {
    recentlySearchedCountries.value = getLocationRecentSearches('countries', 3)
    recentlySearchedStates.value = getLocationRecentSearches(
      'states',
      3,
      searchStore.searchLocation.states
    )
    recentlySearchedCities.value = getLocationRecentSearches(
      'cities',
      3,
      searchStore.searchLocation.cities
    )
  })
})

const countryOptions = computed(() => {
  const recentSearchedValues = getLocationRecentSearches('countries', 3)
  let formattedRecentSearchedCountries = formatRecentSearches(recentSearchedValues)

  const filteredCountries = countries.filter((item) => !recentSearchedValues.includes(item))

  return formattedRecentSearchedCountries.concat(filteredCountries)
})

const stateOptions = computed(() => {
  if (!selectedCountries.value) {
    return []
  }

  const recentSearchedValues = getLocationRecentSearches('states', 3, dynamicStatesOptions.value)

  let formattedRecentSearchedStates = formatRecentSearches(recentSearchedValues)

  const filteredStates = dynamicStatesOptions.value.filter(
    (item) => !recentSearchedValues.includes(item)
  )

  return formattedRecentSearchedStates.concat(filteredStates)
})

const cityOptions = computed(() => {
  if (!selectedStates.value) {
    return []
  }

  const recentSearchedValues = getLocationRecentSearches('cities', 3, dynamicCitiesOptions.value)
  let formattedRecentSearchedCities = formatRecentSearches(recentSearchedValues)

  const filteredCities = dynamicCitiesOptions.value.filter(
    (item) => !recentSearchedValues.includes(item)
  )

  return formattedRecentSearchedCities.concat(filteredCities)
})

const formatRecentSearches = (data) => {
  return data.map((item) => ({
    label: item,
    value: item,
    isRecentSearched: true
  }))
}

const onCountryChange = async (countries: string[]) => {
  if (countries.length === 0) {
    selectedCountries.value = []
    selectedStates.value = []
    selectedCities.value = []

    selectedStates.value = []
    dynamicStatesOptions.value = []
    selectedCities.value = []
    dynamicCitiesOptions.value = []

    statesSelect.value.multiselectElement.clear()
    emit('countryChange', [])
    emit('stateOptions', [])
    emit('cityOptions', [])

    return
  }

  selectedCountries.value = countries
  emit('countryChange', countries)

  loadStates()
}

const loadStates = async () => {
  try {
    const res = await axios(`${import.meta.env.VITE_LOOKALIKES_API}/api/lookalikes/search/states`, {
      params: {
        countries: JSON.stringify(selectedCountries.value)
      }
    })

    dynamicStatesOptions.value = res.data
    emit('stateOptions', res.data)
  } catch (err) {}
}

const loadCities = async () => {
  try {
    const res = await axios.get(
      `${import.meta.env.VITE_LOOKALIKES_API}/api/lookalikes/search/cities`,
      {
        params: {
          countries: JSON.stringify(selectedCountries.value),
          states: JSON.stringify(selectedStates.value)
        }
      }
    )

    dynamicCitiesOptions.value = res.data
    emit('cityOptions', res.data)
  } catch (err) {}
}

const onStateChange = async (states: string[]): Promise<void> => {
  if (states.length === 0) {
    selectedStates.value = []

    selectedCities.value = []
    dynamicCitiesOptions.value = []

    citiesSelect.value.multiselectElement.clear()

    emit('stateChange', [])
    emit('cityChange', [])
    emit('cityOptions', [])

    return
  }

  selectedStates.value = states
  emit('stateChange', states)

  await loadCities()
}

const onCityChange = (cities: string[]): void => {
  if (cities.length === 0) {
    selectedCities.value = []
    emit('cityChange', [])
    return
  }
  selectedCities.value = cities
  emit('cityChange', cities)
}
</script>
