<template>
  <InviteUserModal />
</template>
<script setup lang="ts">
import InviteUserModal from '@/components/Modals/User/InviteUserModal.vue'
import { onBeforeMount } from '@vue/runtime-core'
import { useRouter } from 'vue-router'

const router = useRouter()

onBeforeMount(() => {
  if (window.innerWidth >= 768) {
    router.push('/')
  }
})
</script>
