<template>
  <div>
    <ul class="breadcrumbs flex gap-1">
      <li v-for="(item, index) in items" :key="index" class="breadcrumb">
        <Link v-if="item.route" variant="clear" :to="item.route" size="sm">{{ item.title }}</Link>
        <span v-else>{{ item.title }}</span>
        <Icon
          v-if="item.route"
          name="svg-chevron-right"
          class="self-center stroke-2 w-4 h-4 text-secondary-300 ms-1"
        />
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import Link from '@/components/Link/Link.vue'
import Icon from '@/components/Icon/Icon.vue'
import { useBreadcrumbsStore } from '@/stores/breadcrumbs'

const breadcrumbsStore = useBreadcrumbsStore()

const items = computed(() => breadcrumbsStore.breadcrumbs)
</script>

<style>
.breadcrumb {
  @apply flex text-sm font-medium text-secondary-300;
}
</style>
