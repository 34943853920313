<template>
  <AuthLayout>
    <template #leftSide>
      <LeftSection />
    </template>
    <template #rightSide>
      <div>
        <ApplicationLogo class="md:mb-2 lg:mb-2 mx-auto lg:hidden" variant="white" />
        <div class="my-8 lg:my-6 text-center lg:text-start">
          <h1 class="text-2xl md:text-3xl leading-8 lg:leading-10 font-bold text-gray-50 mb-2 md:mt-2">
            Forgot Password?
          </h1>
          <p class="text-gray-300">
            Forgot your password? No problem. Just let us know your email address and we will email you a password reset link that will allow you to choose a new one.
          </p>
          <Message
            v-if="resetLinkSent"
            class="mt-4 md:mt-8 lg:mt-4"
            type="dark-success"
            message="We have emailed your password reset link!"
          />
        </div>

        <div class="m-auto w-full">
          <form @submit.prevent="forgotPassword">
            <div class="grid gap-4">
              <BasicInput
                :errors="errorMessage"
                label="Business Email"
                name="email"
                type="email"
                :value="formData.email"
                component-type="floatInput"
                required
                @input-change="(value) => (formData.email = value)"
              />
            </div>

            <div class="mt-6">
              <Button
                type="submit"
                class="w-full text-center h-11"
                :loading="loading"
                :disabled="disabledSubmit"
              >
                <span v-show="!disabledSubmit">Email Password Reset</span>
                <span v-show="disabledSubmit" class="flex justify-center">
                  Resend again (<Countdown
                    :start-time="60"
                    :start-countdown="resetLinkSent"
                    @countdown-finished="countdownDone"
                  />) seconds later
                </span>
              </Button>
            </div>

            <div class="text-center mt-10 lg:mt-6">
              <Link variant="clear" to="/login" link-type="link" class="font-medium text-primary-300">
                Back to Sign In
              </Link>
            </div>
          </form>
        </div>
      </div>
    </template>
  </AuthLayout>
</template>

<script setup lang="ts">
import { ref, onMounted, reactive } from 'vue'
import { useRoute } from 'vue-router'
import { update } from '@intercom/messenger-js-sdk'
import axios from 'axios'
import { captureException } from '@sentry/vue'
import AuthLayout from '@/components/AuthLayout.vue'
import BasicInput from '@/components/Inputs/BasicInput/BasicInput.vue'
import Link from '@/components/Link/Link.vue'
import Message from '@/components/Message/Message.vue'
import Button from '@/components/Buttons/Button.vue'
import ApplicationLogo from '@/components/ApplicationLogo.vue'
import Countdown from '@/components/Countdown.vue'
import LeftSection from '@/views/PasswordPage/Components/LeftSection.vue'

onMounted(() => {
  update({ hide_default_launcher: true })
})

const route = useRoute()

const formData = reactive({
  email: (route.query.email as string) ?? ''
})

const loading = ref(false)
const errorMessage = ref(null)
const resetEmailError = ref(false)
const resetLinkSent = ref(false)
const disabledSubmit = ref(false)

const forgotPassword = async () => {
  if (loading.value) return

  try {
    loading.value = true
    errorMessage.value = null
    resetEmailError.value = false
    resetLinkSent.value = false

    await axios.get(`${import.meta.env.VITE_LOOKALIKES_API}/sanctum/csrf-cookie`)

    await axios.post(`${import.meta.env.VITE_LOOKALIKES_API}/forgot-password`, formData)

    resetLinkSent.value = true
    disabledSubmit.value = true
  } catch (error) {
    captureException(error)

    errorMessage.value =
      error.response.status === 422
        ? error.response.data.errors.email
        : ['Oops,something went wrong. please contact support']
    resetEmailError.value = true
  } finally {
    loading.value = false
  }
}

const countdownDone = () => {
  disabledSubmit.value = false
  resetLinkSent.value = false
}
</script>
