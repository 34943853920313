<template>
  <div class="filter-overlay">
    <div
      class="backdrop"
      :class="isOpen ? 'block lg:hidden' : 'opacity-0 hidden'"
      @click="close"
    ></div>
    <div class="filter" :class="[{ 'is-open filter-shadow': isOpen }, $attrs.class]">
      <div class="filter__header">
        <div class="filter__title">
          <Icon name="svg-filter" class="hidden lg:block w-5 h-5 me-1" />
          Filters
        </div>
        <div class="hidden lg:flex ms-auto">
          <Button variant="primary" @click="applyFilters"> Apply Filters</Button>
        </div>
        <div
          class="max-md:absolute max-md:right-0 self-center ms-auto p-2.5 cursor-pointer lg:hidden"
          @click="close"
        >
          <Icon name="input-close" class="bg-grey-75 text-secondary-300 rounded-full size-6 p-1" />
        </div>
      </div>

      <form @submit.prevent="applyFilters">
        <div class="filter__body">
          <FilterDropdown
            label="Job Title"
            :options="titleOptions"
            :values="titles"
            @select="(val) => (title = val)"
            @clear="(val) => (title = val)"
          />
          <FilterDropdown
            label="Country"
            :options="countriesOptions"
            :values="countries"
            @select="(val) => ((countries = val), (onlyTitleFilterChanged = false))"
            @clear="(val) => ((countries = val), (onlyTitleFilterChanged = false))"
          />
          <FilterDropdown
            label="State"
            :options="statesOptions"
            :values="states"
            @select="(val) => ((states = val), (onlyTitleFilterChanged = false))"
            @clear="(val) => ((states = val), (onlyTitleFilterChanged = false))"
          />
          <FilterDropdown
            label="City"
            :options="citiesOptions"
            :values="cities"
            @select="(val) => ((cities = val), (onlyTitleFilterChanged = false))"
            @clear="(val) => ((cities = val), (onlyTitleFilterChanged = false))"
          />
          <FilterDropdown
            label="Industry"
            :options="industriesOptions"
            :values="industries"
            @select="(val) => ((industries = val), (onlyTitleFilterChanged = false))"
            @clear="(val) => ((industries = val), (onlyTitleFilterChanged = false))"
          />
          <FilterDropdown
            label="Headcount"
            :values="headCount"
            :options="headCountOptions"
            @select="(val) => ((headCount = val), (onlyTitleFilterChanged = false))"
            @clear="(val) => ((headCount = val), (onlyTitleFilterChanged = false))"
          />
          <FilterDropdown
            label="Employer Name"
            :values="employerName"
            :api-url="employerApi"
            :delay="500"
            value-key="company_name"
            label-key="company_name"
            clearable
            param-name="company"
            @select="(val) => ((employerName = val), (onlyTitleFilterChanged = false))"
            @clear="(val) => ((employerName = val), (onlyTitleFilterChanged = false))"
          />
          <FilterNumber
            label="Min Years in Current Role"
            :value="minYearsInCurrentRole > 0 ? minYearsInCurrentRole : undefined"
            @change="(val) => ((minYearsInCurrentRole = val), (onlyTitleFilterChanged = false))"
          />
          <FilterNumber
            label="Min Years in Career"
            :value="minYearsInCareer > 0 ? minYearsInCareer : undefined"
            @change="(val) => ((minYearsInCareer = val), (onlyTitleFilterChanged = false))"
          />
          <!-- <FilterRange label="Total role experience" /> -->
        </div>
        <div class="filter__footer">
          <Button type="button" variant="primary" @click="applyFilters"> Apply Filters</Button>

          <Button
            type="reset"
            variant="outline-secondary"
            class="text-secondary-600"
            @click="resetFilters"
          >
            <div class="flex justify-center">
              <Icon name="svg-trash" class="hidden lg:block w-5 h-5 me-2" />
              Clear Filters
            </div>
          </Button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, computed } from 'vue'
import axios from 'axios'

import Icon from '@/components/Icon/Icon.vue'
import Button from '@/components/Buttons/Button.vue'
import FilterDropdown from '@/components/Filters/FilterDropdown.vue'
import FilterNumber from '@/components/Filters/FilterNumber.vue'

import { useSearchStore } from '@/stores/search'

import { countries as countriesOptions } from '@/data/locations'
import { simplifiedYearConversion } from '@/utils/date'

interface IProps {
  open?: boolean
  table?: any
}

defineOptions({
  inheritAttrs: false
})

const headCountOptions = [
  { label: '1-10', value: 1 },
  { label: '10-50', value: 10 },
  { label: '50-200', value: 50 },
  { label: '200-500', value: 200 },
  { label: '500-1,000', value: 500 },
  { label: '1,000-5,000', value: 1000 },
  { label: '10,000+', value: 10000 }
]

const props = withDefaults(defineProps<IProps>(), {
  open: false
})
const emit = defineEmits(['close', 'apply'])

const searchStore = useSearchStore()

const industriesApi = `${import.meta.env.VITE_LOOKALIKES_API}/api/industries`
const employerApi = `${import.meta.env.VITE_LOOKALIKES_API}/api/company-auto-complete`

const isOpen = ref<boolean>(false)
const statesOptions = ref(searchStore.statesOptions)
const citiesOptions = ref(searchStore.citiesOptions)
const industriesOptions = ref([])
const onlyTitleFilterChanged = ref(true)
const titleOptions = computed(() =>
  searchStore.searchResults?.results
    ? Array.from(props.table.getColumn('title').getFacetedUniqueValues().keys())
        .filter((val) => val !== '' && val !== null)
        .sort()
        .slice(0, 5000)
    : []
)

const countries = ref([])
const title = ref([])

const titleInclude = searchStore.searchPayload?.title_include?.map((title) => ({
  value: title,
  label: title,
  type: 'include'
}))

const titleExclude = searchStore.searchPayload?.title_exclude?.map((title) => ({
  value: title,
  label: title,
  type: 'exclude'
}))

const titles = ref([...(titleInclude || []), ...(titleExclude || [])])

const countriesInclude = searchStore.searchPayload?.countries_include?.map((country) => ({
  value: country,
  label: country,
  type: 'include'
}))

const countriesExclude = searchStore.searchPayload?.countries_exclude?.map((country) => ({
  value: country,
  label: country,
  type: 'exclude'
}))

countries.value = [...(countriesInclude || []), ...(countriesExclude || [])]
// Repeat pattern for states
const statesInclude = searchStore.searchPayload?.states_include?.map((state) => ({
  value: state,
  label: state,
  type: 'include'
}))

const statesExclude = searchStore.searchPayload?.states_exclude?.map((state) => ({
  value: state,
  label: state,
  type: 'exclude'
}))

const states = ref([...(statesInclude || []), ...(statesExclude || [])])

// Repeat pattern for cities
const citiesInclude = searchStore.searchPayload?.cities_include?.map((city) => ({
  value: city,
  label: city,
  type: 'include'
}))

const citiesExclude = searchStore.searchPayload?.cities_exclude?.map((city) => ({
  value: city,
  label: city,
  type: 'exclude'
}))

const cities = ref([...(citiesInclude || []), ...(citiesExclude || [])])

const industriesInclude = searchStore.searchPayload?.industries_include?.map((industry) => ({
  value: industry,
  label: industry,
  type: 'include'
}))

const industriesExclude = searchStore.searchPayload?.industries_exclude?.map((industry) => ({
  value: industry,
  label: industry,
  type: 'exclude'
}))

const industries = ref([...(industriesInclude || []), ...(industriesExclude || [])])

const headCountInclude = searchStore.searchPayload?.company_size_include
  ?.filter((size) => size !== 2) // size 1 and 2 are the same
  .map((size: number) => ({
    value: size,
    label: getLabelByValue(size),
    type: 'include'
  }))

const headCountExclude = searchStore.searchPayload?.company_size_exclude
  ?.filter((size) => size !== 2) // size 1 and 2 are the same
  .map((size: number) => ({
    value: size,
    label: getLabelByValue(size),
    type: 'exclude'
  }))

const headCount = ref([...(headCountInclude || []), ...(headCountExclude || [])])

const employerNameInclude = searchStore.searchPayload?.companies_include?.map((company) => ({
  value: company,
  label: company,
  type: 'include'
}))

const employerNameExclude = searchStore.searchPayload?.companies_exclude?.map((company) => ({
  value: company,
  label: company,
  type: 'exclude'
}))

const employerName = ref([...(employerNameInclude || []), ...(employerNameExclude || [])])

const minYearsInCurrentRole = ref(
  simplifiedYearConversion(searchStore.searchPayload?.total_role_exp)
)
const minYearsInCareer = ref(simplifiedYearConversion(searchStore.searchPayload?.career_exp))

watch(
  countries,
  async (newVal) => {
    if (newVal.length > 0) {
      const res = await axios(
        `${import.meta.env.VITE_LOOKALIKES_API}/api/lookalikes/search/states`,
        {
          params: {
            countries: JSON.stringify(newVal.map((country) => country.label))
          }
        }
      )
      statesOptions.value = res.data
    }
  },
  { immediate: true, deep: true }
)

watch(
  states,
  async (newVal) => {
    if (newVal.length > 0) {
      const res = await axios(
        `${import.meta.env.VITE_LOOKALIKES_API}/api/lookalikes/search/cities`,
        {
          params: {
            countries: JSON.stringify(countries.value.map((country) => country.label)),
            states: JSON.stringify(states.value.map((state) => state.label))
          }
        }
      )
      citiesOptions.value = res.data
    }
  },
  { immediate: true, deep: true }
)

onMounted(async () => {
  const industriesRes = await axios.get(industriesApi)
  industriesOptions.value = industriesRes.data
})

function getLabelByValue(size: number) {
  const option = headCountOptions.find((option) => option.value === size)
  return option ? option.label : null
}

const applyFilters = () => {
  emit('apply', {
    onlyTitleFilterChanged: onlyTitleFilterChanged.value,
    title: title.value,
    title_include: title.value
      .filter((title) => title.type === 'include')
      .map((title) => title.label),
    title_exclude: title.value
      .filter((title) => title.type === 'exclude')
      .map((title) => title.label),
    countries_include: countries.value
      .filter((country) => country.type === 'include')
      .map((country) => country.label),
    countries_exclude: countries.value
      .filter((country) => country.type === 'exclude')
      .map((country) => country.label),
    states_include: states.value
      .filter((state) => state.type === 'include')
      .map((state) => state.label),
    states_exclude: states.value
      .filter((state) => state.type === 'exclude')
      .map((state) => state.label),
    cities_include: cities.value
      .filter((city) => city.type === 'include')
      .map((city) => city.label),
    cities_exclude: cities.value
      .filter((city) => city.type === 'exclude')
      .map((city) => city.label),
    industries_include: industries.value
      .filter((industry) => industry.type === 'include')
      .map((industry) => industry.label),
    industries_exclude: industries.value
      .filter((industry) => industry.type === 'exclude')
      .map((industry) => industry.label),
    company_size_include: headCount.value
      .filter((size) => size.type === 'include')
      .map((size) => size.value),
    company_size_exclude: headCount.value
      .filter((size) => size.type === 'exclude')
      .map((size) => size.value),
    companies_include: employerName.value
      .filter((company) => company.type === 'include')
      .map((company) => company.label),
    companies_exclude: employerName.value
      .filter((company) => company.type === 'exclude')
      .map((company) => company.label),
    total_role_exp: minYearsInCurrentRole.value,
    career_exp: minYearsInCareer.value,
    industries: industriesOptions.value
  })

  onlyTitleFilterChanged.value = true
}

const resetFilters = () => {
  title.value = []
  countries.value = []
  states.value = []
  cities.value = []
  industries.value = []
  headCount.value = []
  employerName.value = []
  minYearsInCurrentRole.value = undefined
  minYearsInCareer.value = undefined
}

const close = () => {
  isOpen.value = false
  emit('close')
}

watch(
  () => props.open,
  (openStatus) => {
    if (openStatus) {
      document.body.classList.add('max-lg:overflow-hidden')
    } else {
      document.body.classList.remove('max-lg:overflow-hidden')
    }
    isOpen.value = openStatus
  }
)
</script>

<style>
@keyframes slideFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideFromBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.filter {
  @apply hidden lg:block w-full px-4 md:px-8 lg:px-4 bg-secondary-50 overscroll-contain lg:animate-none;
  animation: slideFromBottom 0.5s ease-out forwards;

  .filter__header {
    @apply flex py-2.5 justify-center md:justify-between items-center md:border-b border-secondary-100 relative;

    .filter__title {
      @apply flex text-lg leading-6 lg:text-xl text-center md:text-start font-bold lg:font-semibold text-grey-900;
    }
  }

  .filter__body {
    @apply mt-4 lg:mt-6 lg:px-2 grid gap-3;

    .filter__section {
      @apply border-b last:border-b-0 pb-3 last:pb-0;

      .filter__section__name {
        @apply flex justify-between text-sm font-medium;
      }

      .filter__section__label {
        @apply text-secondary-900;
      }

      .filter__section__clear {
        @apply text-secondary-400 underline hover:no-underline ms-auto;
      }
    }
  }

  .filter__footer {
    @apply grid gap-4 mt-10;
  }
}

.filter.is-open {
  @apply z-[2147483600] block md:max-h-[100vh] md:h-[calc(100%-80px)] lg:max-h-none max-md:rounded-t-20 max-lg:fixed bottom-0 mt-auto inset-x-0 md:left-auto py-5 md:content-center max-lg:overflow-y-auto;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .filter {
    animation: slideFromRight 0.5s ease-out forwards;
  }
  .filter-shadow.is-open {
    box-shadow:
      0px 47px 47px 0px rgba(117, 117, 117, 0.09),
      0px 12px 26px 0px rgba(117, 117, 117, 0.1);
  }
}

.backdrop {
  @apply z-[2147483600] transition-opacity ease-linear duration-150 w-screen h-screen fixed inset-0 bg-secondary-950/50 md:bg-transparent;
}
</style>
