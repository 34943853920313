<template>
  <RouterLink
    class="rounded-lg leading-5 ease-out duration-300"
    :active-class="activeClass"
    :exact-active-class="exactActiveClass"
    :class="[
      size ? `text-${size}` : null,
      colors[variant] ?? colors.default,
      types[linkType] ?? types.default
    ]"
    :to="to"
    :replace="to"
    :disabled="disabled"
    @click.stop="$emit('onClick')"
  >
    <slot />
  </RouterLink>
</template>
<script setup lang="ts">
import { defineEmits, defineProps } from 'vue'
import type { linkTypes, linkVariant } from '@/components/Link/link'
import type { Sizes } from '@/components/Inputs/inputs'

interface IProps {
  variant: linkVariant
  linkType?: linkTypes
  disabled?: boolean
  size?: Sizes
  to?: string
  replace?: boolean
  append?: boolean
  tag?: string
  activeClass?: string
  exactActiveClass?: string
  ariaCurrentValue?: 'time' | 'location' | 'page' | 'step' | 'date' | 'true' | 'false'
}

defineEmits(['onClick'])

const colors = {
  default: 'text-secondary-900 bg-transparent',
  clear: '',
  primary: 'text-primary',
  secondary: 'text-secondary',
  success: 'text-success',
  danger: 'text-danger'
}

const types = {
  default: '',
  link: 'underline hover:no-underline'
}

withDefaults(defineProps<IProps>(), {
  variant: 'page',
  ariaCurrentValue: 'page',
  size: 'md',
  replace: false,
  append: false,
  tag: 'a'
})
</script>
