<template>
  <div v-if="tabs.length">
    <div class="flex">
      <TabList
        :tabs="tabs"
        @selected="(index) => (model = index)"
        :active-tab="model"
        :class="tabsClass"
        :row-direction="rowDirection"
        :active-tab-item-class="activeTabItemClass"
        :tab-item-class="tabItemClass"
      >
      </TabList>
      <slot name="actions" />
    </div>
    <div v-show="index == model" v-for="(tab, index) in tabs" :key="tab.slug">
      <slot :name="`tab-${tab.slug}-body`" />
    </div>
  </div>
</template>

<script setup lang="ts">
import TabList from '@/components/Tabs/TabList.vue'
import { defineEmits, defineProps, onMounted } from 'vue'

interface IProps {
  tabs: Record<string, { title: string; slug: string }>
  tabsClass?: string
  rowDirection?: boolean
  activeTabItemClass?: string
  tabItemClass?: string
}

defineEmits(['selected'])
withDefaults(defineProps<IProps>(), {
  tabsClass: 'p-2',
  rowDirection: false
})

const model = defineModel()

onMounted(() => {
  if (model.value === undefined) {
    model.value = 0
  }
})
</script>
