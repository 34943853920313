<template>
  <TipCard
    :class="{ 'max-lg:hidden': !showTips }"
    class="mt-2 md:mt-4 lg:mt-0 lg:absolute lg:top-0 lg:left-0 lg:max-w-xs"
    title="Job Description Search"
    description="Provide a Job Description or Job Ad, and Lookalikes will automatically parse out the necessary information to populate a new candidate search."
  />
  <div class="lg:border lg:rounded-2xl lg:p-6 mt-10">
    <div class="bg-grey-75 rounded-lg p-4">
      <div class="mb-3">
        <p class="text-lg text-grey-900 font-extrabold">
          Job Description Input will be available to all users soon. You will be notified by email.
        </p>
<!--        <div class="flex md:flex-row flex-col md:gap-20 gap-5 mt-5">-->
<!--          <span class="block text-sm md:min-w-[11%]">Job Description:</span>-->
<!--          <div class="text-sm text-grey-600 bg-gray-200 rounded-lg p-4 w-full">-->
<!--            <p>Paste your Job Description or Job Ad here.</p>-->
<!--            <p class="mt-2">-->
<!--              Lookalikes will automatically parse out the necessary information to populate a new-->
<!--              candidate search.-->
<!--            </p>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import TipCard from '@/components/TipCard.vue'
const showTips = ref(true)
</script>
