<template>
  <BaseSearchCard :data="data">
    <template #buttons>
      <!-- <Button variant="success" v-if="data.saved" class="flex-1 lg:flex-none">Saved</Button>
      <Button variant="outline-primary" v-else class="flex-1 lg:flex-none">Save</Button>
      <Button variant="primary" class="flex-1 lg:flex-none">
        View
      </Button> -->
      <Button
        variant="outline-success-secondary"
        v-if="saved"
        class="flex-1 lg:flex-none cursor-default"
        >Saved</Button
      >
      <Button
        variant="outline-primary"
        v-else
        class="flex-1 lg:flex-none"
        :loading="loading"
        @click="saveSearch"
        >Save</Button
      >

      <Button variant="primary" class="search-card__actions__button lg:order-3" @click="viewSearch">
        View
      </Button>
    </template>
  </BaseSearchCard>
</template>
<script setup lang="ts">
import { defineProps, ref } from 'vue'
import { useRouter } from 'vue-router'

import Button from '@/components/Buttons/Button.vue'
import BaseSearchCard from '@/views/Card/Search/BaseSearchCard.vue'
import { useBreadcrumbsStore } from '@/stores/breadcrumbs'
import { useSearchStore } from '@/stores/search'

import axios from 'axios'
interface IProps {
  data: Object
}

const emit = defineEmits(['saveSearchError'])
const props = defineProps<IProps>()

const router = useRouter()
const breadcrumbsStore = useBreadcrumbsStore()
const searchStore = useSearchStore()
const loading = ref(false)
const saved = ref(props.data.saved)

const viewSearch = () => {
  breadcrumbsStore.setBreadcrumbs([{ title: 'Recent Searches', route: { name: 'recentSearches' } }])
  router.push({
    name: 'searchResults',
    params: { searchId: props.data.search_id }
  })
}

const saveSearch = async () => {
  loading.value = true
  try {
    await axios.post(`${import.meta.env.VITE_LOOKALIKES_API}/api/save-search`, {
      search_id: props.data.search_id
    })
    saved.value = true
  } catch (err) {
    if (err.response.status === 400) {
      emit('saveSearchError', new Error('Search already saved'))
    } else {
      emit('saveSearchError', new Error('Failed to save search'))
    }
  } finally {
    loading.value = false
  }
}
</script>
