<template>
  <div v-for="experience in companyExpHistory" class="flex flex-row gap-4 mt-2" :key="experience.title">
    <div class="flex flex-col items-center w-[10px] min-h-full pt-3">
      <Icon name="svg-dot" class="size-2 text-secondary-200"/>
      <div class="bg-secondary-200 w-[2px] h-full"></div>
    </div>
    <div class="flex flex-col items-start justify-start gap-1 h-full">
      <div class="flex flex-row">
        <span class="text-md leading-6 font-semibold text-grey-900">{{ experience.title }}</span>
      </div>
      <div class="flex flex-col md:flex-row md:items-center gap-3">
        <div class="flex bg-grey-75 rounded-lg text-secondary-600 md:p-1 md:px-2 items-center text-sm gap-1">
          <Icon name="svg-portfolio" class="size-4 text-grey-600 mr-1" />
          <span class="text-grey-600 text-sm leading-[19px]">
            {{ experience.industry }}
          </span>
        </div>
        <div class="flex items-center gap-1.5">
          <Icon name="svg-calendar" class="size-4 text-grey-600"/>
          <span class="text-grey-600 text-sm leading-[19px]">{{ formatDate(experience.starts_at) }} - {{ formatDate(experience.ends_at) ?? 'Current' }}</span>
        </div>
        <div class="flex items-center gap-1.5">
          <Icon name="input-time" class="size-4 text-grey-600"/>
          <span class="text-grey-600 text-sm leading-[19px]">Role: {{ convertMonthsToYearsMonths(experience.total_experience_in_position) }}</span>
        </div>
      </div>
      <div v-if="experience.description" class="flex flex-row">
        <span class="text-sm">
          {{ experience.description }}
        </span>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import Icon from '@/components/Icon/Icon.vue'
import { defineProps } from 'vue'
import { convertMonthsToYearsMonths } from '@/utils'

interface IProps {
  companyExpHistory: object,
}

defineProps<IProps>()

const formatDate = (date) => {
  if (!date) {
    return 'Current'
  }

  const fullDate = new Date(date);

  return `${fullDate.toLocaleString('default', { month: 'short' })}. ${fullDate.getFullYear()}`;
}
</script>
