<template>
  <div
    class="flex flex-col items-start p-6 gap-4 bg-white border border-secondary-100 rounded-xl card-shadow"
  >
    <div class="flex flex-col items-start p-0 gap-4 w-full">
      <div class="flex flex-row justify-between items-center p-0 gap-2 w-full">
        <div class="font-bold text-xl leading-5 text-grey-900">
          {{ project.name }}
        </div>
        <div class="p-2 bg-[#EDEEF14D] rounded-[40px]">
          <RouterLink :to="`/project/${project.id}`" target="_blank">
            <Icon name="input-arrow-up-right" class="size-6 text-grey-300 cursor-pointer" />
          </RouterLink>
        </div>
      </div>

      <div class="search-card__results">
        <div class="search-card__results__item">
          <div class="search-card__results__item__wrap">
            <Icon name="svg-shortlist" class="search-card__results__item__icon" />
            <div class="search-card__results__item__count">
              {{ Intl.NumberFormat().format(project.candidates_count) }}
            </div>
          </div>
          <div class="search-card__results__item__title">results</div>
        </div>
        <div class="search-card__results__item">
          <div class="search-card__results__item__wrap">
            <Icon name="user-down" class="search-card__results__item__icon" />
            <div class="search-card__results__item__count">
              {{ Intl.NumberFormat().format(project.shortlisted_count) }}
            </div>
          </div>
          <div class="search-card__results__item__title">shortlisted candidates</div>
        </div>
      </div>
      <div class="flex flex-row p-0 gap-4">
        <Button variant="outline-danger" class="max-w-[107px]" @click="toggleRemoveProjectModal"
          >Remove</Button
        >
        <RouterLink :to="`/project/${project.id}`">
          <Button variant="primary" class="w-[86px]">View</Button>
        </RouterLink>
      </div>
      <div class="flex flex-row p-0 gap-4">
        <span class="text-grey-300 text-sm leading-5 font-medium"
          >Created: {{ project.created_at_formatted }}</span
        >
      </div>
    </div>
    <RemoveModal
      v-if="removeProjectModal"
      title="Remove Project"
      description="This action is irreversible. Are you sure you want to remove this project?"
      @close="closeRemoveProjectModal"
      @remove="$emit('remove', project), closeRemoveProjectModal()"
    />
  </div>
</template>

<script setup lang="ts">
import { defineProps, ref } from 'vue'

import Icon from '@/components/Icon/Icon.vue'
import Button from '@/components/Buttons/Button.vue'
import RemoveModal from '@/components/Modals/RemoveModal.vue'

interface IProps {
  project: object
}
const props = defineProps<IProps>()
console.log(props.project)

const removeProjectModal = ref(false)

const toggleRemoveProjectModal = () => {
  removeProjectModal.value = !removeProjectModal.value
}

const closeRemoveProjectModal = () => {
  removeProjectModal.value = false
}
</script>

<style scoped>
.card-shadow {
  box-shadow:
    0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.search-card__results {
  @apply flex gap-3 mt-3;
  .search-card__results__item {
    @apply flex flex-col gap-2 basis-40;

    .search-card__results__item__wrap {
      @apply flex gap-2 items-center;
    }

    .search-card__results__item__count {
      @apply font-bold text-xl leading-5 text-secondary-900;
    }
    .search-card__results__item__icon {
      @apply size-8 p-2 text-primary-600 bg-primary-50 rounded;
    }

    .search-card__results__item__title {
      @apply text-sm font-medium text-secondary-900;
    }
  }
}
</style>
