<template>
  <div class="filter__section">
    <div class="filter__section__name">
      <label v-if="label" class="filter__section__label" :for="name || `form-input-${$.uid}`">
        {{ label }}
      </label>
      <button class="filter__section__clear" v-show="clearable" @click="clear">Clear</button>
    </div>
    <div class="filter__wrap" :class="{ 'mt-3': !active }">
      <div class="filter__field">
        <div class="filter__inputs">
          <div>
            <BasicInput
              label="From"
              :value="value.from"
              class="flex gap-2 md:gap-6 lg:gap-2 lg:w-1/2"
              label-class="text-secondary-500 text-sm font-medium mb-0"
              input-class="text-secondary-900 text-sm font-medium border-0 border-b border-b-secondary-900 rounded-none hover:rounded-none shadow-none text-center !px-0 pb-1 pt-0"
              @change="change('from', $event)"
            />
          </div>
          <div>
            <BasicInput
              label="To"
              :value="value.to"
              class="flex gap-2 md:gap-6 lg:gap-2 lg:w-1/2"
              label-class="text-secondary-500 text-sm font-medium mb-0"
              input-class="text-secondary-900 text-sm font-medium border-0 border-b border-b-secondary-900 rounded-none hover:rounded-none shadow-none text-center !px-0 pb-1 pt-0"
              @change="change('to', $event)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import BasicInput from '@/components/Inputs/BasicInput/BasicInput.vue'

interface IProps {
  label?: string
  name?: string
  value?: { from: string; to: string }
  clearable?: boolean
}

const props = withDefaults(defineProps<IProps>(), {
  clearable: true
})

const emit = defineEmits(['clear', 'change'])

const from = ref()
const to = ref()

const change = (data: string, event: any) => {
  if (data === 'from') {
    from.value = event.target.value
  } else {
    to.value = event.target.value
  }
  emit('change', { from: from.value, to: to.value })

  console.log({ from: from.value, to: to.value })
}

const clear = () => {
  from.value = ''
  to.value = ''
  emit('clear')
}
</script>

<style scoped>
.filter__section {
  @apply border-b last:border-b-0 pb-3 last:pb-0;

  .filter__section__name {
    @apply flex justify-between text-sm font-medium;
  }

  .filter__section__label {
    @apply text-secondary-900;
  }

  .filter__section__clear {
    @apply text-secondary-400 underline hover:no-underline ms-auto;
  }
}

.filter__wrap {
  @apply flex flex-col gap-2;

  .filter__field {
    .filter__inputs {
      @apply flex gap-2;
    }
  }
}
</style>
