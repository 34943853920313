<template>
  <div
    v-show="message"
    class="font-medium rounded-lg p-2.5 items-center text-center z-50"
    :class="[colors[type] || colors.info, `text-${size}`, { 'flex justify-center': icon }]"
  >
    <Icon v-if="icon && withIcon" :name="icon" class="me-2.5 w-5 h-5" :color="colors[type]" />
    {{ message }}
    <slot />
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps, withDefaults, onMounted } from 'vue'
import Icon from '@/components/Icon/Icon.vue'
import type { MessageTypes, Sizes } from '@/components/Inputs/inputs'

interface IProps {
  type?: MessageTypes
  size?: Sizes
  message?: string
  timeout?: number
  withIcon?: boolean
}

const emit = defineEmits(['timeout'])

const colors = {
  info: 'text-blue-600 bg-blue-50',
  success: 'text-success-600 bg-success-50',
  'dark-success': 'text-success-800 bg-success-300',
  error: 'text-danger-600 bg-danger-50',
  warning: 'text-yellow-600 bg-yellow-50'
}

const props = withDefaults(defineProps<IProps>(), {
  type: 'info',
  size: 'sm',
  withIcon: true
})

const icon = computed(
  () =>
    ({
      info: '',
      success: 'svg-check',
      'dark-success': 'svg-check',
      error: 'input-close',
      warning: ''
    })[props.type]
)

onMounted(() => {
  if (props.timeout) {
    setTimeout(() => {
      emit('timeout')
    }, props.timeout)
  }
})
</script>
