<template>
  <div class="bg-grey-75 rounded-lg relative bg-animated-gradient bg-no-repeat">

  </div>
</template>

<style scoped>
.bg-animated-gradient {
  background: linear-gradient(90deg,  #E0E0E0 0%, #EBEBEB 33%, #F1F1F1 71%, #E0E0E0 100%);
  background-color: #E0E0E0;
  background-size: 40% 100%;
  background-repeat: no-repeat;
  animation: slide-gradient 2s ease infinite;
}

@keyframes slide-gradient {
  0% {
    background-position: -100% 50%;
  }
  100% {
    background-position: 200% 50%;
  }
}
</style>
<script setup lang="ts">
</script>
