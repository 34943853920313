<template>
  <div class="search-card">
    <div class="search-card__header">
      <div class="search-card__type">{{ searchType }}</div>
      <RouterLink
        :to="{
          name: 'searchResults',
          params: { searchId: data.search_id },
          query: { shortlisted: true }
        }"
        target="_blank"
      >
        <Icon name="input-arrow-up-right" class="search-card__external-link" />
      </RouterLink>
    </div>
    <div class="search-card__body">
      <div class="search-card__title">
        <div>{{ data.title }}</div>
        <div class="archetype-linkedin" v-if="data.archetype_name">
          Archetype: {{ data.archetype_name }}
        </div>
      </div>
      <div class="search-card__filters">
        <div class="search-card__filter">
          <div class="search-card__filter__name">Years Exp:</div>
          <div
            v-if="data.role_experience > 0 || data.career_experience > 0"
            class="search-card__filter__value"
          >
            <span v-if="data.role_experience > 0" class="search-card__filter__value__role"
              >{{ convertMonthsToYearsMonths(data.role_experience) }} role,</span
            >

            <span v-if="data.career_experience > 0" class="search-card__filter__value__career"
              >{{ convertMonthsToYearsMonths(data.career_experience) }} career</span
            >
          </div>
          <div v-else class="search-card__filter__value">No Input</div>
        </div>
        <div class="search-card__filter">
          <div class="search-card__filter__name">Location:</div>
          <div v-if="location" class="search-card__filter__value">
            {{ location }}
          </div>
          <div v-else class="search-card__filter__value">No Input</div>
        </div>
        <div class="search-card__filter">
          <div class="search-card__filter__name">Industries:</div>
          <div
            v-if="data.industries && data.industries.length > 0"
            class="search-card__filter__value"
          >
            {{ data.industries.join(', ') }}
          </div>
          <div v-else class="search-card__filter__value">No Input</div>
        </div>
      </div>
      <div class="search-card__results">
        <div class="search-card__results__item">
          <div class="search-card__results__item__wrap">
            <Icon name="svg-shortlist" class="search-card__results__item__icon" />
            <div class="search-card__results__item__count">
              {{ Intl.NumberFormat().format(data.total_results) }}
            </div>
          </div>
          <div class="search-card__results__item__title">results</div>
        </div>
        <div class="search-card__results__item">
          <div class="search-card__results__item__wrap">
            <Icon name="user-down" class="search-card__results__item__icon" />
            <div class="search-card__results__item__count">
              {{ Intl.NumberFormat().format(data.total_shortlisted) }}
            </div>
          </div>
          <div class="search-card__results__item__title">shortlisted candidates</div>
        </div>
      </div>
    </div>
    <div class="search-card__actions">
      <slot name="buttons" />
    </div>
    <div class="search-card__footer">
      <div class="search-card__date">Date searched: {{ data.created_at_formatted }}</div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { defineProps, computed } from 'vue'
import Icon from '@/components/Icon/Icon.vue'
import type { SavedSearch } from '@/views/Searches/types'
import { locationShortenFormat } from '@/utils/location-formatter'
import { convertMonthsToYearsMonths } from '@/utils/date'

interface IProps {
  data: SavedSearch
}

const props = defineProps<IProps>()

const location = computed(() => {
  return props.data.countries?.length
    ? locationShortenFormat(props.data.countries, props.data.states, props.data.cities, true)
    : ''
})

const searchType = computed(() => {
  return props.data.search_type === 'url' ? 'Linkedin Archetype Search' : 'Job Title Search'
})
</script>

<style>
.search-card {
  @apply flex flex-col p-4 md:p-6 border border-secondary-100 rounded-2xl;
  box-shadow:
    0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);

  .search-card__header {
    @apply flex justify-between items-center;
    .search-card__type {
      @apply rounded px-3 py-1 bg-secondary-100 font-medium text-secondary-600 text-base leading-5 capitalize;
    }
    .search-card__external-link {
      @apply size-10 p-2 bg-secondary-100 text-secondary-300 rounded-full;
    }
  }

  .search-card__body {
    .search-card__title {
      @apply text-2xl leading-5 text-secondary-900 font-bold mt-3;
      .archetype-linkedin {
        @apply text-primary font-semibold text-sm mt-2;
      }
    }

    .search-card__filters {
      @apply flex flex-col gap-2 mt-3;
    }

    .search-card__filter {
      @apply flex gap-3;
      .search-card__filter__name {
        @apply text-secondary-400 text-sm font-semibold w-20;
      }
      .search-card__filter__value {
        @apply text-secondary-600 text-base leading-5 font-medium;
      }
    }

    .search-card__results {
      @apply flex gap-3 mt-3;
      .search-card__results__item {
        @apply flex flex-col gap-2 basis-40;

        .search-card__results__item__wrap {
          @apply flex gap-2 items-center;
        }

        .search-card__results__item__count {
          @apply font-bold text-xl leading-5 text-secondary-900;
        }
        .search-card__results__item__icon {
          @apply size-8 p-2 text-primary-600 bg-primary-50 rounded;
        }

        .search-card__results__item__title {
          @apply text-sm font-medium text-secondary-900;
        }
      }
    }
  }

  .search-card__actions {
    @apply mt-4 flex flex-wrap gap-4;
  }

  .search-card__footer {
    @apply mt-auto;
    .search-card__date {
      @apply mt-4 text-xs lg:text-sm leading-5 text-secondary-300 font-medium;
    }
  }
}
</style>
