<template>
  <div v-for="education in educations" :key="education.degree">
    <div v-if="education.school" class="flex flex-row gap-3">
      <div>
        <Icon name="svg-education" class="size-6 text-primary-500" />
      </div>
      <div class="flex flex-col">
        <span v-if="education.school.name" class="text-base text-grey-900 font-semibold">
          {{ education.school.name }}
        </span>
        <span class="text-base text-grey-900">
          {{ education.degree }}
          {{ education.field_of_studyname ? ', ' + education.field_of_study.name : '' }}
        </span>
        <span class="text-base leading-6 text-grey-400" v-if="education.from_date || education.to_date">
          {{ [formatDate(education.from_date), formatDate(education.to_date)].join(' - ') }}
        </span>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { defineProps } from 'vue'
import Icon from '@/components/Icon/Icon.vue'
import type { EducationItem } from '@/components/Candidate/Education/education'

interface IProps {
  educations: EducationItem[]
}

defineProps<IProps>()

const formatDate = (date, defaultValue = '') => {
  if (!date) {
    return defaultValue
  }

  const fullDate = new Date(date)

  return `${fullDate.getFullYear()}`
}
</script>
