<template>
  <div class="features flex flex-col">
    <div class="features__header">
      <div class="features__item features__item--label">Features</div>
      <div class="features__item features__item--included">Included</div>
    </div>
    <div
      class="features__row"
      v-for="(feature, featureIndex) in features"
      :key="featureIndex"
    >
      <div class="features__item features__item--label">{{ feature.label }}</div>
      <div class="features__item features__item--included">
        <Icon v-if="feature.value == 'check'" name="svg-check" class="size-6 text-success-300 mx-auto" />
        <div v-else>{{feature.value}}</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Icon from '@/components/Icon/Icon.vue'

const features = [
  { label: 'View 1.3B+ Full Candidate Resumes', value: 'check' },
  { label: 'Share projects with colleagues', value: 'check' },
  { label: 'Unlimited Searches', value: 'check' },
  { label: 'Build Custom Archetypes', value: 'check' },
  { label: 'AI Ranks by Predicted Interest™ In A New Job', value: 'check' },
  { label: 'Unlimited Candidate Export', value: 'check' },
  { label: 'FREE Regional Compensation Data', value: 'check' },
  { label: 'FREE Regional Hiring Demand Data', value: 'check' },
  { label: 'FREE Candidate Benchmarks', value: 'check' },
  { label: 'Direct ATS/HRIS Integration', value: 'Teams' }
]
</script>

<style scoped>
.features {
  @apply flex divide-y divide-dark-300 text-dark-100 border border-dark-300 rounded-2xl overflow-hidden;

  .features__header,
  .features__row {
    @apply flex text-md;
  }

  .features__item {
    @apply px-3 h-12 content-center;
  }
  .features__item--label {
    @apply ps-5 grow;
  }
  .features__item--included {
    @apply w-24 text-center font-medium;
  }


  .features__header {
    .features__item--label {
      @apply bg-dark-500 font-bold text-22;
    }
    .features__item--included {
      @apply bg-dark-800;
    }
  }

  .features__row {
    .features__item--label {
      @apply bg-dark-800 text-md;
    }
    .features__item--included {
      @apply bg-dark-900;
    }
  }

}
</style>
