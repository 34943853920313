import { captureException } from '@sentry/vue'
import type { SearchPayload, SearchResults } from './types'
import { defineStore } from 'pinia'
import axios from 'axios'

interface IState {
  searchLocation: {
    countries: string[]
    states: string[]
    cities: string[]
  }
  statesOptions: string[]
  citiesOptions: string[]
  searchResults: {}[] | null
  searchId: number | null
  shortlist: string[]
  searchPayload: SearchPayload | null
  searchType: 'url' | 'title' | null
  linkedinProfileData: any
  savedSearch: boolean
}

export const useSearchStore = defineStore('search', {
  state: (): IState => ({
    searchLocation: {
      countries: [],
      states: [],
      cities: []
    },
    statesOptions: [],
    citiesOptions: [],
    searchResults: null,
    searchId: null,
    savedSearch: false,
    shortlist: [],
    searchPayload: null,
    searchType: null,
    linkedinProfileData: null
  }),

  actions: {
    async fetchSearchResults(searchId: number) {
      try {
        const res = await axios.get(
          `${import.meta.env.VITE_LOOKALIKES_API}/api/lookalikes/search/${searchId}`
        )

        if (res.data.search_type === 'url') {
          this.setSearchState(res.data, res.data.searchParams, [], [])
        }
        if (res.data.search_type === 'title') {
          this.setSearchState(res.data, res.data.archetypeData, [], [])
        }
      } catch (err) {
        captureException(err)
        throw err
      }
    },
    setSearchState(
      data: SearchResults[] | null,
      searchPayload: SearchPayload | null,
      statesOptions: string[],
      citiesOptions: string[]
    ) {
      this.searchResults = data
      this.searchId = data?.search_id
      this.savedSearch = data?.saved
      this.statesOptions = statesOptions
      this.citiesOptions = citiesOptions
      this.searchType = data?.search_type
      this.populateShortlist()
      this.setSearchPayload(searchPayload)
      this.setSearchLocation(searchPayload?.countries, searchPayload?.states, searchPayload?.cities)
    },
    setSearchId(id: number | null) {
      this.searchId = id
    },
    populateShortlist() {
      if (this.searchResults) {
        this.shortlist = []
        if (this.searchResults.results && this.searchResults.results.length > 0) {
          this.searchResults.results.forEach((candidate) => {
            if (candidate.shortlisted) {
              this.addCandidateToShortlist(candidate.id)
            }
          })
        }
      }
    },
    addCandidateToShortlist(id: number) {
      this.shortlist.push(id)
    },
    setSearchPayload(payload: SearchPayload) {
      this.searchPayload = payload
    },
    setSearchLocation(countries: string[], states: string[], cities: string[]) {
      this.searchLocation = { countries, states, cities }
    }
  }
})
