<template>
  <BaseModal @close="close">
    <template #header>
      <div class="md:hidden flex mb-4">
        <Link :to="$router.options.history.state.back" class="flex">
          <Icon name="input-arrow-left" class="size-10 p-2 text-secondary-400" />
          <span class="flex items-center text-secondary-400">Go Back</span>
        </Link>
      </div>
      <div class="flex justify-center mb-4">
        <div class="w-full flex items-center justify-center">
          <img class="hidden md:flex" src="../../../assets/invite-modal.png" alt="Invite User" />
          <img
            class="flex md:hidden"
            src="../../../assets/invite-modal-mobile.png"
            alt="Invite User"
          />
        </div>
      </div>
    </template>

    <template #main>
      <Message v-if="message" :type="messageType" :message="message" />

      <h2 class="md:text-center text-xl font-semibold text-secondary-900 mb-2">Invite People</h2>
      <p class="md:text-center text-secondary-900 mb-6">
        Invite your colleagues and friends to use Lookalikes.
      </p>

      <form @submit.prevent="invite" class="mb-2">
        <BasicInput
          label="Email"
          name="email"
          type="email"
          :value="email"
          required
          @input-change="(value) => (email = value)"
        />

        <div class="flex justify-end gap-3 pt-5">
          <Button
            type="button"
            variant="outline-secondary"
            class="hidden md:flex text-center h-11"
            @click="close"
            >Cancel</Button
          >
          <Button type="submit" class="max-sm:w-full text-center h-11" :loading="loading"
            >Send Invite</Button
          >
        </div>
      </form>
    </template>
  </BaseModal>
</template>
<script setup lang="ts">
import BaseModal from '@/components/Modals/BaseModal.vue'
import BasicInput from '@/components/Inputs/BasicInput/BasicInput.vue'
import { defineEmits, ref } from 'vue'
import axios from 'axios'
import { captureException } from '@sentry/vue'
import Button from '@/components/Buttons/Button.vue'
import Message from '@/components/Message/Message.vue'
import Icon from '@/components/Icon/Icon.vue'
import Link from '@/components/Link/Link.vue'

const emit = defineEmits(['close'])

const email = ref(null)
const loading = ref(false)
const message = ref('')
const messageType = ref('')

const invite = async () => {
  loading.value = true
  clear()

  try {
    await axios.post(`${import.meta.env.VITE_LOOKALIKES_API}/api/user/invite`, {
      email: email.value
    })

    messageType.value = 'success'
    message.value = `Invite was sent successfully to ${email.value}.`
    email.value = null
  } catch (err) {
    messageType.value = 'error'
    message.value =
      err.response.status === 422
        ? err.response.data.message
        : 'Oops, something went wrong. please try again.'

    captureException(err)
  } finally {
    loading.value = false
  }
}

const close = () => {
  clear()

  emit('close')
}

const clear = () => {
  message.value = ''
  messageType.value = ''
}
</script>
