<template>
  <div class="relative lg:inline-block" v-click-away="closeDropdown">
    <slot
      name="button"
      :toggle="toggleDropdown"
      :disabled="disabled"
      :active="active"
      :isOpen="isOpen"
    >
      <button
        class="inline-flex cursor-pointer items-center p-3 text-base font-semibold rounded-lg hover:border hover:border-primary hover:bg-secondary-50 active:text-primary active:bg-primary-50 disabled:border-0 disabled:bg-secondary-50 disabled:text-secondary-300 focus:outline-none"
        :class="[
          active || isOpen ? 'text-primary bg-primary-50' : 'text-secondary-900 bg-secondary-50'
        ]"
        :disabled="disabled"
        @click="toggleDropdown"
      >
        {{ label }}
        <Icon :name="arrowIcon" class="w-4 h-4" />
      </button>
    </slot>
    <div
      v-if="isOpen && items?.length"
      :class="`dropdown-body z-40 mt-2 bg-white lg:rounded-md lg:shadow-lg lg:ring-1 ring-black ring-opacity-5 focus:outline-none ${align  === 'end' ? 'right-0' : 'left-0'} ${position}`"
    >
      <slot name="items" :selectItem="selectItem">
        <div class="p-4 flex max-lg:flex-col">
          <div
            v-for="item in items"
            :key="item.label"
            @click="selectItem(item)"
            class="flex p-3 w-full cursor-pointer"
            :class="[items.class]"
          >
            <slot name="item" :item="item">
              <div
                v-if="item.icon"
                class="w-10 h-10 content-center border rounded-full border-secondary-100 me-3"
              >
                <Icon :name="item.icon" class="h-5 w-5 mx-2.5 text-primary m-auto" />
              </div>
              <div>
                <Link v-if="item.route" :to="item.route">{{ item.label }}</Link>
                <span v-else class="font-medium text-secondary-900">{{ item.label }}</span>
                <p v-if="item.description" class="text-sm text-gray-500">{{ item.description }}</p>
              </div>
            </slot>
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import Icon from '@/components/Icon/Icon.vue'
import type { DropdownItem, DropdownProps } from '@/components/Dropdown/dropdown'
import Link from '@/components/Link/Link.vue'

const emit = defineEmits(['select'])
const props = withDefaults(defineProps<DropdownProps>(), {
  align: 'start',
  active: false,
  disabled: false,
  closeOnSelect: true,
  position: 'absolute'
})

const isOpen = ref<boolean>(false)

const arrowIcon = computed(() =>
  isOpen.value || props.active ? 'input-arrow-up' : 'input-arrow-down'
)

const toggleDropdown = (): void => {
  isOpen.value = !isOpen.value
}

const closeDropdown = (e): void => {
  isOpen.value = false
}

const selectItem = (item: DropdownItem): void => {
  emit('select', item)

  if (props.closeOnSelect) {
    closeDropdown()
  }
}
</script>
