<template>
  <div>
    <SearchResultsLoader v-show="loading" />
    <div v-show="!loading" class="md:px-4 lg:px-6">
      <div class="xl:w-50">
        <div class="flex items-center mb-4 md:mb-2">
          <h1 class="text-3xl text-secondary-900 font-bold">New Search</h1>
          <div class="lg:hidden flex text-primary ms-auto">
            <Icon name="svg-bulb" class="w-5 h-5 me-1" />
            <span>Tips</span>
            <Switch name="tips" class="ms-3" :checked="showTips" @change="toggleTips" />
          </div>
        </div>
        <p class="text-md text-secondary-700 mt-4 md:mt-2 hidden lg:block">
          Your new search starts by defining the ideal candidate. There are two ways to do this,
          either by starting with the job title, or by providing a specific person to serve as an
          archetype. When starting with just the job title, you can build an advanced search that
          makes candidate search results highly specific. When providing a specific person, an
          advanced search is built automatically for you.
        </p>
        <p class="text-md text-secondary-700 mt-4 md:mt-2 lg:hidden">
          Begin a new search by either options:
          <ul class="list-decimal ms-4">
            <li>Enter a job title to build a custom advanced search</li>
            <li>Select a specific person as an Archetype to automatically generate search parameters</li>
          </ul>
        </p>
      </div>
      <div class="mt-6 md:mt-10">
        <div class="lg:ms-87">
          <div class="text-lg text-grey-900 font-bold mb-3">Search by:</div>
        </div>
        <div class="lg:relative">
          <Tabs
            :tabs="tabs"
            tabs-class="md:p-2 md:bg-grey-75 rounded-lg"
            class="lg:ms-87"
            v-model="currentTab"
          >
            <template #tab-job-title-body>
              <TitleSearchTab
                :show-tips="showTips"
                @loading="(value) => (loading = value)"
                @error="error = true"
              />
            </template>
            <template #tab-linkedin-body>
              <LinkedURLSearchTab
                :show-tips="showTips"
                @loading="(value) => (loading = value)"
                @error="error = true"
              />
            </template>
            <template #tab-job-description-body>
              <JobDescriptionSearchTab :show-tips="showTips" />
            </template>
            <!-- <template #tab-ats-body> ATS</template> -->
          </Tabs>
        </div>
      </div>
      <Message
        v-if="error"
        class="fixed bottom-0 w-full h-15"
        type="error"
        message="Oops, something went wrong. Please try again or contact our support team"
        :timeout="10000"
        @timeout="error = false"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import SearchResultsLoader from '@/components/Results/SearchResultsLoader.vue'
import TitleSearchTab from '@/views/Search/Tabs/TitleSearchTab.vue'
import LinkedURLSearchTab from '@/views/Search/Tabs/LinkedURLSearchTab.vue'
import Icon from '@/components/Icon/Icon.vue'
import Message from '@/components/Message/Message.vue'

import Switch from '@/components/Inputs/Switch.vue'
import Tabs from '@/components/Tabs/Tabs.vue'
import JobDescriptionSearchTab from '@/views/Search/Tabs/JobDescriptionSearchTab.vue'
const tabs = [
  {
    title: 'Job Title',
    slug: 'job-title'
  },
  {
    title: 'LinkedIn Profile URL',
    slug: 'linkedin'
  },
  {
    title: 'Job Description',
    slug: 'job-description'
  }
  // {
  //   title: 'Fetch Open Job ATS',
  //   slug: 'ats'
  // }
]

const route = useRoute()
const router = useRouter()
const currentTab = ref(0)
const showTips = ref(true)
const loading = ref(false)
const error = ref(false)
const toggleTips = (state) => {
  showTips.value = state
}

// watch(
//   () => currentTab.value,
//   (tabIndex) => {
//     const current = tabs[tabIndex]

//     router.push({
//       query: { tab: current.slug }
//     })
//   }
// )

watch(
  () => route.query.tab,
  (tab) => {
    if (!tab) return

    currentTab.value = tabs.findIndex((t) => t.slug === route.query.tab)
  }
)

onMounted(() => {
  if (route.query.tab) {
    currentTab.value = tabs.findIndex((t) => t.slug === route.query.tab)
  }
})
</script>
