<template>
  <div class="filter__section">
    <div class="filter__section__name">
      <label v-if="label" class="filter__section__label" :for="name || `form-input-${$.uid}`">
        {{ label }}
      </label>
      <button class="filter__section__clear" v-show="clearable" @click="clear">Clear</button>
    </div>
    <div class="filter__wrap" :class="{ 'mt-3': !active }">
      <div class="filter__field">
        <div class="filter__inputs">
          <div class="flex gap-2 md:gap-6 lg:gap-2 lg:w-1/2">
            <label for="from" class="text-secondary-500 text-sm font-medium mb-0">From</label>
            <DatePicker
              :ui="{
                input: 'dp__flat-input'
              }"
              hide-input-icon
              :clearable="false"
              v-model="fromDate"
              @update:modelValue="change"
            />
          </div>
          <div class="flex gap-2 md:gap-6 lg:gap-2 lg:w-1/2">
            <label for="to" class="text-secondary-500 text-sm font-medium mb-0">To</label>
            <DatePicker
              :ui="{
                input: 'dp__flat-input'
              }"
              hide-input-icon
              :clearable="false"
              v-model="toDate"
              @update:modelValue="change"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'

import DatePicker from '@/components/Inputs/Date/DatePicker.vue'

interface IProps {
  label?: string
  name?: string
  value?: object
  clearable?: boolean
}

const emit = defineEmits(['clear', 'change'])
const props = withDefaults(defineProps<IProps>(), {
  clearable: true
})

const fromDate = ref<Date | null>(props.value?.from)
const toDate = ref<Date | null>(props.value?.to)

watch(
  () => props.value,
  (val) => {
    fromDate.value = val?.from
    toDate.value = val?.to
  }
)

const change = () => {
  emit('change', { from: fromDate.value, to: toDate.value })
}

const clear = () => {
  fromDate.value = null
  toDate.value = null
  emit('clear')
}
</script>

<style scoped>
.filter__section {
  @apply border-b last:border-b-0 pb-3 last:pb-0;

  .filter__section__name {
    @apply flex justify-between text-sm font-medium;
  }

  .filter__section__label {
    @apply text-secondary-900;
  }

  .filter__section__clear {
    @apply text-secondary-400 underline hover:no-underline ms-auto;
  }
}

.filter__wrap {
  @apply flex flex-col gap-2;

  .filter__field {
    .filter__inputs {
      @apply flex gap-2;
    }
  }
}
</style>
