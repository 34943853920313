<template>
  <div class="bg-gray-100 h-dvh overflow-hidden">
    <div class="flex w-full h-full overflow-auto lg:divide-x lg:divide-dark-150 bg-dark-900 bg-[-200px] bg-no-repeat bg-cover bg-auth-grid">
      <!-- Left Side -->
      <div class="max-lg:hidden w-full pt-7 px-7 relative overflow-auto !bg-transparent">
          <slot name="leftSide" />
      </div>

      <!-- Right Side -->
      <div class="flex flex-col justify-between w-full mx-auto px-4 pt-10 md:pt-25 md:px-0 lg:p-20 lg:pb-0 xl:max-w-[594px] bg-gradient-218 from-dark-600 from-[-1.26%] to-dark-400 to-[97.79%] overflow-auto">
        <div class="max-lg:max-w-[500px] max-lg:w-full mx-auto mb-4">
          <slot name="rightSide" />
        </div>
        <div class="text-xxs text-secondary-300 text-center lg:text-start my-4">
          <span class="text-primary-300">©</span> {{new Date().getFullYear()}} HR Signal Technology Inc. DBA Lookalikes<span class="text-primary-300">™</span> |
          <a class="text-primary-300 text-nowrap underline hover:no-underline" href="https://www.hirelookalikes.com/privacy-policies-legal/" target="_blank">Privacy, Policies & Legal</a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.bg-auth-grid {
  background-image: url('@/assets/images/auth/white-grid.png');
}
</style>