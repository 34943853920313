<template>
  <div class="w-full">
    <div class="relative lg:h-[162px] flex-1">
      <VueApexCharts :options="chartOptions" :series="series" height="100%" />
    </div>
    <div class="flex flex-row max-sm:flex-col gap-5 mt-4">
      <div class="flex flex-row gap-1.5 items-center">
        <Icon name="svg-dot" class="text-primary-400 size-2"/>
        <span class="text-sm text-grey-900">{{ xAxisTitle }}</span>
      </div>
      <div class="flex flex-row gap-1.5 items-center ">
        <Icon name="svg-dash-line" class="size-4"/>
        <span class="text-sm text-grey-900">{{ xAxisAvgTitle }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, ref } from 'vue'
import VueApexCharts from 'vue3-apexcharts'
import type { VueApexChartsComponent } from 'vue3-apexcharts'
import Icon from '@/components/Icon/Icon.vue'
import type { ApexOptions } from 'apexcharts'

interface IProps {
  series: VueApexChartsComponent['series']
  xAxisCategories: number[] | string[]
  yAnnotationValues?: number[]
  xAnnotationValues?: number | string[]
  yAnnotationTitles?: string[]
  xAnnotationTitles?: string[]
  yAxisTitle?: string,
  xAxisTitle?: string,
  xAxisAvgTitle?: string,
  xAxisFormatter?(value: string, timestamp?: number, opts?:any): string | string[]
  yAxisFormatter?(val: number, opts?: any): string | string[]
  xTooltipFormatter?(val: number, opts?: any): string
  yTooltipFormatter?(val: number, opts?: any): string
  dataValueFormatter(val: number[] | number | string, opts?: never): string
}

const props = defineProps<IProps>()

const chartOptions = ref<ApexOptions>({
  chart: {
    type: 'bar',
    toolbar: {
      tools: {
        download: false
      }
    }
  },
  labels: props.xAxisCategories,
  colors: ['#BFD8FE', '#807AFF'],
  dataLabels: {
    enabled: false,
    formatter: props.dataValueFormatter
  },
  plotOptions: {
    bar: {
      borderRadius: 4,
      columnWidth: '80%',
    }
  },
  xaxis: {
    title: {
      text:props.xAxisTitle ?? ''
    },
    labels: {
      style: {
        colors: '#8995A7',
        cssClass: 'max-sm:text-[10px]'
      },
      formatter: props.xAxisFormatter,
      rotate: 0,
      hideOverlappingLabels: true,
    }
  },
  yaxis: {
    title: {
      text:props.yAxisTitle ?? ''
    },
    decimalsInFloat: 0,
    labels: {
      style: {
        colors: '#8995A7'
      },
      formatter: props.yAxisFormatter
    }
  },
  tooltip: {
    x: {
      formatter: props.xTooltipFormatter
    },
    y: {
      formatter: props.yTooltipFormatter
    }
  },
  annotations: {
    yaxis: [
      props.yAnnotationValues?.[0]
        ? {
          label: {
            offsetX: 20,
            position: 'left',
            text: props.yAnnotationTitles?.[0] ?? 'AVG',
            borderColor: 'transparent',
            style: {
              color: '#fff',
              background: '#556074',
              padding: {
                bottom: 4
              }
            }
          },
          y: props.yAnnotationValues[0],
          strokeDashArray: 10,
          borderWidth: 2,
          borderColor: 'black'
        }
        : {},
      props.yAnnotationValues?.[1]
        ? {
          label: {
            offsetX: 20,
            position: 'left',
            text: props.yAnnotationTitles?.[1] ?? 'AVG',
            style: {
              color: '#fff',
              background: 'orange',
              padding: {
                bottom: 4
              }
            }
          },
          y: props.yAnnotationValues[1],
          strokeDashArray: 10,
          borderWidth: 2,
          borderColor: 'orange'
        }
        : {}
    ],
    xaxis: props.xAnnotationValues
      ? [
        props.xAnnotationValues?.[0]
          ? {
            label: {
              text: props.xAnnotationTitles?.[0] ?? 'AVG',
              position: 'top',
              offsetX: 25,
              offsetY: 20,
              orientation: 'horizontal',
              borderRadius: 4,
              style: {
                color: '#fff',
                background: '#556074',
                padding: {
                  top:4,
                  right: 8,
                  bottom: 4,
                  left: 8
                },
                fontSize: '12px',
                fontFamily: 'Nunito Sans'
              }
            },
            x: props.xAnnotationValues[0].toString(),
            strokeDashArray: 5,
            borderColor: '#556074',
            borderWidth: 1,
          }
          : {},
        props.xAnnotationValues?.[1]
          ? {
            label: {
              text: props.xAnnotationTitles?.[1] ?? 'AVG',
              style: {
                color: '#fff',
                background: 'orange'
              }
            },
            x: props.xAnnotationValues[1].toString(),
            strokeDashArray: 10,
            borderColor: 'orange',
            borderWidth: 2
          }
          : { borderColor: 'transparent' }
      ]
      : []
  }
})
</script>

<style scoped>
.apexcharts-bar-area {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}
</style>
