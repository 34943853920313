<template>
  <div class="flex flex-row gap-2 items-center">
    <input
      type="radio"
      :id="id"
      :name="name"
      :value="value"
      :checked="checked"
      v-model="localValue"
      class="w-6 h-6 text-primary-700 bg-grey-50 border-grey-300 focus:ring-transparent"
      @change="$emit('radioChange', $event.target.value)"
    />
    <label v-if="label" :for="id" class="flex gap-1 text-grey-900 text-md leading-6">
      {{ label }}

      <Icon v-if="iconLabelName" :name="iconLabelName" class="size-4" />
    </label>
  </div>
</template>

<script setup lang="ts">
import { defineProps, ref, watch } from 'vue'
import Icon from '@/components/Icon/Icon.vue'

interface IProps {
  id: string
  value: string
  name: string
  modelValue: string | null
  label?: string
  iconLabelName?: string
  checked?: boolean
}

const props = defineProps<IProps>()

const localValue = ref(props.modelValue)

watch(
  () => props.modelValue,
  (newVal) => {
    localValue.value = newVal
  }
)
</script>
