<template>
  <SortByButton>
    <template #content>
      <div
        class="sort-options-shadow absolute flex flex-col p-0 rounded-lg top-15 w-87 right-0 md:left-0 border bg-secondary-50 z-20"
      >
        <div class="flex flex-row px-6 py-2 gap-[10px] bg-[#F9F9F9]">
          <span class="text-secondary-600 text-lg leading-8">Sort by</span>
        </div>
        <div class="sort__wrap">
          <div class="sort__items">
            <div class="sort__item">
              <div class="flex flex-row justify-between">
                <span class="text-grey-900 text-md leading-6 font-bold">Rank</span>
              </div>
              <div class="sort__item__options">
                <RadioButton
                  id="rank_asc"
                  name="table-sort"
                  label="Sort ascending"
                  icon-label-name="svg-full-arrow-up"
                  :checked="columnId === 'ranking_order' && sortOrder === false"
                  @radioChange="(value) => ((columnId = 'ranking_order'), (sortOrder = false))"
                />
                <RadioButton
                  id="rank_desc"
                  name="table-sort"
                  label="Sort descending"
                  icon-label-name="svg-full-arrow-down"
                  @radioChange="(value) => ((columnId = 'ranking_order'), (sortOrder = true))"
                  :checked="columnId === 'ranking_order' && sortOrder === true"
                />
              </div>
            </div>
            <div class="sort__item">
              <div class="flex flex-row justify-between">
                <span class="text-grey-900 text-md leading-6 font-bold">Employer</span>
              </div>
              <div class="sort__item__options">
                <RadioButton
                  id="employer_asc"
                  name="table-sort"
                  label="Sort ascending"
                  icon-label-name="svg-full-arrow-up"
                  :checked="columnId === 'company_name' && sortOrder === false"
                  @radioChange="(value) => ((columnId = 'company_name'), (sortOrder = false))"
                />
                <RadioButton
                  id="employer_desc"
                  name="table-sort"
                  label="Sort descending"
                  icon-label-name="svg-full-arrow-down"
                  :checked="columnId === 'company_name' && sortOrder === true"
                  @radioChange="(value) => ((columnId = 'company_name'), (sortOrder = true))"
                />
              </div>
            </div>
            <div class="sort__item">
              <div class="flex flex-row justify-between">
                <span class="text-grey-900 text-md leading-6 font-bold">Role Experience</span>
              </div>
              <div class="sort__item__options">
                <RadioButton
                  id="roleExp_asc"
                  name="table-sort"
                  label="Sort ascending"
                  icon-label-name="svg-full-arrow-up"
                  :checked="columnId === 'total_experience_in_position' && sortOrder === false"
                  @radioChange="
                    (value) => ((columnId = 'total_experience_in_position'), (sortOrder = false))
                  "
                />
                <RadioButton
                  id="roleExp_desc"
                  name="table-sort"
                  label="Sort descending"
                  icon-label-name="svg-full-arrow-down"
                  :checked="columnId === 'total_experience_in_position' && sortOrder === true"
                  @radioChange="
                    (value) => ((columnId = 'total_experience_in_position'), (sortOrder = true))
                  "
                />
              </div>
            </div>
            <div class="sort__item">
              <div class="flex flex-row justify-between">
                <span class="text-grey-900 text-md leading-6 font-bold">Career Experience</span>
              </div>
              <div class="sort__item__options">
                <RadioButton
                  id="careerExp_asc"
                  name="table-sort"
                  label="Sort ascending"
                  icon-label-name="svg-full-arrow-up"
                  @radioChange="
                    (value) => ((columnId = 'total_career_experience'), (sortOrder = false))
                  "
                  :checked="columnId === 'total_career_experience' && sortOrder === false"
                />
                <RadioButton
                  id="careerExp_desc"
                  name="table-sort"
                  label="Sort descending"
                  icon-label-name="svg-full-arrow-down"
                  :checked="columnId === 'total_career_experience' && sortOrder === true"
                  @radioChange="
                    (value) => ((columnId = 'total_career_experience'), (sortOrder = true))
                  "
                />
              </div>
            </div>
          </div>

          <div class="sort__actions">
            <div class="flex flex-row gap-4 p-0 justify-end">
              <Button variant="outline-secondary" class="w-fit" @click="resetSort">Reset</Button>
              <Button variant="outline-primary" class="w-fit" @click="sortTable">Apply Now</Button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </SortByButton>
</template>
<script setup lang="ts">
import SortByButton from '@/components/Sorting/SortByButton.vue'
import RadioButton from '@/components/Buttons/RadioButton.vue'
import Button from '@/components/Buttons/Button.vue'
import { ref } from 'vue'
import type { Table } from '@tanstack/table-core'

interface IProps {
  table: Table<any>
}

const emit = defineEmits(['sort'])
const props = defineProps<IProps>()

const columnId = ref(props.columnId)
const sortOrder = ref(props.sortOrder)

const sortTable = () => {
  props.table.setSorting([{ id: columnId.value, desc: sortOrder.value }])
  emit('sort')
}

const resetSort = () => {
  columnId.value = ''
  sortOrder.value = null
  props.table.resetSorting()
}
</script>

<style>
.sort-options-shadow {
  box-shadow:
    0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.sort__wrap {
  @apply px-6 py-4;
}

.sort__items {
  @apply flex flex-col p-0 divide-y divide-secondary-100;
}

.sort__item {
  @apply py-4 first:pt-0 last:pb-0;

  .sort__item__options {
    @apply mt-2 flex flex-col items-start p-0 gap-4;
  }
}

.sort__actions {
  @apply mt-6;
}
</style>
