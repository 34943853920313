<template>
  <div>
    <label
      v-if="componentType === 'input' && label"
      :for="name || `form-input-${$.uid}`"
      :class="[
        { 'text-secondary-300': disabled || readonly, 'text-secondary-600': !disabled },
        labelClass
      ]"
      class="mb-2 text-sm font-medium inline-block"
    >
      {{ label }}
    </label>

    <div class="relative">
      <input
        ref="inputRef"
        class="block py-2 w-full text-sm leading-6 text-secondary-900 rounded-lg border bg-secondary-50 hover:bg-secondary-100 appearance-none active:text-secondary-900 focus:text-secondary-900 focus:outline-none focus:ring-0 focus:border-secondary-500 peer focus:bg-secondary-50 disabled:text-secondary-200 read-only:text-secondary-200"
        :class="[
          clearable ? 'ps-3 pe-6' : 'px-3',
          errors?.length ? 'border-danger-600' : 'border-secondary-200',
          inputClass
        ]"
        :type="type"
        :id="name || `form-input-${$.uid}`"
        :form="form"
        :name="name"
        :value="value"
        :required="required"
        :disabled="disabled"
        :readonly="readonly"
        :min="min"
        :max="max"
        :placeholder="placeholder"
        :minlength="minLength"
        :maxLength="maxLength"
        @input="$emit('inputChange', $event.target.value)"
      />
      <label
        v-if="componentType === 'floatInput'"
        :for="name ?? `form-input-${$.uid}`"
        :class="{ 'text-danger-600': errors?.length, 'text-secondary-500': !errors?.length }"
        class="absolute top-2 left-1 px-2 text-sm bg-secondary-50 duration-300 transition-transform origin-[0] -translate-y-4 scale-75 select-none peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-hover:peer-placeholder-shown:bg-secondary-100 peer-focus:peer-hover:bg-secondary-50 peer-focus:bg-secondary-50 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-secondary-600 peer-disabled:text-secondary-200"
      >
        {{ label }}
      </label>
      <slot />
    </div>

    <InputError v-if="touched && required" :messages="['This field is required']" />
    <InputError v-if="errors" :messages="errors" />
  </div>
</template>

<script setup lang="ts">
import { defineEmits, defineProps, defineExpose, ref } from 'vue'
import InputError from '@/components/Inputs/InputError.vue'

interface IProps {
  type?: string
  label?: string
  labelClass?: string
  inputClass?: string
  touched?: boolean
  value?: string | number
  required?: boolean
  disabled?: boolean
  readonly?: boolean
  placeholder?: string
  name?: string | number
  form?: string
  isValid?: boolean
  errors?: string[]
  minLength?: string
  maxLength?: string
  min?: number
  max?: number
  clearable: boolean
  componentType?: 'input' | 'floatInput'
}

withDefaults(defineProps<IProps>(), {
  type: 'text',
  placeholder: '',
  componentType: 'input',
  clearable: false
})

const inputRef = ref(null)
const emit = defineEmits(['inputChange', 'cleared'])

const clearValue = () => {
  inputRef.value.value = ''
  emit('cleared')
}

defineExpose({
  clearValue
})
</script>
