import { defineStore } from 'pinia'
import axios from 'axios'

interface ShortlistState {
  candidates: any[]
  projectName: string
  projectCreatedAt: string
  shortlistedCount: number
}

export const useProjectStore = defineStore('project', {
  state: (): ShortlistState => ({
    candidates: [],
    projectName: '',
    projectCreatedAt: '',
    shortlistedCount: 0
  }),
  getters: {
    getCandidates: (state) => state.candidates
  },
  actions: {
    async fetchCandidates(projectId: string) {
      const candidatesResponse = await axios.get(
        `${import.meta.env.VITE_LOOKALIKES_API}/api/projects/${projectId}/candidates`
      )
      this.candidates = candidatesResponse.data.candidates
      this.projectName = candidatesResponse.data.project_name
      this.projectCreatedAt = candidatesResponse.data.created_at
      this.shortlistedCount = candidatesResponse.data.shortlisted_count
    },
    async removeCandidate(candidateId: string) {
      await axios.delete(
        `${import.meta.env.VITE_LOOKALIKES_API}/api/projects/candidate/${candidateId}`
      )
      this.candidates = this.candidates.filter(
        (candidate) => candidate.project_candidate_id !== candidateId
      )
    },
    shortlist(candidateId: string) {
      const candidate = this.candidates.find(
        (candidate) => candidate.project_candidate_id === candidateId
      )
      if (candidate) {
        candidate.shortlisted = true
      }
    },
    unshortlist(candidateId: string) {
      const candidate = this.candidates.find(
        (candidate) => candidate.project_candidate_id === candidateId
      )
      if (candidate) {
        candidate.shortlisted = false
      }
    },
    setCandidates(candidates: any[]) {
      this.candidates = candidates
    }
  }
})
