<template>
  <div v-if="isRecentlyViewed" class="relative">
    <div @mouseover="displayToolTip = true" @mouseleave="displayToolTip = false">
      <p
        v-if="displayToolTip"
        class="absolute z-10 text-secondary-500 bg-grey-75 rounded-md w-max text-xs p-1 top-[-25px]"
        :class="tooltipPosition === 'right' ? 'left-9': 'right-9'"
      >
        Last Viewed: {{ getRecentViewedDate(candidateId) }}
      </p>
      <IconButton icon="input-eye" variant="secondary" icon-class="text-secondary-500 size-6  stroke-1" class="rounded-lg !bg-grey-75 p-2"></IconButton>
    </div>
  </div>
</template>
<script setup lang="ts">
import IconButton from '@/components/Buttons/IconButton.vue'
import { defineProps, ref } from 'vue'
import { getRecentViewedDate, isRecentViewed } from '@/utils/recent-viewed-candidates'
import { onBeforeMount } from '@vue/runtime-core'

interface IProps {
  candidateId: string,
  displayAtFirstTime?: boolean,
  tooltipPosition?: 'left' | 'right'
}

const props = withDefaults(defineProps<IProps>(), {
  displayAtFirstTime: false,
  tooltipPosition: 'right',
})

const isRecentlyViewed = ref(isRecentViewed(props.candidateId, props.displayAtFirstTime))

onBeforeMount(() => {
  window.addEventListener('message', function(event) {
    isRecentlyViewed.value = isRecentViewed(props.candidateId, props.displayAtFirstTime)
  });
})

const displayToolTip = ref(false)


</script>
