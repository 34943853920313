<template>
  <SectionBlock title="Candidate Employer(s)">
    <div class="grid gap-3 md:gap-4">
      <SelectInput
        class="flex md:flex-row w-full gap-6"
        label="Employer(s) Headcount:"
        label-class="w-full md:max-w-37.5 text-secondary-900 !font-medium"
        placeholder="Click here"
        :searchable="false"
        save-values-as-objects
        :options="headcountOptions"
        :selected-value="localSelectedHeadcount"
        @change="(options) => $emit('headcountChange', options)"
      />
      <SelectInput
        class="flex md:flex-row w-full lg:maw-w-[820px] gap-6"
        label="Employer(s) Name:"
        label-class="w-full md:max-w-37.5 text-secondary-900 !font-medium"
        :api-url="employerApi"
        :delay="500"
        :hide-option-if-search-empty="true"
        value-key="company_name"
        label-key="company_name"
        clearable
        param-name="company"
        placeholder="Type and select"
        :selected-value="localSelectedEmployers"
        :clear-on-blur="true"
        :save-values-as-objects="true"
        @change="(option) => $emit('employerChange', option)"
      />
      <!-- <SelectInput
        class="flex md:flex-row w-full lg:maw-w-[820px] gap-6"
        label="Employer(s) Name:"
        label-class="w-full md:max-w-37.5 text-secondary-900 !font-medium"
        :api-url="employerApi"
        delay="500"
        :hide-option-if-search-empty="true"
        value-key="company_name"
        label-key="company_name"
        clearable
        param-name="company"
        placeholder="Type and select"
        :selected-value="selectedEmployers"
        :clear-on-blur="true"
        @change="(option) => $emit('employerChange', option)"
      /> -->

      <SelectInput
        class="flex md:flex-row w-full lg:maw-w-[820px] gap-6"
        label="Industry:"
        label-class="w-full md:max-w-37.5 text-secondary-900 !font-medium"
        :options="industries"
        :selected-value="selectedIndustries"
        clearable
        placeholder="Type and select"
        :clear-on-blur="true"
        @change="(option) => $emit('industryChange', option)"
      >
        <template #suggestions>
          <div class="flex suggestions">
            <span v-for="industry in industries" :key="industry.value" class="suggestion">
              {{ industry.name }}
            </span>
          </div>
        </template>
      </SelectInput>
    </div>
  </SectionBlock>
</template>

<script setup lang="ts">
import { defineEmits, ref, onMounted, computed } from 'vue'
import SectionBlock from '@/components/Tabs/SectionBlock.vue'
import SelectInput from '@/components/Inputs/Select/SelectInput.vue'

import axios from 'axios'

interface IProps {
  selectedIndustries?: string[]
  selectedEmployers?: string[]
  selectedHeadcount?: number[]
}

const props = defineProps<IProps>()

const emit = defineEmits(['headcountChange', 'employerChange', 'industryChange'])

const headcountOptions = [
  { label: '1-10', value: 1 },
  { label: '10-50', value: 10 },
  { label: '50-200', value: 50 },
  { label: '200-500', value: 200 },
  { label: '500-1,000', value: 500 },
  { label: '1,000-5,000', value: 1000 },
  { label: '10,000+', value: 10000 }
]
const employerApi = `${import.meta.env.VITE_LOOKALIKES_API}/api/company-auto-complete`
const industriesApi = `${import.meta.env.VITE_LOOKALIKES_API}/api/industries`

const industries = ref([])
const localSelectedHeadcount = computed(() =>
  props.selectedHeadcount
    ? props.selectedHeadcount
        .filter((headcount) => headcount !== 2) // size 1 and 2 are the same
        .map((headcount) => headcountOptions.find((option) => option.value === headcount))
    : null
)

const localSelectedEmployers = computed(() => {
  return props.selectedEmployers?.map((employer) => ({ label: employer, value: employer }))
})

onMounted(async () => {
  if (localSelectedHeadcount.value) {
    emit('headcountChange', localSelectedHeadcount.value)
  }
  if (localSelectedEmployers.value) {
    emit('employerChange', localSelectedEmployers.value)
  }
  if (props.selectedIndustries) {
    emit('industryChange', props.selectedIndustries)
  }

  const industriesRes = await axios.get(industriesApi)
  industries.value = industriesRes.data
})
</script>

<style scoped>
.suggestions {
  @apply text-secondary-400 gap-2;
}

.suggestion {
  @apply underline hover:no-underline;
}

.suggestion:not(:last-child):after {
  content: ',';
}
</style>
