<template>
  <div class="rounded-lg p-5 w-90 gap-3 text-white flex flex-col" :class="bgColor">
    <span class="text-3xl font-semibold"> {{ title }}</span>
    <span class="text-lg font-semibold"> {{ subtitle }}</span>
    <div class="flex gap-2">
      <span class="text-5xl font-bold"> ${{ price }} </span>
      <span class="self-center text-gray-300 font-medium text-xl"> /{{ pricePeriod }}</span>
    </div>
    <slot />
    <button
      v-if="!actionButtonInActiveState && !contactSalesButton"
      class="bg-transparent mt-3 border border-white text-white px-6 py-3 rounded-lg font-semibold hover:bg-white hover:text-primary-500 transition-all duration-200"
      @click="emit('upgradeNow')"
    >
      {{ buttonText ?? 'Upgrade Now' }}
    </button>
    <button
      v-if="actionButtonInActiveState && !contactSalesButton"
      class="hover:bg-transparent mt-3 border border-white hover:text-white px-6 py-3 rounded-lg font-semibold bg-white text-primary-500 transition-all duration-200"
      @click="emit('upgradeNow')"
    >
      {{ buttonText ?? 'Upgrade Now' }}
    </button>
    <a href="mailto:sales@hrsignal.com" class="w-full">
      <button
        v-if="contactSalesButton"
        class="w-full bg-transparent mt-3 border border-white text-white px-6 py-3 rounded-lg font-semibold hover:bg-white hover:text-primary-500 transition-all duration-200"
        @click="emit('upgradeNow')"
      >
        Contact Sales
      </button>
    </a>
    <p :class="bottomTextColorClass ?? 'text-gray-300'" class="text-sm text-center">
      Instant Access. No Credit Card Needed.
    </p>
  </div>
</template>

<script setup lang="ts">
interface IProps {
  title: string
  subtitle: string
  price: number
  pricePeriod: string
  bgColor: string
  actionButtonInActiveState?: boolean
  bottomTextColorClass?: string
  buttonText?: string
  contactSalesButton?: boolean
}

defineProps<IProps>()

const emit = defineEmits(['upgradeNow'])
</script>
