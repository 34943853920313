<template>
  <div class="flex flex-col gap-8 rounded-lg border border-grey-100">
    <ChartCard
      title="Position Demand Trend Over Time"
      description="Position Hiring Demand is charted over time to reveal seasonality or trends."
      :avg-years-and-months="`${averagePositionDemand}%`"
      :comparison-label="percentageValueTitle"
      :no-data="Object.values(props.positionDemandHistory).length === 0"
    >
      <template #chart>
        <AreaChart
          :series="series"
          :categories="categories"
          :data-value-formatter="formatter"
        />
      </template>
    </ChartCard>
  </div>
</template>
<script setup lang="ts">
import AreaChart from '@/components/Chart/AreaChart.vue'
import ChartCard from '@/components/Card/ChartCard.vue'
import { computed, defineProps } from 'vue'

interface IProps {
  positionDemandHistory: object[]
  averagePositionDemand?: string
  positionChangeLabel?: string
  monthsAgo?: string,
  positionDemandPercentageChange?: string
}

const props = defineProps<IProps>()

const series = computed(() => [
  {
    name: 'Score',
    data: props.positionDemandHistory.map((data) => data.position_demand)
  }
])

const categories = computed(() => {
  return props.positionDemandHistory.map((data) => {
    const date = new Date(data.timestamp)

    return `${date.toLocaleString('default', { month: 'short' })}`
  })
})

const percentageValueTitle = computed(() => {
  let label = `${Math.abs(props.positionDemandPercentageChange)}%`

  switch (props.positionChangeLabel) {
    case 'up':
      return `<span class="text-success-500">${label} higher</span> <span class="text-secondary-500">compared to ${props.monthsAgo} months ago</span>`
    case 'down':
      return `<span class="text-danger-500">${label} lower</span> <span class="text-secondary-500">compared to ${props.monthsAgo} months ago</span>`
    case 'same':
      return `<span class="text-secondary-500">same as compared to ${props.monthsAgo} months ago</span>`
    default:
      return '<span class="text-secondary-500">Data for this position/location did not load.</span>'
  }
})

const formatter = (val: number): string => `${val}%`
</script>
