<template>
  <div>
    <form @submit.prevent="updatePassword" class="grid gap-4 md:gap-6 lg:w-1/2">
      <Message :type="updateStatus" :message="updateMessage"/>
      <ProfileCard>
        <div class="grid gap-4">
          <BasicInput
            :errors="formErrors?.fullName"
            :value="form.fullName"
            label="Full Name"
            name="fullName"
            min-length="2"
            clearable
            required
          />
          <BasicInput
            type="email"
            input-class="hover:bg-transparent"
            :errors="formErrors?.email"
            :value="form.email"
            label="Email"
            name="email"
            :disabled="true"
            required
            @input-change="(value) => (form.email = value)"
          />
        </div>
        <div class="md:text-end mt-6">
          <Button
            type="submit"
            variant="primary"
            class="w-full md:w-auto h-11"
            :loading="loading"
          >
            Save
          </Button>
        </div>
      </ProfileCard>
    </form>
  </div>
</template>

<script setup lang="ts">
import axios from 'axios'
import { captureException } from '@sentry/vue'
import { reactive, ref } from 'vue'
import Message from '@/components/Message/Message.vue'
import Button from '@/components/Buttons/Button.vue'
import ProfileCard from '@/views/User/Components/ProfileCard.vue'
import BasicInput from '@/components/Inputs/BasicInput/BasicInput.vue'
import { useUserStore } from '@/stores/user'

const store = useUserStore()

const initialState = {
  fullName: store.getUserName,
  email: store.user?.email,
};

const form = reactive(initialState)
const formErrors = ref(null);
const loading = ref(false);
const updateStatus = ref('error');
const updateMessage = ref(null);

const updatePassword = async () => {
  if(loading.value)
    return
  
  formErrors.value = null
  loading.value = true

  try {
    await axios.put(`${import.meta.env.VITE_LOOKALIKES_API}/api/profile/update`, form)

    updateStatus.value = 'success'
    updateMessage.value = 'Account information was successfully updated!'

    Object.assign(form, initialState);
  } catch (error) {
    if (error.response?.status === 422) {
      formErrors.value = error.response.data.errors
    } else {
      captureException(error)
      updateStatus.value = 'error'
      updateMessage.value = error.response?.message ?? 'Oops something went wrong. please contact our support team'
    }
  } finally {
    loading.value = false
  }
}
</script>
