<template>
  <div>
    {{ countdown }}
  </div>
</template>

<script setup lang="ts">
import { ref, onBeforeUnmount, watch, defineProps, defineEmits, onMounted } from 'vue'

interface IProps {
  startTime: number
  startCountdown: boolean
}

const props = withDefaults(defineProps<IProps>(), {
  startTime: 10,
  startCountdown: false
})

const emit = defineEmits(['countdown-finished'])

const countdown = ref<number>(props.startTime)
const intervalId = ref<number | null>(null)

const startTimer = () => {
  clearInterval(intervalId.value as number)
  countdown.value = props.startTime

  intervalId.value = setInterval(() => {
    if (countdown.value > 0) {
      countdown.value -= 1
    } else {
      clearInterval(intervalId.value as number)
      emit('countdown-finished')
    }
  }, 1000) as unknown as number
}

watch(
  () => props.startCountdown,
  (newVal) => {
    if (newVal) startTimer()
  }
)

onMounted(() => {
  if (props.startCountdown) {
    startTimer()
  }
})

onBeforeUnmount(() => {
  clearInterval(intervalId.value as number)
})
</script>
