<template>
  <Button
    :variant="!savedSearch ? 'outline-secondary' : 'outline-success-secondary'"
    class="flex items-center"
    :disabled="savedSearch"
    :loading="loading"
    @click="saveSearch"
  >
    <Icon v-if="!savedSearch" name="svg-save" class="size-5 me-2" />
    <Icon v-else name="svg-green-check-mark" class="size-5 me-2" />
    {{ savedSearch ? 'Saved' : 'Save Search' }}
  </Button>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import axios from 'axios'

import Button from '@/components/Buttons/Button.vue'
import Icon from '@/components/Icon/Icon.vue'

import { useSearchStore } from '@/stores/search'

const emit = defineEmits(['saveSearchError'])

const searchStore = useSearchStore()

const savedSearch = computed(() => searchStore.savedSearch)
const loading = ref(false)

const saveSearch = async () => {
  loading.value = true
  try {
    await axios.post(`${import.meta.env.VITE_LOOKALIKES_API}/api/save-search`, {
      search_id: searchStore.searchId
    })

    searchStore.savedSearch = true
  } catch (err) {
    if (err.response.status === 400) {
      emit('saveSearchError', new Error('Search already saved'))
    } else {
      emit('saveSearchError', new Error('Failed to save search'))
    }
  } finally {
    loading.value = false
  }
}
</script>
