<template>
  <Button
    :variant="shortlisted || shortlistedState ? 'success' : 'primary'"
    :class="{
      'bg-primary-100 !text-primary-700 hover:!text-white': !shortlisted && !shortlistedState,
      '!bg-success-50 border border-success-500 !text-success-500': shortlisted || shortlistedState
    }"
    :disabled="shortlisted || shortlistedState"
    :loading="loading"
    @onClick="shortlist"
  >
    {{ shortlisted || shortlistedState ? 'Shortlisted' : 'Shortlist' }}
  </Button>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import axios from 'axios'

import Button from '@/components/Buttons/Button.vue'
import { useSearchStore } from '@/stores/search'
interface Props {
  searchId: number
  searchResultId: number
  candidateData: any
}

const emit = defineEmits(['error'])
const props = defineProps<Props>()

const searchStore = useSearchStore()

const loading = ref(false)
const shortlistedState = computed(() =>
  searchStore.shortlist.includes(Number(props.searchResultId))
)
const shortlisted = ref(false)

const shortlist = async () => {
  loading.value = true
  try {
    const res = await axios.post(`${import.meta.env.VITE_LOOKALIKES_API}/api/shortlist`, {
      searchId: props.searchId,
      searchResultId: props.searchResultId
    })

    shortlisted.value = true
    searchStore.addCandidateToShortlist(props.searchResultId)
    searchStore.savedSearch = true

    searchStore.searchResults?.results.forEach((result) => {
      if (result.id === props.searchResultId) {
        result.shortlisted = true
      }
    })
  } catch (error) {
    if (error.response.status === 400) {
      emit('error', new Error('Candidate already shortlisted'))
    } else {
      emit('error', new Error('Failed to shortlist candidate'))
    }
  } finally {
    loading.value = false
  }
}
</script>
